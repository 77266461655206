import { select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSession } from 'rdx/modules/auth/slice';

function* confirmInvite({ payload }) {
  const { params: { token: code, password, first_name, last_name, postal_code, country_code } , onSuccess } = payload;
  const action = (yield select(getSession))?.getAction('invite_confirm');
  const params = { code, password, first_name, last_name, postal_code, country_code };
  const { success, error } = yield* makeRequest.action(
    action,
    params,
  );
  if (success) {
    if (onSuccess) {
      onSuccess();
    }
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default confirmInvite;
