import { useState, useMemo } from 'react';
import { jwtDecode } from 'lib/utils/jwtDecode';
import AutomaticLogoutModal from 'containers/Modals/AutomaticLogoutModal';
import { useModal } from 'hooks/useModal';
import { useInterval } from 'hooks/useInterval';
import { getAuthToken } from 'lib/utils/authHelpers';

export const DISCONNECT_TIMEOUT = 180;

export const useSessionTimeout = () => {
  const { callModal } = useModal();
  const authToken = getAuthToken();
  const authExpirationTime = useMemo(() => (authToken ? jwtDecode(authToken).exp : null), [authToken]);
  const [modalShown, setModalShown] = useState(false);

  const callAutoLogoutModal = () => {
    callModal(
      <AutomaticLogoutModal
        message="You have reached the end of your session."
        onCancel={() => setModalShown(false)}
      />
    );
  };

  const tick = () => {
    // now is /1000 to match jwt expiration specificity -> seconds not ms
    const now = Math.round((Date.now() / 1000));
    const secondsUntilLogout = (authExpirationTime - 10) - now;
    if (authToken && !modalShown && secondsUntilLogout < DISCONNECT_TIMEOUT) {
      setModalShown(true);
      callAutoLogoutModal();
    }
  };

  useInterval(tick, 5000);
};
