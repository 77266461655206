import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'hooks/useModal';
import ContextualDropdown from 'components/ContextualDropdown';
import ProfileModal from 'containers/Modals/ProfileModal';
import OrgProfileModal from 'containers/Modals/OrgProfileModal';
import CreateOrgModal from 'components/CreateOrgModal';
import { getOrganizations, getCurrentOrganization, getCurrentOrganizationLicense } from 'rdx/modules/organization/slice';

import globals from 'styles/globals';
import { getSessionAction, requestLogout } from 'rdx/modules/auth/slice';
import { useHistory } from 'react-router-dom';
import { getUser } from 'rdx/modules/users/slice';
import OrgPin from '../OrgPin';
import { useMixpanel } from 'hooks/useMixpanel';

const NotificationBar = () => {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const history = useHistory();
  const { callModal, closeModal, setModal } = useModal();

  const user = useSelector(getUser);
  const createOrgAction = useSelector(getSessionAction('create_organization'));
  const organizations = useSelector(getOrganizations);
  const currentOrganization = useSelector(getCurrentOrganization);
  const license = useSelector(getCurrentOrganizationLicense);

  const isTrialLicense = license?.attributes?.pn === 'FJBDRC-PREM-TRIAL';

  const userActions = useMemo(() => {
    const handleCancel = () => {
      closeModal();
      setModal(null);
    };

    const newUserActions = [{
      name: 'Sign Out',
      action: () => {
        mixpanel?.track('Clicked Sign Out')
        dispatch(requestLogout({ onSuccess: () => {
          mixpanel?.track('Logout');
          mixpanel?.reset();
          history.push('/login');
        } }));
      },
    }];

    newUserActions.push(
      {
        name: 'My Profile',
        action: () => {
          callModal(
            <ProfileModal
              key={uuid()}
              onCancel={handleCancel}
            />
          );
        },
      }
    );

    if (currentOrganization?.id) {
      newUserActions.push(
        {
          name: 'Organization Profile',
          action: () => {
            callModal(
              <OrgProfileModal
                key={uuid()}
              />
            );
          },
        }
      );
    }

    if (createOrgAction) {
      newUserActions.push(
        {
          name: 'Create an Organization',
          action: () => {
            callModal(
              <CreateOrgModal key={uuid()} />
            );
          },
        },
      );
    }

    return newUserActions;
  }, [callModal, closeModal, createOrgAction, currentOrganization?.id, dispatch, history, mixpanel, setModal]);

  const handleSelectOrg = (item) => {
    const pinned = user?.settings?.pinned_tab;
    history.push(`/${item.value}/${pinned || 'home'}`);
  };

  const items = useMemo(() => organizations.map((org) => ({
    name: org?.name,
    value: org.attributes.slug,
  })), [organizations]);

  return (
    <Container>
      {isTrialLicense && (
        <p style={{ color: globals.colors.primaryLight, fontStyle: 'italic', marginRight: '30px' }}>Premium Free Trial Active</p>
      )}
      <OrgPin
        currentOrg={currentOrganization}
      />
      <OrgDropdown
        id="org-select-dropdown"
        items={items}
        color={colors.fontLight}
        display={currentOrganization?.attributes?.name || 'loading..'}
        isOptionSelect
        handleSelectItem={handleSelectOrg}
      />
      <UserDropdown
        id="user-dropdown"
        items={userActions}
        color={colors.fontLight}
        display={user ? `${user.first_name} ${user.last_name}` : 'Loading...'}
      />
    </Container>
  );
};

export default NotificationBar;

const { colors } = globals;

export const UserDropdown = styled(ContextualDropdown)`
  margin: 0 15px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OrgDropdown = styled(ContextualDropdown)`
  margin: 0 24px 0 10px;
`;
