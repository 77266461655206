import { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useEventListener } from 'hooks/useEventListener';
import { useVerifyClose } from 'hooks/useVerifyClose';
import { useDrawer } from 'hooks/useDrawer';
import { useSecondaryDrawer } from 'hooks/useSecondaryDrawer';
import { useLocation } from 'react-router-dom';

import ContextualDropdown, { ContextualDropdownProps } from 'components/ContextualDropdown';
import Link from 'components/Link';
import NotificationBar from 'components/NotificationBar';
import MaxWidthContainer from 'containers/MaxWidthContainer';
import StatusFlag from 'components/StatusFlag';

import globals from 'styles/globals';
import bdrcLogo from 'assets/bdrc-logo-white.svg';
import { ChevronLeft, ChevronRight, XIcon } from 'components/Icons/index';
import useFullScreenWidth from 'hooks/useFullScreenWidth/index';
import { getCurrentOrgSlug } from 'rdx/modules/organization/slice';
import { setActiveNavTab } from 'rdx/modules/nav/slice';
import { getIsMobile } from 'rdx/modules/app/slice';
import NavHamburger from 'components/NavHeader/NavHamburger';
import type { Tab } from 'components/Tabs';
import { IconProps } from 'components/Icons/createIcon';
import NavHeaderTabs from 'components/NavHeader/NavHeaderTabs';
import NavHeaderButton, { NavHeaderButtonProps } from 'components/NavHeader/NavHeaderButton';

const COLLAPSE_TO_HAMBURGER_WIDTH = 1200;

const DangerousHTMLTitle = ({ title }: { title: string | TrustedHTML }) => <Title dangerouslySetInnerHTML={{ __html: title }} />;

type NavArrows<NavArrowsDropdownOptionValue> = {
  prev?: () => void,
  next?: () => void,
  dropdown: ContextualDropdownProps<NavArrowsDropdownOptionValue>,
};

export type NavHeaderTab = Tab & {
  children?: Tab[],
  name?: string,
};

export type NavHeaderProps<NavArrowsDropdownOptionValue> = {
  isMainNav?: boolean,
  isModal?: boolean,
  tabs?: NavHeaderTab[],
  currentTab?: string,
  onTabChange?: (tabId: string) => void,
  initialTabId?: string,
  buttons?: NavHeaderButtonProps[],
  title?: string,
  titleHeader?: string,
  titleSub?: string,
  flag?: string,
  onClose?: () => void,
  navArrows?: NavArrows<NavArrowsDropdownOptionValue>,
  showLogo?: boolean,
  Icon?: React.ComponentType<IconProps>,
  stepPageColorScheme?: boolean,
};

const NavHeader = <NavArrowsDropdownOptionValue,>({
  isMainNav,
  isModal,
  tabs,
  currentTab,
  onTabChange,
  initialTabId,
  buttons,
  title,
  titleHeader,
  titleSub,
  flag,
  onClose,
  navArrows,
  showLogo,
  Icon,
  stepPageColorScheme,
}: NavHeaderProps<NavArrowsDropdownOptionValue>) => {
  const isMobile = useAppSelector(getIsMobile);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const orgSlug = useAppSelector(getCurrentOrgSlug);
  const [activeTab, setActiveTab] = useState((initialTabId ?? (tabs?.[0]?.id)) ?? '');
  const [shouldHamburger, setShouldHamburger] = useState((window.innerWidth < COLLAPSE_TO_HAMBURGER_WIDTH));
  const { verifyClose } = useVerifyClose();
  const { drawerIsOpen } = useDrawer();
  const { secondaryDrawerIsOpen } = useSecondaryDrawer();
  const fullScreen = useFullScreenWidth();

  const hideBottomBar = !(tabs?.length ?? buttons?.length);

  const resizeHandler = useCallback(() => {
    setShouldHamburger((window.innerWidth < COLLAPSE_TO_HAMBURGER_WIDTH));
  }, [setShouldHamburger]);

  useEventListener('resize', resizeHandler);

  useEffect(() => {
    if (initialTabId) {
      setActiveTab(initialTabId);
    }
    if (currentTab === null) {
      setActiveTab('');
    }
  }, [initialTabId, currentTab]);

  useEffect(() => {
    const currentPathSegment = location.pathname.split('/').pop();
    if (currentPathSegment && ['home', 'panels', 'experiments', 'workflows', 'file-explorer', 'cytometers', 'reagents', 'populations', 'groups', 'users', 'fcs-viewer'].includes(currentPathSegment)) {
      dispatch(setActiveNavTab(currentPathSegment));
    } else {
      dispatch(setActiveNavTab(null));
    }
  }, [dispatch, location.pathname]);

  return (
    <NavBarWrapper stepPageColorScheme={stepPageColorScheme} useFullScreen={fullScreen} isMobile={isMobile}>
      {onClose
        && (
          <IconX
            color={colors.white}
            onClick={(drawerIsOpen || secondaryDrawerIsOpen) ? () => verifyClose(onClose) : onClose}
            data-testid="close-drawer"
            isMobile={isMobile}
          />
        )}
      {isMainNav ? (
        <NavBarTopBackground stepPageColorScheme={stepPageColorScheme}>
          <MaxWidthContainer>
            <NavBarTop isMainNav>
              <HomeLink to={`/${orgSlug}/home`} data-testid="home-link">
                <BDRCLogo src={bdrcLogo} alt="BD Research Cloud" />
              </HomeLink>
              <NotificationBar />
            </NavBarTop>
          </MaxWidthContainer>
        </NavBarTopBackground>
      ) : (
        <NavBarTop isModal={isModal} isMobile={isMobile} stepPageColorScheme={stepPageColorScheme}>
          {flag && <HeaderFlag>{flag}</HeaderFlag>}
          <TitleContainer spaceBetween={showLogo} stepPageColorScheme={stepPageColorScheme}>
            {Icon && <Icon size={hideBottomBar ? 22 : 18} color={colors.white} style={{ marginRight: '0.5em' }} />}
            <DangerousHTMLTitle title={title ?? ''} />
            {titleHeader && <TitleHeader>{titleHeader}</TitleHeader>}
            {titleSub && <TitleSub>{titleSub}</TitleSub>}
            {showLogo && <BDRCLogo src={bdrcLogo} alt="BD Research Cloud" />}
          </TitleContainer>
          {navArrows && (
            <StepNavigation>
              <PrevIcon
                size="32px"
                softDisabled={!navArrows.prev}
                onClick={(drawerIsOpen || secondaryDrawerIsOpen) ? () => verifyClose(navArrows.prev) : () => navArrows.prev?.()}
                id="navigate-previous-step"
              />
              <ContextualDropdown id="nav-arrows-dropdown" {...navArrows.dropdown} isOptionSelect isStepNav highZ itemHeight="4rem" />
              <NextIcon
                size="32px"
                softDisabled={!navArrows.next}
                onClick={(drawerIsOpen || secondaryDrawerIsOpen) ? () => verifyClose(navArrows.next) : () => navArrows.next?.()}
                id="navigate-next-step"
              />
            </StepNavigation>
          )}
        </NavBarTop>
      )}
      {!hideBottomBar && (
        <NavBarBottomBackground>
          <MaxWidthContainer>
            <NavBarBottom isMainNav={isMainNav} hasBackArrow={tabs?.some((tab) => tab.id === 'PREV')}>
              <NavContainer>
                {(isMainNav && shouldHamburger)
                  ? (
                    <HamburgerNav className={shouldHamburger ? 'active' : ''}>
                      <NavHamburger tabs={tabs} />
                    </HamburgerNav>
                  )
                  : (
                    <TabsNav className="active">
                      <NavHeaderTabs
                        tabs={tabs ?? []}
                        currentTab={currentTab}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        onTabChange={onTabChange}
                      />
                    </TabsNav>
                  )}
              </NavContainer>
              <ButtonsContainer>
                {buttons?.map(({ id, component, tooltip, ...rest }, index) => <NavHeaderButton key={id ?? index} {...{ id, component, tooltip, ...rest }} />)}
              </ButtonsContainer>
            </NavBarBottom>
          </MaxWidthContainer>
        </NavBarBottomBackground>
      )}
    </NavBarWrapper>
  );
};

export default NavHeader;

const { colors, layout, fonts } = globals;

const handleMargin = (isMainNav?: boolean, stepPageColorScheme?: boolean) => {
  if (isMainNav) {
    return `0 0 0 ${layout.navContainerPadding}`;
  }
  if (stepPageColorScheme) {
    return '0 0 0 0';
  }
  return `0 ${layout.drawerPadding}`;
};

const HamburgerNav = styled.div`
  opacity: 0;
  &.active {
    opacity: 1;
  }
`;

const TabsNav = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  opacity: 0;
  &.active {
    opacity: 1;
  }
`;

export const NavBarWrapper = styled.div<{ stepPageColorScheme?: boolean, useFullScreen?: boolean, isMobile?: boolean }>`
  background-color: ${({ stepPageColorScheme }) => (stepPageColorScheme ? 'transparent' : colors.charcoal)};
  position: relative;
  width: 100%;

  ${({ useFullScreen, stepPageColorScheme }) => useFullScreen && stepPageColorScheme && css`
    max-width: calc(${globals.layout.maxInnerWidth} + calc(${globals.layout.mainContainerPadding}));
    padding-left: calc(${globals.layout.mainContainerPadding});
  `}

  ${({ isMobile }) => isMobile && css`
    display: flex;
    align-items: center;
  `}
`;

export const NavBarTopBackground = styled.div<{ stepPageColorScheme?: boolean }>`
  background-color: ${({ stepPageColorScheme }) => (stepPageColorScheme ? 'transparent' : colors.charcoal)};
`;

export const NavBarTop = styled.div<{ stepPageColorScheme?: boolean, isMainNav?: boolean, isModal?: boolean, isMobile?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${layout.headerBarTopHeight};
  width: ${({ stepPageColorScheme, isMainNav }) => {
    if (stepPageColorScheme) {
      return '100%';
    }
    if (isMainNav) {
      return 'calc(100% - 20px)';
    }
    return 'calc(100% - 146px)';
  }};
  margin: ${({ isMainNav, stepPageColorScheme }) => handleMargin(isMainNav, stepPageColorScheme)};

  ${({ isModal }) => (isModal && css`
    height: 90px;
    position: fixed
    top: 0;
  `)};

  ${({ isMobile }) => isMobile && css`
    width: 100%;
    margin: 0;
  `}
`;

const NavBarBottomBackground = styled.div`
  background-color: ${colors.white};
  z-index: 1;
`;

const NavBarBottom = styled.div<{ isMainNav?: boolean, hasBackArrow?: boolean }>`
  width: 'calc(100% - 20px)';
  height: ${layout.headerBarBottomHeight};
  margin-left: ${({ isMainNav, hasBackArrow }) => {
    if (isMainNav) {
      if (hasBackArrow) {
        return layout.navContainerPaddingWithBackArrow;
      }
      return layout.navContainerPadding;
    }
    return layout.drawerPadding;
  }};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HomeLink = styled(Link)`
  color: ${colors.white};
  font: ${fonts.h2};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
`;

export const BDRCLogo = styled.img`
  height: 20px;
`;

const TitleContainer = styled.div<{ stepPageColorScheme?: boolean, spaceBetween?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font: ${fonts.bodyLabel};
  color: ${({ stepPageColorScheme }) => (stepPageColorScheme ? colors.fontDark : colors.white)};
  overflow: hidden;
  ${({ spaceBetween }) => (spaceBetween && css`
    width: 100%;
    justify-content: space-between;
  `)}
`;

const Title = styled.div`
  max-width: 100%;
  letter-spacing: 0.4pt;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: flex-end;
`;

const TitleHeader = styled.h4`
  text-transform: capitalize;
  margin-left: 10px;
`;

const TitleSub = styled.p`
  font: ${fonts.buttons};
  color: white;
  margin-left: 10px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 1px;
`;

const HeaderFlag = styled(StatusFlag)`
  position: absolute;
  top: 7px;
  left: -${layout.drawerPadding};
  width: ${parseInt(layout.drawerPadding) - 10}px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: ${colors.pureBlack};
`;

const NavContainer = styled.div`
  align-self: flex-end;
  display: flex;
  position: relative;
  color: ${colors.white};
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding-bottom: 1px;
`;

const X_LENGTH = 16;
const X_OFFSET_Y = 8;
const X_OFFSET_X = 12;
const X_HITBOX_PADDING = 5;

export const IconX = styled(XIcon)`
  ${({ isMobile }) => (!isMobile ? css`
    position: absolute;
    box-sizing: content-box;
    left: ${X_OFFSET_X}px;
    top: ${X_OFFSET_Y}px;
    padding: ${X_HITBOX_PADDING}px;
  ` : css`
    margin: ${X_OFFSET_X}px;
  `)}

    height: ${X_LENGTH / 1.41}px;
    width: ${X_LENGTH / 1.41}px;
`;

const StepNavigation = styled.div`
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
`;

const PrevIcon = styled(({ softDisabled, ...props }) => <ChevronLeft {...props} />)`
  position: relative;
  left: 3px;
  ${({ softDisabled }) => softDisabled && css`
    opacity: .5;
    pointer-events: none;
  `}
`;

const NextIcon = styled(({ softDisabled, ...props }) => <ChevronRight {...props} />)`
  position: relative;
  right: 3px;
  ${({ softDisabled }) => softDisabled && css`
    opacity: .5;
    pointer-events: none;
  `}
`;
