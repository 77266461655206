import styled from 'styled-components';
import globals from 'styles/globals';

const { colors } = globals;

const GridTable = styled.div`
  display: grid;
  padding: 15px;
  background-color: ${colors.white};
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin: 3px 0px;
`;

export default GridTable;
