import moment from 'moment';
import { HalfWidthCell, LabelCell, Table, TableRow } from 'components/BarcodeScans/shared';

const InventoryReagentTable = ({ inventoryReagent }) => (
  <Table>
    <tbody>
      <TableRow>
        <LabelCell>Created On</LabelCell>
        <HalfWidthCell>{moment(inventoryReagent.created_at).format('lll')}</HalfWidthCell>
      </TableRow>
      <TableRow>
        <LabelCell>Quantity</LabelCell>
        <HalfWidthCell>{inventoryReagent.quantity}</HalfWidthCell>
      </TableRow>
      <TableRow>
        <LabelCell>Location</LabelCell>
        <HalfWidthCell>{inventoryReagent.location || '---'}</HalfWidthCell>
      </TableRow>
    </tbody>
  </Table>
);

export default InventoryReagentTable;
