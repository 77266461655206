import { useState, useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getBarcodeScans, requestBarcodeScans } from 'rdx/modules/barcodeScans/slice';
import { getSessionLink } from 'rdx/modules/auth/slice';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';

import { useLoading } from 'hooks/useLoading';
import { useUnwrappedSelector } from 'hooks/useUnwrappedSelector';

import LoadingCircle from 'components/Loading/LoadingCircle';
import BarcodeScanCard from 'components/BarcodeScans/BarcodeScanCard';
import PrimaryButton from 'components/Button/PrimaryButton';
import { pluralize } from 'lib/utils/string';
import MobileBarcodeScanCard from 'components/BarcodeScans/MobileBarcodeScanCard';
import { BarcodeScanAttributes } from 'types/barcodeScans';

const BarcodeScans = () => {
  const dispatch = useAppDispatch();
  const routeMatch = useRouteMatch();

  const isMobileScanner = routeMatch.path.includes('mobile-scanner');

  const barcodeScansLink = useAppSelector(getSessionLink('barcode_scans'));
  const barcodeScans = useUnwrappedSelector<BarcodeScanAttributes>(getBarcodeScans);
  const currentOrg = useAppSelector(getCurrentOrganization);
  const currentOrgID = useMemo(() => currentOrg?.id, [currentOrg?.id]);

  const [page, setPage] = useState(1);

  const isLoading = useLoading({ watchRequests: [requestBarcodeScans.type] });

  const fetchBarcodeScans = useCallback(() => {
    if (barcodeScansLink && currentOrgID) {
      dispatch(requestBarcodeScans({
        link: barcodeScansLink,
        query: { page, pageSize: 10 },
        params: {
          organizationID: currentOrgID,
        },
      }));
    }
  }, [barcodeScansLink, currentOrgID, dispatch, page]);

  useEffect(() => {
    fetchBarcodeScans();
  }, [fetchBarcodeScans]);

  const getResultText = () => `Found ${pluralize(barcodeScans?.meta?.pagination?.rowCount ?? 0, '%n {scanned reagent : scanned reagents}')}`;

  return (isLoading && page === 1) ? (
    <LoadingCircle />
  ) : (
    <ScansSection isMobile={isMobileScanner}>
      {isMobileScanner ? (
        <Header>Scanned Reagents</Header>
      ) : (
        <SectionTitle>{getResultText()}</SectionTitle>
      )}
      <BarcodeScansContainer>
        {Array.isArray(barcodeScans) && !!barcodeScans.length ? barcodeScans.map((barcodeScan) => {
          if (isMobileScanner) {
            return (
              <MobileBarcodeScanCard
                key={barcodeScan.id}
                barcodeScan={barcodeScan}
                fetchBarcodeScans={fetchBarcodeScans}
              />
            );
          }
          return (
            <BarcodeScanCard
              key={barcodeScan.id}
              barcodeScan={barcodeScan}
              fetchBarcodeScans={fetchBarcodeScans}
            />
          );
        }) : (
          <p>No reagents scanned</p>
        )}
        {Array.isArray(barcodeScans) && !!barcodeScans.length && (barcodeScans?.meta?.pagination?.page !== barcodeScans?.meta?.pagination?.pageCount) && (
          <PrimaryButton
            text="Load More"
            width={isMobileScanner ? '250px' : '120px'}
            height={isMobileScanner ? '60px' : '40px'}
            onClick={() => setPage((p) => p + 1)}
            loading={isLoading}
            style={{ margin: '20px auto' }}
          />
        )}
      </BarcodeScansContainer>
    </ScansSection>

  );
};

export default BarcodeScans;

const Header = styled.h1`
  margin-bottom: 10px;
`;

const ScansSection = styled.div<{ isMobile?: boolean }>`
  margin: auto;
  width: fit-content;
  ${({ isMobile }) => !isMobile && css`width: 100%;`}
`;

const BarcodeScansContainer = styled.div`
  height: 100%;
  max-height: 100%;
`;

const SectionTitle = styled.h2`
  margin: 10px 0px;
`;
