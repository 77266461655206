import moment from 'moment';
import { HalfWidthCell, LabelCell, Table, TableRow } from 'components/BarcodeScans/shared';
import convertYYMMDDtoDate from 'lib/utils/convertYYMMDDtoDate';

const ScanTable = ({ scanAttributes, owner }) => {
  let expiresAt;

  if (scanAttributes?.meta?.expires_at) {
    expiresAt = convertYYMMDDtoDate(scanAttributes.meta.expires_at);
  }

  return (
    <Table>
      <tbody>
        <TableRow>
          <LabelCell>Scanned On</LabelCell>
          <HalfWidthCell>{moment(scanAttributes.created_at).format('lll')}</HalfWidthCell>
        </TableRow>
        <TableRow>
          <LabelCell>Scanned By</LabelCell>
          <HalfWidthCell>{owner.first_name} {owner.last_name}</HalfWidthCell>
        </TableRow>
        {scanAttributes.meta && (
          <>
            <TableRow>
              <LabelCell>Global Trade Item Number</LabelCell>
              <HalfWidthCell>{scanAttributes.meta.gtin || '---'}</HalfWidthCell>
            </TableRow>
            <TableRow>
              <LabelCell>Catalog Number</LabelCell>
              <HalfWidthCell>{scanAttributes.meta.catalog_number || '---'}</HalfWidthCell>
            </TableRow>
            <TableRow>
              <LabelCell>Lot</LabelCell>
              <HalfWidthCell>{scanAttributes.meta.lot || '---'}</HalfWidthCell>
            </TableRow>
            <TableRow>
              <LabelCell>Expiration Date</LabelCell>
              <HalfWidthCell>{moment(expiresAt).format('ll')}</HalfWidthCell>
            </TableRow>
            <TableRow>
              <LabelCell>Manufacturer ID</LabelCell>
              <HalfWidthCell>{scanAttributes.meta.manufacturer_id || '---'}</HalfWidthCell>
            </TableRow>
            <TableRow>
              <LabelCell>Vial ID</LabelCell>
              <HalfWidthCell>{scanAttributes.meta.vial || '---'}</HalfWidthCell>
            </TableRow>
          </>
        )}
      </tbody>
    </Table>
  );
};

export default ScanTable;
