import { put, select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSession, setInviteStatus } from 'rdx/modules/auth/slice';

function* requestInviteStatus({ payload }) {
  const action = (yield select(getSession))?.getAction('invite_status');
  const { data, success, error } = yield* makeRequest.action(action, { code: payload.token });
  if (success && data) {
    yield put(setInviteStatus(data.unwrap().attributes.status));
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestInviteStatus;
