/* eslint-disable max-len */
import { takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';
import requestSaga from 'rdx/api-utils/requestSaga';
import { generateIssue } from './slice';

function* watchTechSupportSagas() {
  yield trackRequests(takeLatest, generateIssue, (rdxAction) => requestSaga({ rdxAction, message: 'Thank you for your feedback.' }));
}

export default watchTechSupportSagas;
