import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import type { RootState } from 'index';
import Resource from 'lib/jsonApi/Resource';
import { ResourceObject, ResourceObjects } from 'types/json-api-types';
import { type GenericActionPayload } from 'types/redux-types';
import { type UserAttributes } from 'types/user';

interface UsersSliceState {
  orgUsers: ResourceList,
  orgUser: Resource | ResourceObject | null;
  unconfirmedOrgUsers: ResourceList;
  pendingOrgUsers: ResourceList;
  chatOrgUsers: ResourceList;

  groupUsers: ResourceList;
  groupUser: Resource | null;
  pendingGroupUsers: ResourceList;
  unconfirmedGroupUsers: ResourceList;

  permissionUsers: ResourceList | null;
  generalUsers: ResourceList;
  currentUser: Resource<UserAttributes>;
  currentUserPermissions: ResourceList;
}

const initialState: UsersSliceState = {
  // Org Users
  orgUsers: new ResourceList(),
  orgUser: null,
  unconfirmedOrgUsers: new ResourceList(),
  pendingOrgUsers: new ResourceList(),
  chatOrgUsers: new ResourceList(),

  // Group users
  groupUsers: new ResourceList(),
  groupUser: null,
  pendingGroupUsers: new ResourceList(),
  unconfirmedGroupUsers: new ResourceList(),

  permissionUsers: null,
  generalUsers: new ResourceList(),
  currentUser: new Resource(),
  currentUserPermissions: new ResourceList(),
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setOrgUsers: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.orgUsers = state.orgUsers.mergeWith(action.payload) || state.orgUsers;
        return;
      }
      state.orgUsers = action.payload;
    },
    setUnconfirmedOrgUsers: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.unconfirmedOrgUsers = state.unconfirmedOrgUsers.mergeWith(action.payload) || state.unconfirmedOrgUsers;
        return;
      }
      state.unconfirmedOrgUsers = action.payload;
    },
    setPendingOrgUsers: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.pendingOrgUsers = state.pendingOrgUsers.mergeWith(action.payload) || state.pendingOrgUsers;
        return;
      }
      state.pendingOrgUsers = action.payload;
    },
    setChatOrgUsers: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.chatOrgUsers = state.chatOrgUsers.mergeWith(action.payload) || state.chatOrgUsers;
        return;
      }
      state.chatOrgUsers = action.payload;
    },
    setGroupUsers: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.groupUsers = state.groupUsers.mergeWith(action.payload) || state.groupUsers;
        return;
      }
      state.groupUsers = action.payload;
    },
    setPendingGroupUsers: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.pendingGroupUsers = state.pendingGroupUsers.mergeWith(action.payload) || state.pendingGroupUsers;
        return;
      }
      state.pendingGroupUsers = action.payload;
    },
    setUnconfirmedGroupUsers: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.unconfirmedGroupUsers = state.unconfirmedGroupUsers.mergeWith(action.payload) || state.unconfirmedGroupUsers;
        return;
      }
      state.unconfirmedGroupUsers = action.payload;
    },
    setGroupUser: (state, action: PayloadAction<ResourceList>) => {
      state.groupUser = action.payload.unwrap() as Resource;
    },
    setUser: (state, action: PayloadAction<Resource<UserAttributes>>) => {
      state.currentUser = action.payload;
    },
    setUserPermissions: (state, action) => {
      state.currentUserPermissions = action.payload;
    },
    updateGroupUsers: (state, action: PayloadAction<{ resource: Resource, included: ResourceObjects }>) => {
      const { resource, included } = action.payload;
      if (state.groupUsers.includesRes(resource)) {
        state.groupUsers = state.groupUsers.withResource(resource, included);
        return;
      }
      if (state.pendingGroupUsers.includesRes(resource)) {
        state.pendingGroupUsers = state.pendingGroupUsers.withResource(resource, included);
      }
    },
    setOrgUser: (state, action: PayloadAction<Resource | null>) => {
      state.orgUser = action.payload;
    },
    setGeneralUsers: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.generalUsers = state.generalUsers.mergeWith(action.payload) || state.generalUsers;
        return;
      }
      state.generalUsers = action.payload;
    },
    setPermissionUsers: (state, action: PayloadAction<ResourceList | null>) => {
      state.permissionUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

type RequestUsersParams = {
  organization_id: string,
  workflow_owners?: number,
  search?: string,
}

const requestUser = createAction('users/requestUser');
const updateUser = createAction('users/updateUser');
const updateUserTutorials = createAction('users/updateUserTutorials');
const requestUserPermissions = createAction('users/requestUserPermissions');
const requestOrgUsers = createAction('users/requestOrgUsers');
const revokeConsent = createAction('users/revokeConsent');
const requestGroupUsers = createAction('users/requestGroupUsers');
const requestGroupUser = createAction('users/requestGroupUser');
const postInvite = createAction('users/postInvite');
const inviteOrgUser = createAction('users/inviteOrgUser');
const deleteGroupUser = createAction('users/deleteGroupUser');
const patchGroupUser = createAction('users/patchGroupUser');
const confirmGroupUser = createAction('users/confirmGroupUser');
const confirmOrgUser = createAction('users/confirmOrgUser');
const patchOrgUser = createAction('users/patchOrgUser');
const deleteOrgUser = createAction('users/deleteOrgUser');
const requestOrgUser = createAction('users/requestOrgUser');
const requestUsers = createAction<GenericActionPayload<RequestUsersParams>>('users/requestUsers');
const requestPermissionUsers = createAction('users/requestPermissionUsers');
const marcomOptIn = createAction('users/marcomOptIn');

const {
  setUser,
  setUserPermissions,
  setOrgUsers,
  setUnconfirmedOrgUsers,
  setPendingOrgUsers,
  setChatOrgUsers,
  setGroupUsers,
  setGroupUser,
  setPendingGroupUsers,
  setUnconfirmedGroupUsers,
  updateGroupUsers,
  setOrgUser,
  setGeneralUsers,
  setPermissionUsers,
} = usersSlice.actions;

export {
  setUser,
  setUserPermissions,
  setOrgUsers,
  setUnconfirmedOrgUsers,
  setPendingOrgUsers,
  setChatOrgUsers,
  setGroupUsers,
  setGroupUser,
  setPendingGroupUsers,
  setUnconfirmedGroupUsers,
  updateGroupUsers,
  setOrgUser,
  setGeneralUsers,
  setPermissionUsers,

  requestUser,
  updateUser,
  updateUserTutorials,
  requestUserPermissions,
  requestOrgUsers,
  revokeConsent,
  requestGroupUsers,
  requestGroupUser,
  postInvite,
  inviteOrgUser,
  deleteGroupUser,
  patchGroupUser,
  confirmGroupUser,
  confirmOrgUser,
  patchOrgUser,
  deleteOrgUser,
  requestOrgUser,
  requestUsers,
  requestPermissionUsers,
  marcomOptIn,
};

export const getUser = (state: RootState) => state.users.currentUser;
export const getUserPermissions = (state: RootState) => state.users.currentUserPermissions;
export const getCompletedTutorials = (state: RootState) => state.users.currentUser.attributes?.completed_tutorials;
export const getDisableTutorials = (state: RootState) => {
  const userSettings = state.users.currentUser.attributes?.settings;
  return userSettings?.disable_tutorials;
};
export const getUserId = (state: RootState) => state.users.currentUser?.id;
export const getUserUUID = (state: RootState) => state.users.currentUser?.attributes?.uuid;
export const getUserDefaultResources = (state: RootState) => {
  const userSettings = state.users.currentUser.attributes.settings;
  return userSettings?.default_resources ?? {};
};
export const getUserDefaultResourcesForCurrentOrg = (state: RootState) => {
  const userSettings = state.users.currentUser.attributes?.settings;
  const currentOrgSlug = state.organization.currentOrganization?.attributes?.slug;
  return userSettings?.default_resources?.[currentOrgSlug];
};
export const getUserDefaultGroupForCurrentOrg = (state: RootState) => {
  const userSettings = state.users.currentUser.attributes?.settings;
  const currentOrgSlug = state.organization.currentOrganization?.attributes?.slug;
  return userSettings?.default_resources?.[currentOrgSlug]?.default;
};
export const getUserDefaultProjectForCurrentOrg = (state: RootState) => {
  const userSettings = state.users.currentUser.attributes?.settings;
  const currentOrgSlug = state.organization.currentOrganization?.attributes?.slug;
  if (currentOrgSlug) {
    const defaultForCurrentOrg = userSettings?.default_resources?.[currentOrgSlug]?.default;
    if (defaultForCurrentOrg) {
      return userSettings?.default_resources?.[currentOrgSlug]?.[defaultForCurrentOrg];
    }
  }
  return undefined;
};
export const getOrgUserInviteScopes = (state: RootState) => state.users.orgUsers.getAction('invite')?.field?.('suggested_scope')?.options;
export const getOrgUsersRaw = (state: RootState) => state.users.orgUsers;
export const getOtherOrgUsersRaw = (state: RootState) => state.users.chatOrgUsers;
export const getPendingOrgUsersRaw = (state: RootState) => state.users.pendingOrgUsers;
export const getUnconfirmedOrgUsersRaw = (state: RootState) => state.users.unconfirmedOrgUsers;
export const getOrgUsersActions = (state: RootState) => state.users.orgUsers.getAction;
export const getGroupUsersRaw = (state: RootState) => state.users.groupUsers;
export const getGroupUser = (state: RootState) => state.users.groupUser;
export const getPendingGroupUsersRaw = (state: RootState) => state.users.pendingGroupUsers;
export const getUnconfirmedGroupUsersRaw = (state: RootState) => state.users.unconfirmedGroupUsers;
export const getOrgUser = (state: RootState) => state.users.orgUser;
export const getGeneralUsers = (state: RootState) => state.users.generalUsers;
export const getPermissionUsers = (state: RootState) => state.users.permissionUsers;

export default usersSlice.reducer;
