import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import globals from 'styles/globals';

import { navTabUnderlineCSS } from 'components/NavHeader/shared';
import ContextualDropdown from 'components/ContextualDropdown';
import { Tab } from 'components/Tabs';

type Props = {
  text: string,
  routes: Tab[]
};

const DropdownNav = ({ text = 'Default', routes = [] }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const lowerCaseName = text.toLowerCase();
  const parentActive = () => {
    let bool = false;
    routes.forEach((route) => {
      if (route.link && location.pathname.includes(route.link)) {
        bool = true;
      }
    });
    return bool;
  };

  const dropdownItems = routes.map((tab) => {
    if (tab.id === 'PREV') {
      return ({
        name: 'Workflows',
        action: () => history.push('/'),
      });
    }
    return ({
      name: tab.text,
      action: () => history.push(tab.link ?? '/'),
    });
  });
  return (
    <SContextualDropdown
      isMainNav
      items={dropdownItems}
    >
      <Title active={parentActive()}>{lowerCaseName}</Title>
    </SContextualDropdown>
  );
};

export default DropdownNav;

export const Title = styled.h3<{ active?: boolean }>`
  padding: 10px;
  text-align: center;
  height: 40px;
  padding-right: 15px;
  white-space: nowrap;
  text-transform: capitalize;
  font: ${globals.fonts.buttons};
  color: ${globals.colors.fontDark};
  letter-spacing: 0.4pt;
  font-weight: 300;

  ${({ active }) => active && navTabUnderlineCSS}
`;

const SContextualDropdown = styled(ContextualDropdown)`
  cursor: pointer;
`;
