/* eslint-disable @typescript-eslint/no-empty-function */
import { useScanditSDK } from "lib/scandit/scanditSDK";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import type { Barcode, Symbology } from "scandit-web-datacapture-barcode";

export interface ScanditContextType {
  barcode: Barcode | undefined;
  keepCameraOn: boolean;
  symbologies: Partial<Record<Symbology, boolean>>;
  setBarcode: Dispatch<SetStateAction<Barcode | undefined>>;
  setKeepCameraOn: Dispatch<SetStateAction<boolean>>;
  setSymbologies: Dispatch<Partial<Record<Symbology, boolean>>>;
}

export const ScanditContext = createContext<ScanditContextType>({
  barcode: undefined,
  keepCameraOn: true,
  symbologies: {},
  setBarcode: () => {},
  setKeepCameraOn: () => {},
  setSymbologies: () => {},
});

export interface ScanditProviderProps {
  children: ReactNode;
}

export function ScanditProvider({ children }: ScanditProviderProps): JSX.Element {
  const { loading, sdk } = useScanditSDK();
  const [barcode, setBarcode] = useState<Barcode | undefined>();
  const [keepCameraOn, setKeepCameraOn] = useState(true);
  const [symbologies, setSymbologies] = useState<Partial<Record<Symbology, boolean>>>({});

  useEffect(() => {
    if (!loading) {
      const enabledSymbologyEntries = sdk.getEnabledSymbologies().map((symbology) => [symbology, true] as const);
      const enabledSymbologies = Object.fromEntries(enabledSymbologyEntries);
      setSymbologies(enabledSymbologies);
    }
  }, [loading, sdk]);

  return (
    <ScanditContext.Provider
      value={useMemo(
        () => ({
          barcode,
          setBarcode,
          keepCameraOn,
          setKeepCameraOn,
          symbologies,
          setSymbologies,
        }),
        [barcode, keepCameraOn, symbologies]
      )}
    >
      {children}
    </ScanditContext.Provider>
  );
}

export function useScanditContext(): ScanditContextType {
  return useContext(ScanditContext);
}
