import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const NoContentMessage = ({ item, onClick, locked, id, linkTestID }) => (
  <TextContainer id={id}>
    There are no {item}s to display.<br />
    {onClick && (
      <LinkText data-testid={locked ? `${linkTestID ? `locked-${linkTestID}` : 'locked-no-content-link'}` : linkTestID} onClick={onClick} locked={locked}>
        Click here to create a new {item}
      </LinkText>
    )}
  </TextContainer>
);

NoContentMessage.propTypes = {
  item: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  locked: PropTypes.bool,
  id: PropTypes.string,
  linkTestID: PropTypes.string,
};

NoContentMessage.defaultProps = {
  onClick: undefined,
  locked: false,
  id: 'no-content-message',
  linkTestID: 'no-content-link',
};

export default NoContentMessage;

const TextContainer = styled.div`
  margin-top: 15px;
  line-height: 1.5em;
`;

const LinkText = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    text-decoration: underline;
    color: ${({ locked, theme }) => (locked ? theme.colors.warning : theme.colors.primaryDark)};
  }
`;
