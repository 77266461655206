import { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import globals from 'styles/globals';
import Loading from 'components/Loading';
import { FilledCheckmarkIcon } from 'components/Icons';
import { useLicenses } from 'components/CreateOrgModal/useLicenses';
import { BackText } from 'containers/Modals/OrgProfileModal/components';
import { LinkText } from 'containers/Auth/components';
import PrimaryButton from 'components/Button/PrimaryButton/index';
import { requestLicenses, setLicenses, setLicenseQuoteURL, requestLicenseQuoteURL, getLicenseQuoteURL } from 'rdx/modules/licenses/slice';
import { getSessionAction, requestLogout } from 'rdx/modules/auth/slice';
import { useMixpanel } from 'hooks/useMixpanel';
import { createTrialLicenseForOrg, getCurrentOrganization, requestOrganizationProfile } from 'rdx/modules/organization/slice';
import { useAppSelector } from 'hooks/reduxHooks';
import { COLORS } from 'styles/unifiedVisSpec';
import ConfirmModal from 'containers/Modals/ConfirmModal';
import { useSecondaryModal } from 'hooks/useSecondaryModal';
import { newErrorEvent, newMessageEvent } from 'rdx/modules/messages/slice';
import { useModal } from 'hooks/useModal';

const FEATURE_NAMES = {
  AUTOMTED_PANEL_DESIGN: 'Reagent selector',
  DATA_AND_METADATA_MANAGEMENT: 'Data and metadata management',
  FACSDIVA_EXPERIMENT_CREATION: 'BD FACSDiva Experiment creation',
  EXPERIMENT_WORKFLOW_TRACKING: 'Experiment workflow tracking',
  TITRATION_IMAGE_GENERATOR: 'Titration image generator',
  WORKFLOW_COLLABORATION: 'Workflow collaboration',
  USER_PERMISSIONS: 'User based permissions',
  RECURRING_WORKFLOWS: 'Automatically recurring workflows',
  // TODO: Implement this feature row once it is actually a part of premium licenses
  // INSTRUMENT_PERFORMANCE: 'Instrument performance tracking',
  REAGENT_INVENTORY: 'Reagent inventory management',
  ANTIBODY_TITER_MANAGEMENT: 'Antibody titer management',
  FILE_SHARING: 'Share files outside organization',
  DATA_STORAGE: 'Data storage limit',
  WORKFLOW_TRACKING: 'Active workflows limit',
  ORGANIZATION_USERS: 'Available user seats',
};

const watchRequests = [requestLicenses.type, setLicenses.type];

/**
 * SelectLicenseTable will display a table with license information and buttons
 * @param {function} setLicense (license: {string}) => setLicense(license), called on radio click
 * @param {function} setActiveTab (tabId: {string}) => setActiveTab(tabId), called after selecting license
 * @param {function} onCancel
 * @param {object} currentLicenses
 */

const SelectLicenseTable = ({
  setLicense,
  setActiveTab,
  currentLicenses,
  currentBestLicense,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const { closeAndUnsetModal } = useModal();
  const { callSecondaryModal, closeAndUnsetSecondaryModal } = useSecondaryModal();
  const mixpanel = useMixpanel();
  const upgradeLinkAction = useAppSelector(getSessionAction('license_quote_url'));
  const orgProfileAction = useAppSelector(getSessionAction('organization_profile'));
  const licenseQuoteUrl = useAppSelector(getLicenseQuoteURL);
  const currentOrg = useAppSelector(getCurrentOrganization);
  const createTrialLicenseAction = currentOrg?.getAction('create_trial_license');
  const licenses = useLicenses();
  const currentLicensePns = currentLicenses?.map((l) => l.attributes.pn);
  const currentLicensePn = currentBestLicense?.attributes?.pn;
  const [quoteOpened, setQuoteOpened] = useState(false);

  useEffect(() => {
    if (licenseQuoteUrl && !quoteOpened) {
      window.open(licenseQuoteUrl);
      setQuoteOpened(true);
    }
    return () => {
      dispatch(setLicenseQuoteURL(''));
    };
  }, [dispatch, licenseQuoteUrl, quoteOpened]);

  const handleQuoteRequest = (pn) => {
    setQuoteOpened(false);
    dispatch(requestLicenseQuoteURL({ action: upgradeLinkAction, params: { pn } }));
  };

  const fetchOrgProfile = () => {
    if (currentOrg?.attributes?.slug && orgProfileAction) {
      dispatch(requestOrganizationProfile({
        organizationID: currentOrg.attributes.slug,
        onError: ({ currentOrgSlug }) => {
          if (currentOrgSlug) {
            history.push(`/${currentOrgSlug}`);
          } else {
            dispatch(requestLogout({ onSuccess: () => {
              mixpanel?.track('Logout')
              mixpanel?.reset();
              history.push('/')
            } }));
          }
        },
      }));
    }
  }

  const createFreeTrialLicense = () => {
    if (createTrialLicenseAction) {
      let trialLicensePn = '';
      const options = createTrialLicenseAction.field('pn')?.options;
      if (options && Array.isArray(options)) {
        trialLicensePn = options[0];
      }
      if (trialLicensePn) {
        dispatch(createTrialLicenseForOrg({
          action: createTrialLicenseAction,
          params: {
            pn: trialLicensePn,
          },
          onSuccess: () => {
            mixpanel?.track("Free Trial License Activated", { feature_slug: 'select license table' });
            dispatch(newMessageEvent({ text: 'Free Trial License successfully activated.'}))
            fetchOrgProfile();
            closeAndUnsetModal();
            closeAndUnsetSecondaryModal();
          },
        }));
      }
    } else {
      dispatch(newErrorEvent({ text: "You do not have sufficient permissions to activate the free trial, please contact the org's admin."}))
    }
  }

  const SANDBOX_LICENSE_PN = 'FJBDRC-FRM';
  const BASIC_LICENSE_PN = 'FJBDRC-BASIC';
  const PROF_LICENSE_PN = 'FJBDRC-PROF-301-PLUS';
  const PREM_LICENSE_PN = 'FJBDRC-PREM-301-PLUS';

  const licenseMetas = licenses?.data?.reduce((acc, cur) => ({ ...acc, [cur.attributes.pn]: cur.attributes.meta }), {});

  const renderDetailContainer = (inner) => (
    <DetailContainer>{inner}</DetailContainer>
  );

  const rows = useMemo(() => Object.values(FEATURE_NAMES).map((name) => {
    const CheckIcon = (
      <FilledCheckmarkIcon
        size="md-lg"
        color="primary"
      />
    );

    const data = {
      feature: <p>{name}</p>,
      individual: renderDetailContainer(CheckIcon),
      basic: renderDetailContainer(CheckIcon),
      professional: renderDetailContainer(CheckIcon),
      premium: renderDetailContainer(CheckIcon),
    };

    switch (name) {
      case FEATURE_NAMES.WORKFLOW_COLLABORATION:
        data.individual = renderDetailContainer('');
        break;
      case FEATURE_NAMES.USER_PERMISSIONS:
        data.individual = renderDetailContainer('');
        break;
      case FEATURE_NAMES.DATA_STORAGE:
        data.individual = renderDetailContainer(`${licenseMetas?.[SANDBOX_LICENSE_PN]?.storage_gb_per_user} GB`);
        data.basic = renderDetailContainer(`${licenseMetas?.[BASIC_LICENSE_PN]?.storage_gb_per_user} GB/User`);
        data.professional = renderDetailContainer(`${licenseMetas?.[PROF_LICENSE_PN]?.storage_gb_per_user} GB/User`);
        data.premium = renderDetailContainer(`${licenseMetas?.[PREM_LICENSE_PN]?.storage_gb_per_user} GB/User`);
        break;
      case FEATURE_NAMES.WORKFLOW_TRACKING:
        data.individual = renderDetailContainer(licenseMetas?.[SANDBOX_LICENSE_PN]?.max_workflows);
        data.basic = renderDetailContainer(licenseMetas?.[BASIC_LICENSE_PN]?.max_workflows);
        data.professional = renderDetailContainer('Unlimited');
        data.premium = renderDetailContainer('Unlimited');
        break;
      case FEATURE_NAMES.ORGANIZATION_USERS:
        data.individual = renderDetailContainer(licenseMetas?.[SANDBOX_LICENSE_PN]?.seats);
        data.basic = renderDetailContainer(`Up to ${licenseMetas?.[BASIC_LICENSE_PN]?.seats}`);
        data.professional = renderDetailContainer('Custom');
        data.premium = renderDetailContainer('Custom');
        break;
      case FEATURE_NAMES.RECURRING_WORKFLOWS:
        data.individual = licenseMetas?.[SANDBOX_LICENSE_PN]?.is_prof ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        data.basic = licenseMetas?.[BASIC_LICENSE_PN]?.is_prof ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        data.professional = licenseMetas?.[PROF_LICENSE_PN]?.is_prof ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        data.premium = licenseMetas?.[PREM_LICENSE_PN]?.is_prof ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        break;
      case FEATURE_NAMES.TITRATION_IMAGE_GENERATOR:
        data.individual = renderDetailContainer('Beta');
        data.basic = renderDetailContainer('Beta');
        data.professional = renderDetailContainer('Beta');
        data.premium = renderDetailContainer('Beta');
        break;
      case FEATURE_NAMES.REAGENT_INVENTORY:
        data.individual = licenseMetas?.[SANDBOX_LICENSE_PN]?.reagent_inventory ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        data.basic = licenseMetas?.[BASIC_LICENSE_PN]?.reagent_inventory ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        data.professional = licenseMetas?.[PROF_LICENSE_PN]?.reagent_inventory ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        data.premium = licenseMetas?.[PREM_LICENSE_PN]?.reagent_inventory ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        break;
      case FEATURE_NAMES.FILE_SHARING:
        data.individual = licenseMetas?.[SANDBOX_LICENSE_PN]?.is_premium ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        data.basic = licenseMetas?.[BASIC_LICENSE_PN]?.is_premium ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        data.professional = licenseMetas?.[PROF_LICENSE_PN]?.is_premium ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        data.premium = licenseMetas?.[PREM_LICENSE_PN]?.is_premium ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        break;
      case FEATURE_NAMES.ANTIBODY_TITER_MANAGEMENT:
        data.individual = licenseMetas?.[SANDBOX_LICENSE_PN]?.is_premium ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        data.basic = licenseMetas?.[BASIC_LICENSE_PN]?.is_premium ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        data.professional = licenseMetas?.[PROF_LICENSE_PN]?.is_premium ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        data.premium = licenseMetas?.[PREM_LICENSE_PN]?.is_premium ? renderDetailContainer(CheckIcon) : renderDetailContainer('');
        break;
      default:
        break;
    }

    return data;
  }), [licenseMetas]);

  const renderCurrentLicense = () => {
    let copy = 'Your Current License'
    if (currentLicensePns.some((currentLicensePn) => currentLicensePn?.includes('FJBDRC-PREM') && currentLicensePn?.includes('TRIAL'))) {
      copy = 'Free Trial Active'
    }
    return (
      <CurrentLicense>{copy}</CurrentLicense>
    )
  };

  const renderActivateFreeTrialButton = () => {
    if (currentLicensePns.some((currentLicensePn) => currentLicensePn?.includes('FJBDRC-PREM') && currentLicensePn?.includes('TRIAL'))) {
      return null;
    }
    return (
      <CurrentLicense
        style={{color: COLORS.NOTIFICATIONS.SUCCESS.NORMAL, cursor: 'pointer'}}
        onClick={() => {
          callSecondaryModal(
            <ConfirmModal
              isSecondary
              message="Are you sure you want to enable your free trial of a premium license?"
              onConfirm={() => createFreeTrialLicense()}
            />
          )
        }}
      >
        Click Here to Activate Free Trial
      </CurrentLicense>
    )
  };

  rows.unshift({
    feature: <span />,
    individual: (
      <ColumnHeader>
        <ColumnHeaderText alignCenter={!currentLicensePn}>Individual</ColumnHeaderText>
        {currentLicensePn === SANDBOX_LICENSE_PN && renderCurrentLicense()}
      </ColumnHeader>
    ),
    basic: (
      <ColumnHeader>
        <ColumnHeaderText alignCenter={!currentLicensePn}>Essentials</ColumnHeaderText>
        {currentLicensePn === BASIC_LICENSE_PN && renderCurrentLicense()}
      </ColumnHeader>
    ),
    professional: (
      <ColumnHeader>
        <ColumnHeaderText alignCenter={!currentLicensePn}>Professional</ColumnHeaderText>
        {currentLicensePn?.includes('FJBDRC-PROF') && renderCurrentLicense()}
      </ColumnHeader>
    ),
    premium: (
      <ColumnHeader>
        <ColumnHeaderText alignCenter={!currentLicensePn}>Premium</ColumnHeaderText>
        {currentLicensePn?.includes('FJBDRC-PREM') ? renderCurrentLicense() : currentLicensePn ? renderActivateFreeTrialButton() : null}
      </ColumnHeader>
    ),
  });

  rows.push({
    feature: <div />,
    individual: renderDetailContainer(<div />),
    basic: (currentLicensePn === BASIC_LICENSE_PN || (!setLicense || !setActiveTab))
      ? (
        renderDetailContainer(<div />)
      )
      : setLicense && setActiveTab && renderDetailContainer(
        <PrimaryButton
          text="Create Free Org"
          data-test-id="create-basic-org"
          onClick={() => {
            if (setLicense && setActiveTab) {
              setLicense(BASIC_LICENSE_PN);
              setActiveTab('organization-attributes');
              mixpanel?.track('Clicked "Create Free Org"')
            }
          }}
          width="90%"
        />
      ),
    professional: (currentLicensePn?.includes('FJBDRC-PROF') || !upgradeLinkAction)
      ? (
        renderDetailContainer(<div />)
      )
      : renderDetailContainer(
        <PrimaryButton
          text={currentBestLicense?.attributes?.price > 0 ? 'Add More Seats' : 'Click for Quote'}
          onClick={() => {
            mixpanel?.track('Clicked "Click for Quote" in Select a License table (Professional)')
            handleQuoteRequest(PROF_LICENSE_PN)
          }}
          width="90%"
        />
      ),
    premium: ((currentLicensePn?.includes('FJBDRC-PREM') && !currentLicensePn?.includes('TRIAL')) || !upgradeLinkAction)
      ? (
        renderDetailContainer(<div />)
      )
      : renderDetailContainer(
        <PrimaryButton
          text={currentBestLicense?.attributes?.price > 0 ? 'Add More Seats' : 'Click for Quote'}
          onClick={() => {
            mixpanel?.track('Clicked "Click for Quote" in Select a License table (Premium)')
            handleQuoteRequest(PREM_LICENSE_PN)
          }}
          width="90%"
        />
      ),
  });
  
  return (
    <Loading watchRequests={watchRequests}>
      {onCancel && (
        <BackText onClick={() => onCancel()}>
          BACK TO MY LICENSE INFORMATION
        </BackText>
      )}
      <LicenseTable rowCount={rows?.length ?? 0}>
        {rows.map(({ feature, individual, basic, professional, premium }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={i}>
            {feature}
            {individual}
            {basic}
            {professional}
            {premium}
          </Fragment>
        ))}
      </LicenseTable>
      {upgradeLinkAction && (
        <AdditionalStorage>
          Add Additional Storage{' '}
          <LinkText onClick={handleQuoteRequest}>HERE</LinkText>
        </AdditionalStorage>
      )}
    </Loading>
  );
};

SelectLicenseTable.propTypes = {
  setLicense: PropTypes.func,
  setActiveTab: PropTypes.func,
  onCancel: PropTypes.func,
  currentLicense: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

SelectLicenseTable.defaultProps = {
  setLicense: undefined,
  setActiveTab: undefined,
  onCancel: undefined,
  currentLicense: {},
};

export default SelectLicenseTable;

const LicenseTable = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1.3fr) repeat(4, minmax(50px, 1fr));
  grid-template-rows: ${(props) => `1.3fr repeat(${props.rowCount - 2}, 1fr) 1.8fr;`};
  grid-column-gap: 10px;
  align-items: center;
  width: 1000px;
  padding: 0 10px;
  margin: auto;
`;

const ColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 3px solid ${globals.colors.primary};
  height: 100%;
`;

const ColumnHeaderText = styled.div`
  font: ${globals.fonts.h2};
  ${(props) => props.alignCenter && 'align-self: center'}
`;

const CurrentLicense = styled.div`
  font: ${globals.fonts.flag};
  color: ${globals.colors.primary};
  text-transform: uppercase;
  flex: 100%;
`;

const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  height: 100%;
  padding: 5px;
  text-align: center;
`;

const AdditionalStorage = styled.div`
  width: 975px;
  margin: 8px auto 0;
  text-align: right;
  font-size: 13px;
`;