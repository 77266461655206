import React from 'react';
import { createRoot } from 'react-dom/client';
import checkSupport from 'lib/utils/browserCompat/checkSupport';

import { EnhancedStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import SessionConnect from 'components/SessionConnect';
import AirbrakeErrorBoundary from 'components/AirbrakeErrorBoundary';
import MessagesConnect from 'containers/MessagesConnect';
import RootRouter from 'containers/RootRouter';
import configureStore from 'rdx/configureStore';
import { unregister as unregisterServiceWorker } from 'lib/registerServiceWorker';
import GlobalStyle from 'styles/GlobalStyle';
import UnsupportedBrowser from 'components/UnsupportedBrowser';
import { ThemeProvider } from 'styled-components';
import globals from 'styles/globals';
import SDKProvider from 'lib/scandit/ScanditSDKProvider';
import { ScanditProvider } from 'contexts/ScanditContext';
import { useInitializeMixpanel } from 'hooks/useInitializeMixpanel';
import { useInitializeClarity } from 'hooks/useInitializeClarity';
import { MixpanelContext } from 'containers/RootRouter/MixpanelContext';

declare global {
  interface Window {
    Cypress: Cypress.Cypress;
    store: EnhancedStore;
    ONETRUST_PERFORMANCE?: boolean;
  }
}

const isSupported = checkSupport();

const store = configureStore();

const container = document.getElementById('root')!;
const root = createRoot(container);

if (isSupported) {
  const ProvidedApp = () => {
    // Microsoft Clarity Analytics
    useInitializeClarity();

    // Mixpanel Analytics
    const mixpanel = useInitializeMixpanel();

    return (
      <SDKProvider>
        <ScanditProvider>
          <MixpanelContext.Provider value={mixpanel}>
            <SessionConnect timeout={10000} refreshInterval={500}>
              <BrowserRouter>
                <MessagesConnect>
                  <AirbrakeErrorBoundary>
                    <RootRouter />
                  </AirbrakeErrorBoundary>
                </MessagesConnect>
              </BrowserRouter>
            </SessionConnect>
          </MixpanelContext.Provider>
        </ScanditProvider>
      </SDKProvider>
    )
  }
  const App: React.FC = () => {

    return (
      <>
        <ThemeProvider theme={globals}>
          <GlobalStyle />
          <Provider store={store}>
            <ProvidedApp />
          </Provider>
        </ThemeProvider>
      </>
    )};

  root.render(<App />)

  if (window.Cypress) {
    window.store = store;
  }
} else {
  root.render(<BrowserRouter><UnsupportedBrowser /></BrowserRouter>);
}

unregisterServiceWorker();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
