import styled from 'styled-components';
import { NavLink } from 'components/NavHeader/shared';

import type { PropsWithChildren } from 'react';

export type Tab = {
  text: string,
  id: string,
  link?: string,
  render?: (renderProps: { text: string, id: string, link?: string, active: boolean }) => JSX.Element,
  noPointer?: boolean,
};

type TabsProps = {
  tabs: Tab[],
  handleTabChange: ({ tabId, link }: { tabId: Tab['id'], link?: string }) => void,
  active: string | null,
  className?: string,
  noUppercase?: boolean,
  tabMaxWidth?: string
};

const Tabs = ({
  tabs,
  handleTabChange,
  children,
  active,
  className,
  noUppercase,
  tabMaxWidth,
}: PropsWithChildren<TabsProps>) => {
  const renderTabs = () => tabs.length
    && tabs?.map(({ text, id, link, render, noPointer }) => {
      const isActiveTab = active === id;
      return (
        <NavLink
          key={id}
          href={link}
          onClick={() => handleTabChange({ tabId: id, link })}
          className={isActiveTab ? 'active' : ''}
          data-testid={`nav-${id}`}
          tab-index={0}
          role="listitem"
          aria-labelledby="shopping cart items"
          noPointer={noPointer}
          maxWidth={tabMaxWidth}
        >
          {render ? render({ text, id, link, active: isActiveTab }) : (
            <LinkWrapper {...{ noUppercase }}>
              {text}
            </LinkWrapper>
          )}
        </NavLink>
      );
    });

  return (
    <NavigationContainer className={className}>
      {renderTabs()}
      <Underline />
      {children}
    </NavigationContainer>
  );
};

export default Tabs;

const NavigationContainer = styled.nav`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  min-height: 50px;
  overflow: auto;
`;

const Underline = styled.div`
  flex: 1;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey70};
`;

export const LinkWrapper = styled.div<{ noUppercase?: boolean }>`
  flex: 1;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  text-transform: ${({ noUppercase }) => (noUppercase ? 'none' : 'uppercase')};
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  line-height: ${({ theme }) => theme.fontSizes.body};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
