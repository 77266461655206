import { useEffect, useMemo, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { callDrawer, closeAndUnsetDrawer, closeDrawer, getDrawerIsOpen, getDrawerUrlInfo, openDrawer, setDrawer } from 'rdx/modules/drawers/slice';

export const useDrawer = () => {
  const dispatch = useAppDispatch();
  const drawerUrlInfo = useAppSelector(getDrawerUrlInfo);
  const drawerIsOpen = useAppSelector(getDrawerIsOpen);

  const dispatchCallDrawer = useCallback((component, urlInfo) => dispatch(callDrawer({ component, urlInfo })), [dispatch]);
  const dispatchSetDrawer = useCallback((component, urlInfo) => dispatch(setDrawer({ component, urlInfo })), [dispatch]);
  const dispatchOpenDrawer = useCallback(() => dispatch(openDrawer()), [dispatch]);
  const dispatchCloseDrawer = useCallback(() => dispatch(closeDrawer()), [dispatch]);
  const dispatchCloseAndUnsetDrawer = useCallback(() => dispatch(closeAndUnsetDrawer()), [dispatch]);

  return {
    callDrawer: dispatchCallDrawer,
    setDrawer: dispatchSetDrawer,
    openDrawer: dispatchOpenDrawer,
    closeDrawer: dispatchCloseDrawer,
    closeAndUnsetDrawer: dispatchCloseAndUnsetDrawer,
    drawerIsOpen,
    urlInfo: drawerUrlInfo,
  };
};

export const useDrawerDirectory = (dir) => {
  const directory = useMemo(() => (dir ? { ...dir } : {}), [dir]);
  const drawerIsOpen = useAppSelector(getDrawerIsOpen);
  const {
    setDrawer: setDirectoryDrawer,
    openDrawer: openDirectoryDrawer,
    closeDrawer: closeDirectoryDrawer,
    drawerIsOpen: directoryDrawerIsOpen,
  } = useDrawer();

  const callDrawerDirectory = useCallback((id, keyList = {}) => {
    const drawer = directory[id];
    // if (!drawer) {
    //   throw new Error(`useDrawerDirectory: Tried to open drawer ${id}, but it does not exist in the directory`);
    // }
    // const requiredKeys = drawer.keys || [];
    // const missingKeys = requiredKeys.filter((key) => keyList[key] == null);
    // if (missingKeys.length) {
    //   throw new Error(`useDrawerDirectory: Missing data ${missingKeys} for drawer ${id}. Make sure all required keys are defined (pass empty string for an empty value)`);
    // }
    Object.entries(keyList).forEach(([key, value]) => {
      if (!(typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean' || Array.isArray(value))) {
        console.warn(`useDrawerDirectory: Tried to serialize non-primitive data key '${key}' of drawer ${id}. Are you sure you passed the right value?`, value);
      }
    });
    const data = drawer.mapData ? drawer.mapData(keyList) : {};
    if (data) {
      const component = drawer.render(data, drawer.dependencies);
      const urlInfo = {
        id,
        data: keyList,
      };
      setDirectoryDrawer(component, urlInfo);
      openDirectoryDrawer();
    }
  }, [directory, openDirectoryDrawer, setDirectoryDrawer]);

  // Open drawer if active in query params
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const drawerParam = params.get('drawer');
  const [autoOpen, setAutoOpen] = useState(!!drawerParam);

  useEffect(() => {
    if (drawerParam && !directoryDrawerIsOpen && autoOpen) {
      const currentDrawer = directory[drawerParam];
      const keyList = {};
      (currentDrawer?.keys || []).forEach((key) => {
        keyList[key] = params.get(key);
      });
      if (
        (currentDrawer && currentDrawer.mapData && currentDrawer.mapData(keyList))
        || (currentDrawer && currentDrawer.mapData === undefined)
      ) {
        callDrawerDirectory(drawerParam, keyList);
        setTimeout(() => {
          setAutoOpen(false);
        }, 100);
      }
    }
  }, [directory, location.search, autoOpen]); // eslint-disable-line

  useEffect(() => {
    if (drawerParam && !directoryDrawerIsOpen) {
      setAutoOpen(true);
    }
  }, [drawerParam]); // eslint-disable-line

  // Close drawer and on unmount
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    if (!drawerIsOpen)
    {  return () => {
      closeDirectoryDrawer();
      setDirectoryDrawer(null);
    };}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Return function to set the active drawer by id
  return callDrawerDirectory;
};
