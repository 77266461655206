import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { deleteBarcodeScan } from 'rdx/modules/barcodeScans/slice';

import { useModal } from 'hooks/useModal';
import { useDrawer } from 'hooks/useDrawer';
import convertYYMMDDtoDate from 'lib/utils/convertYYMMDDtoDate';

import ConfirmModal from 'containers/Modals/ConfirmModal';
import { Table, Cell } from 'components/GridTable';
import PrimaryButton from 'components/Button/PrimaryButton';
import DangerButton from 'components/Button/DangerButton';
import ReagentInventoryDrawer from 'containers/Reagents/ReagentInventoryDrawer';
import BarcodeScanDetail from 'components/BarcodeScans/BarcodeScanDetail';
import BaseDrawer from 'containers/Drawers/BaseDrawer';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';
import { AddReagentToWishlistParams, addReagentToWishlist } from 'rdx/modules/reagents/slice';
import { useMixpanel } from 'hooks/useMixpanel';
import Resource from 'lib/jsonApi/Resource';
import { BarcodeScanAttributes } from 'types/barcodeScans';

type BarcodeScanCardProps = {
  barcodeScan: Resource<BarcodeScanAttributes>,
  fetchBarcodeScans: () => void,
}

const BarcodeScanCard = ({ barcodeScan, fetchBarcodeScans }: BarcodeScanCardProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { callModal, closeAndUnsetModal } = useModal();
  const { callDrawer } = useDrawer();
  const mixpanel = useMixpanel();

  const organization = useAppSelector(getCurrentOrganization);

  const { reagent, inventory_reagent: inventoryReagent, meta, created_at } = barcodeScan.attributes;
  const { category, antigen, fluorochrome, variant, clone, size, species } = reagent ?? {};
  const { lot, expires_at: unformattedExpiresAt, vial } = meta ?? {};
  const expiresAt = convertYYMMDDtoDate(unformattedExpiresAt);

  let hasVialsInInventory = false;
  let vialsInInventory = 0;

  const owner = barcodeScan.getRel<{ first_name: string, last_name: string }>('owner');
  const { first_name: firstName, last_name: lastName }: { first_name?: string, last_name?: string } = owner instanceof Resource ? owner.attributes : {};

  const deleteAction = barcodeScan?.getAction('delete');
  const createInventoryReagentAction = barcodeScan?.getAction('create_inventory_reagent');
  const addReagentToWishlistAction = useMemo(() => organization?.getAction('add_reagent_to_wishlist') ?? null, [organization]);

  if (inventoryReagent?.quantity !== undefined) {
    hasVialsInInventory = Number.isInteger(inventoryReagent.quantity) && inventoryReagent.quantity > 0;
    vialsInInventory = inventoryReagent.quantity;
  }

  const handleDeleteScan = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    callModal(
      <ConfirmModal
        dangerous
        message="Are you sure you want to delete this scan?"
        onCancel={() => closeAndUnsetModal()}
        onConfirm={() => {
          dispatch(deleteBarcodeScan({
            action: deleteAction,
            onSuccess: () => {
              fetchBarcodeScans();
              closeAndUnsetModal();
            },
          }));
        }}
      />
    );
  };

  const handleAddToInventory = () => {
    if (variant) {
      callDrawer(
        <ReagentInventoryDrawer
          key={`${barcodeScan.id}-inventory-drawer`}
          lot={lot}
          expiresAt={expiresAt}
          variant={variant}
          createInventoryReagentAction={createInventoryReagentAction}
          onSuccess={() => {
            mixpanel?.track("Scanned reagent added to inventory");
            fetchBarcodeScans();
          }}
        />
      );
    }
  };

  const handleUpdateInventory = () => {
    if (inventoryReagent && variant) {
      const drawer = (
        <ReagentInventoryDrawer
          key={`${inventoryReagent.id}-inventory-drawer`}
          variant={variant}
          onSuccess={() => {
            fetchBarcodeScans();
            history.replace({ search: '' });
          }}
        />
      );
  
      callDrawer(drawer, {
        id: 'inventory-reagent-details',
        data: {
          inventoryReagentId: inventoryReagent.id,
        },
      });
    }
  };

  const handleOpenBarcodeScanDrawer = () => {
    const drawer = (
      <BaseDrawer key={barcodeScan.id} title={`Scan Details for ${antigen} ${fluorochrome}`}>
        <BarcodeScanDetail />
      </BaseDrawer>
    );

    callDrawer(drawer, {
      id: 'barcode-scan',
      data: {
        scanId: barcodeScan.id,
      },
    });
  };

  const handleAddToWishlist = () => {
    if (category && variant && antigen && fluorochrome && addReagentToWishlistAction) {
      const params: AddReagentToWishlistParams = {
        category,
        catalog_number: variant,
        antigen,
        fluorochrome,
      };
  
      if (clone) {
        params.antibody = clone;
      }

      if (size) {
        params.size = size;
      }

      if (species) {
        params.species = species;
      }
  
      dispatch(addReagentToWishlist({
        action: addReagentToWishlistAction,
        params,
        onSuccess: () => {
          mixpanel?.track("Scanned reagent added to wishlist");
        },
      }));
    }
  };

  return (
    <CardContainer onClick={handleOpenBarcodeScanDrawer}>
      <Cell label="Antigen" cellId="antigen">{antigen ?? '---'}</Cell>
      <Cell label="Fluorochrome" cellId="fluorochrome">{fluorochrome ?? '---'}</Cell>
      <Cell label="Clone" cellId="clone">{clone ?? '---'}</Cell>
      <Cell label="Catalog Number" cellId="cat-num">{variant ?? '---'}</Cell>
      <Cell label="Lot" cellId="lot">{lot ?? '---'}</Cell>
      <Cell label="Expires At" cellId="expires-at">{expiresAt ? moment(expiresAt).format('ll') : '---'}</Cell>
      <Cell label="Vial ID" cellId="vial">{vial ?? '---'}</Cell>
      <Cell label="Scanned By" cellId="scanned-by">{firstName} {lastName}</Cell>
      <Cell label="Scanned On" cellId="scanned-on">{moment(created_at).format('lll')}</Cell>
      <Cell label="Vials in Inventory" cellId="in-inventory">
        {hasVialsInInventory ? vialsInInventory : 'Not in inventory'}
      </Cell>
      <Cell label="Size" cellId="size">
        {reagent?.size ?? '---'}
      </Cell>
      <Cell label="Species" cellId="species">
        {reagent?.species?.join(', ') ?? '---'}
      </Cell>
      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      {((!inventoryReagent && createInventoryReagentAction && lot && variant) || (inventoryReagent && createInventoryReagentAction)) && (
        <ButtonContainer cellId='inventory-button'>
          <PrimaryButton
            text={hasVialsInInventory ? 'update inventory entry' : 'Add to Inventory'}
            width="175px"
            onClick={(e) => {
              e.stopPropagation();
              if (variant) {
                if (inventoryReagent) {
                  handleUpdateInventory();
                } else {
                  handleAddToInventory();
                }
              }
            }}
          />
        </ButtonContainer>
      )}
      {reagent && addReagentToWishlistAction && (
        <ButtonContainer cellId='wishlist-button'>
          <PrimaryButton
            text="Add to wishlist"
            width="175px"
            onClick={(e) => {
              e.stopPropagation();
              handleAddToWishlist();
            }}
          />
        </ButtonContainer>
      )}
      {deleteAction && (
        <DeleteButtonContainer>
          <DangerButton text="delete scan" width="175px" onClick={handleDeleteScan} />
        </DeleteButtonContainer>
      )}
    </CardContainer>
  );
};

export default BarcodeScanCard;

const ButtonContainer = styled.div<{ cellId?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ${({ cellId }) => cellId && css`grid-area: ${cellId};`}
`;

const DeleteButtonContainer = styled(ButtonContainer)`
  grid-area: delete-button;
`;

const CardContainer = styled(Table)<{ hasVialsInInventory?: boolean }>`
  grid-template-areas:
    "antigen     fluorochrome  clone       cat-num     lot           inventory-button"
    "expires-at  vial          scanned-by  scanned-on  in-inventory  wishlist-button"
    "species      size          -           -           -            delete-button";
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: ${({ hasVialsInInventory }) => (hasVialsInInventory ? '0.7' : '1')};

  &:hover {
    cursor: pointer;
    filter: brightness(0.98);
  }
`;
