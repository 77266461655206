import moment from 'moment';
import styled from 'styled-components';

const ReagentTable = ({ variant, inventoryReagent, reagent }) => {
  const updater = inventoryReagent?.getRel?.('updater') || reagent?.getRel?.('updater');
  const updatedAt = inventoryReagent?.updated_at || reagent?.updated_at;

  return (
    <Table>
      <tbody>
        <TableRow>
          <Label>Catalog Number</Label>
          <Value>{reagent?.variant || variant || '---'}</Value>
        </TableRow>
        <TableRow>
          <Label>Antigen</Label>
          <Value>{reagent?.antigen || '---'}</Value>
        </TableRow>
        <TableRow>
          <Label>Alt Name(s)</Label>
          <Value>{reagent?.alt_names?.join(', ') || '---'}</Value>
        </TableRow>
        <TableRow>
          <Label>Clone</Label>
          <Value>{reagent?.clone || '---'}</Value>
        </TableRow>
        <TableRow>
          <Label>Fluorochrome</Label>
          <Value>{reagent?.fluorochrome || '---'}</Value>
        </TableRow>
        <TableRow>
          <Label>Target Species</Label>
          <Value>{reagent?.species?.join(', ') || '---'}</Value>
        </TableRow>
        <TableRow>
          <Label>Host Species</Label>
          <Value>{reagent?.host_specie || '---'}</Value>
        </TableRow>
        <TableRow>
          <Label>Brand</Label>
          <Value>{reagent?.brand || '---'}</Value>
        </TableRow>
        <TableRow>
          <Label>Size</Label>
          <Value>{reagent?.size || '---'}</Value>
        </TableRow>
        {!!updater && !!updatedAt && (
          <>
            <TableRow>
              <Label>Updated At</Label>
              <Value>{moment(updatedAt).format('llll')}</Value>
            </TableRow>
            <TableRow>
              <Label>Updated By</Label>
              <Value>{`${updater.first_name} ${updater.last_name}`}</Value>
            </TableRow>
          </>
        )}
      </tbody>
    </Table>
  );
};

export default ReagentTable;

const Table = styled.table`
  padding-bottom: 40px;
`;

const Label = styled.td`
  font-weight: bold;
  padding: 10px;
  width: 200px;
  ${({ sublabel }) => (sublabel && 'padding: 5px 10px;')};
`;

const TableRow = styled.tr`
  background-color: ${({ theme, white }) => (white ? 'white' : theme.colors.darkGrey)};
`;

const Value = styled.td`
  padding: 10px;
`;
