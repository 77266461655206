import { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import pluralize from 'lib/utils/string/pluralize';

import { useDebouncedSearch } from 'hooks/useDebouncedSearch';
import { useModal } from 'hooks/useModal';
import { usePrevious } from 'hooks/usePrevious';

import BaseModal from 'containers/Modals/BaseModal';
import NavHeader from 'components/NavHeader';
import { humanizeString } from 'lib/utils/string';
import { PERMISSION_TYPES } from 'hocs/withPermissions';
import UserAvatar from 'components/UserAvatar';
import Combobox from 'components/Combobox/index';
import PrimaryButton from 'components/Button/PrimaryButton/index';
import { Lock } from 'components/Icons/index';
import { getCurrentGroup, smartGroupsActionRequest, setCurrentGroup } from 'rdx/modules/groups/slice';
import { getCurrentOrgSlug } from 'rdx/modules/organization/slice';
import { getSessionActions } from 'rdx/modules/auth/slice';
import { getPermissionUsers, requestPermissionUsers, setPermissionUsers } from 'rdx/modules/users/slice';
import { openChatFor } from 'rdx/modules/chat/slice';
import { ORG_PERMISSIONS } from 'lib/constants';

const getReadablePermissions = (permissions) => {
  const orgPermissions = Object.values(ORG_PERMISSIONS);
  return permissions?.map((permission) => {
    return orgPermissions.find((p) => p.value === permission) ?? permission
  })
}

const PermissionsRequiredModal = ({ missingPermissions, permissionType, groupID }) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const sessionActions = useSelector(getSessionActions);
  const orgSlug = useSelector(getCurrentOrgSlug);
  const raw = useSelector(getCurrentGroup);
  const groupDetailAction = useMemo(() => sessionActions.find((a) => a.name === 'group_details'), [sessionActions]);
  const group = useMemo(() => {
    if (raw?.unwrap) {
      return raw.unwrap();
    }
    return raw;
  }, [raw]);
  const prevGroup = usePrevious(group);

  useEffect(() => {
    const newGroup = group && group?.id !== prevGroup?.id;
    if (newGroup && permissionType === PERMISSION_TYPES.GROUP && groupDetailAction) {
      dispatch(smartGroupsActionRequest({
        action: groupDetailAction,
        params: {
          group_id: group.id,
        },
        putAction: setCurrentGroup,
      }));
    }
  }, [dispatch, group, groupDetailAction, permissionType, prevGroup]);
  const users = useSelector(getPermissionUsers);
  const [requestType, setRequestType] = useState('task');
  const [searchValue, setSearchValue] = useState('');
  const [selectedUserUUID, setSelectedUserUUID] = useState(null);

  const selectedUser = useMemo(() => (group?.owner?.uuid === selectedUserUUID
    ? group.owner
    : users?.unwrap()
      .find((u) => u.user.uuid === selectedUserUUID)?.user), [group, selectedUserUUID, users]);

  const { action, params } = useMemo(() => {
    const futureParams = { is_active: '1' };
    const scope = (requestType === 'permissions' && permissionType === PERMISSION_TYPES.ORG)
      ? ['manage_users']
      : missingPermissions;
    let act = {};
    switch (permissionType) {
      case PERMISSION_TYPES.ORG:
        act = sessionActions.find((a) => a.name === 'organization_users');
        futureParams.organization_id = orgSlug;
        break;

      case PERMISSION_TYPES.GROUP:
        act = sessionActions.find((a) => a.name === 'group_users');
        futureParams.group_id = group?.id || groupID;
        if (!group?.id && !groupID) {
          act = null;
        }
        break;
      default:
        break;
    }
    futureParams.scope = act?.field?.('scope')?.type === 'text'
      ? scope[0]
      : scope;
    return {
      action: act,
      params: futureParams,
    };
  }, [group, groupID, missingPermissions, orgSlug, permissionType, requestType, sessionActions]);
  const {
    loading,
    requestResource,
  } = useDebouncedSearch({ action, request: requestPermissionUsers, params });

  const readablePermissions = getReadablePermissions(missingPermissions);
  const missingPermissionText = `This action requires the following ${permissionType} level ${pluralize(missingPermissions.length, '{permission : permissions}')}:`;
  const searchSectionExplanation = `Search for users in this ${humanizeString(permissionType)}`;
  const searchExplanation = 'Search organization users by first name, last name and email.';
  const selectExplanation = 'After selecting a user click OPEN CHAT to open a chat with that user';
  const placeholder = requestType === 'permissions' ? 'Searching users that can grant permissions' : `Searching users with ${pluralize(missingPermissions.length, `${readablePermissions.join(', ')} {permission : permissions}`)}`;

  useEffect(() => {
    const isGroupOwnerOnly = (permissionType === PERMISSION_TYPES.GROUP && requestType === 'permissions');
    if (!isGroupOwnerOnly && action) {
      requestResource(searchValue);
    }
    return () => dispatch(setPermissionUsers(null));
  }, [dispatch, permissionType, requestResource, requestType, searchValue, action]);

  const searchOptions = useMemo(() => [
    {
      value: 'task',
      label: pluralize(missingPermissions.length, 'Users with {this : these} {permission : permissions}'),
    },
    {
      value: 'permissions',
      label: 'Users that can grant permissions',
    },
  ], [missingPermissions.length]);

  const ownerOption = useMemo(() => {
    const user = group?.owner;
    if (user) {
      const render = ({ highlighted }) => (
        <Option {...{ highlighted }}>
          <UserAvatar user={user} showInfo highlighted={highlighted} />
        </Option>
      );
      return [{ render, display: `${user.first_name} ${user.last_name}`, value: user.uuid }];
    }
    return [];
  }, [group]);

  const userOptions = useMemo(() => (permissionType === PERMISSION_TYPES.GROUP && requestType === 'permissions' ? ownerOption : users?.unwrap().map((u) => {
    const render = ({ highlighted }) => (
      <Option {...{ highlighted }}>
        <UserAvatar user={u.user} showInfo highlighted={highlighted} />
      </Option>
    );
    return { render, display: `${u.user.first_name} ${u.user.last_name}`, value: u.user.uuid };
  }) || []), [ownerOption, permissionType, requestType, users]);

  const handleOpenChat = useCallback(
    () => {
      const userUUIDs = [selectedUser?.uuid];
      let programmaticMessage = `Hi ${selectedUser?.first_name}, I need your help with a task that requires the ${pluralize(missingPermissions.length, `${missingPermissions.join(', ')} {permission : permissions}, but I don't have {it : them}.`)}`;
      programmaticMessage += requestType === 'task'
        ? ' Could you help me by doing ...'
        : ` Could you grant me ${pluralize(missingPermissions.length, '{this : these} {permission : permissions}?')}`;
      dispatch(openChatFor({ userUUIDs, programmaticMessage }));
      closeModal();
      setSelectedUserUUID(null);
      setSearchValue('');
      setRequestType('task');
    },
    [closeModal, dispatch, missingPermissions, requestType, selectedUser],
  );

  const handleClose = () => {
    closeModal();
    setSelectedUserUUID(null);
    setSearchValue('');
    setRequestType('task');
  };

  return (
    <BaseModal
      id="permissions-required-modal"
      dimensions={{ height: '500px', width: '700px' }}
      styles={{ padding: '0px' }}
      confirm={{ render: () => { } }}
      cancel={{ render: () => { } }}
    >
      <Wrapper>
        <NavHeader
          Icon={WarningLock}
          title={pluralize(missingPermissions.length, 'Missing Required {Permission : Permissions}')}
          isModal
        />
        <ModalInteriorContentWrapper>
          <SectionWrapper>
            <ExplanationText id="missing-permissions-explanation">{missingPermissionText}</ExplanationText>
            <PermissionList>
              {readablePermissions.map((scope) => {
                return (<Permission data-testid="missing-permission-li" id={`${scope.value ? scope.value : scope}-required-li`} key={scope}>{humanizeString(scope.label ? scope.label : scope)}</Permission>)})}
            </PermissionList>
          </SectionWrapper>
          <SectionWrapper>
            <ExplanationText id="permission-user-search-explanation">{searchSectionExplanation}</ExplanationText>
            <SmallText>
              {searchExplanation}
            </SmallText>
            <br />
            <SmallText>
              {selectExplanation}
            </SmallText>
            <RadioGroup
              name="search-types"
              options={searchOptions}
              selected={requestType}
              id="search-toggle-radio-group"
              onClick={(e) => { setSelectedUserUUID(null); setSearchValue(''); setRequestType(e.target.value); }}
            />
          </SectionWrapper>
          <SectionWrapper>
            <Combobox
              id="permission-users-select"
              options={userOptions}
              placeholder={placeholder}
              disableFilter
              loading={loading}
              width="100%"
              onChange={(v) => setSelectedUserUUID(v)}
              onInputChange={(v) => setSearchValue(v)}
            />
          </SectionWrapper>
          <Horizontal>
            <AvatarWrapper>
              {!!selectedUserUUID && (
                <UserAvatar id="selected-user-avatar" showInfo user={selectedUser} />
              )}
            </AvatarWrapper>
            <ButtonWrapper>
              <PrimaryButton
                hollow
                id="close-modal-button"
                text="cancel"
                onClick={handleClose}
              />
              <PrimaryButton
                id="open-chat-for-button"
                disabled={!selectedUserUUID}
                text="Open Chat"
                onClick={handleOpenChat}
              />
            </ButtonWrapper>
          </Horizontal>
        </ModalInteriorContentWrapper>
      </Wrapper>
    </BaseModal>
  );
};

PermissionsRequiredModal.propTypes = {
  permissionType: PropTypes.string,
  groupID: PropTypes.number,
  missingPermissions: PropTypes.arrayOf(PropTypes.string),
};

PermissionsRequiredModal.defaultProps = {
  permissionType: 'organization',
  missingPermissions: [],
  groupID: undefined,
};

export default PermissionsRequiredModal;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  width: 100%;
  height: 100%;
`;

const ExplanationText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.header};
`;

const ModalInteriorContentWrapper = styled.div`
  padding: 30px 60px;
  width: 100%;
  height: 410px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RadioGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0 25px 0;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const RadioInput = styled.input``;

const RadioLabel = styled.label`
  margin-left: 10px;
`;

const RadioGroup = ({ name, options, selected, onClick }) => (
  <RadioGroupWrapper>
    {options.map(({ label, value }) => (
      <RadioWrapper key={value}>
        <RadioInput checked={selected === value} type="radio" id={value} name={name} value={value} onChange={onClick} />
        <RadioLabel htmlFor={value}>{label}</RadioLabel>
      </RadioWrapper>
    ))}
  </RadioGroupWrapper>
);

const Option = styled.div`
  background-color: ${({ highlighted, theme }) =>
    (highlighted ? theme.colors.primary : theme.colors.white)};
  padding: 10px 5px;
`;

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SectionWrapper = styled.div`
  flex: ${({ flex }) => flex || 1};
  width: 100%;
`;

const Horizontal = styled.div`
  flex: 1;
  min-height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AvatarWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SmallText = styled.small`
  margin-top: 6px;
`;

const PermissionList = styled.ul`
  width: 100%;
`;
const Permission = styled.li`
  float: left;
  min-width: 120px;
`;

const WarningLock = styled(Lock)`
  fill: ${({ theme }) => theme.colors.warning};
`;
