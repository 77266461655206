import styled from 'styled-components';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from 'hooks/reduxHooks';
import { deleteBarcodeScan } from 'rdx/modules/barcodeScans/slice';

import { useModal } from 'hooks/useModal';

import ConfirmModal from 'containers/Modals/ConfirmModal';
import { DeleteIcon } from 'components/Icons';
import Resource from 'lib/jsonApi/Resource';
import { BarcodeScanAttributes } from 'types/barcodeScans';

type BarcodeScanCardProps = {
  barcodeScan: Resource<BarcodeScanAttributes>,
  fetchBarcodeScans: () => void,
}

const MobileBarcodeScanCard = ({ barcodeScan, fetchBarcodeScans }: BarcodeScanCardProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { callModal, closeAndUnsetModal } = useModal();

  const { reagent, inventory_reagent: inventoryReagent, meta, created_at } = barcodeScan.attributes;
  const { antigen, fluorochrome, variant } = reagent ?? {};
  const { lot } = meta ?? {};

  let hasVialsInInventory = false;
  let vialsInInventory = 0;

  const owner = barcodeScan.getRel<{ first_name: string, last_name: string }>('owner');
  const { first_name: firstName, last_name: lastName }: { first_name?: string, last_name?: string } = owner instanceof Resource ? owner.attributes : {};

  const deleteAction = barcodeScan?.getAction('delete');

  if (inventoryReagent?.quantity !== undefined) {
    hasVialsInInventory = Number.isInteger(inventoryReagent.quantity) && inventoryReagent.quantity > 0;
    vialsInInventory = inventoryReagent.quantity;
  }

  const handleDeleteScan = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    callModal(
      <ConfirmModal
        dangerous
        message="Are you sure you want to delete this scan?"
        onCancel={() => closeAndUnsetModal()}
        onConfirm={() => {
          dispatch(deleteBarcodeScan({
            action: deleteAction,
            onSuccess: () => {
              fetchBarcodeScans();
              closeAndUnsetModal();
            },
          }));
        }}
      />
    );
  };
  return (
    <Container onClick={() => { history.push(`/mobile-scanner/${barcodeScan.id}`); }}>
      {deleteAction && <DeleteIcon onClick={handleDeleteScan} />}
      <Text>
        <p>
          <Title>{reagent ? `${antigen} ${fluorochrome} | Cat: ${variant}` : 'no reagent found'}{lot ? ` | Lot: ${lot}` : ''}</Title>
        </p>
        <ScannedBy>
          Scanned by {firstName} {lastName} on {moment(created_at).format('lll')}
          {hasVialsInInventory && ` (${vialsInInventory} vials in inventory)`}
        </ScannedBy>
      </Text>
    </Container>
  );
};

export default MobileBarcodeScanCard;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  margin-bottom: 5px;
  cursor: pointer;
  width: 100%;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-weight: bold;
`;

const ScannedBy = styled.p`
  font-style: italic;
  font-size: 14px;
`;
