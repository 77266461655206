import { createAction, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import { FluorochromeAttributes } from 'types/fluorochromes';
import { GenericActionPayload } from 'types/redux-types';

const initialState: ResourceList<FluorochromeAttributes> = new ResourceList<FluorochromeAttributes>();

export type RequestFluorochromesQuery = {
  pageSize?: number,
  page?: number,
  search?: string
  fluorescent_protein?: boolean
  conjugatable?: boolean
}

const requestFluorochromes = createAction<GenericActionPayload<undefined, RequestFluorochromesQuery>>('requestFluorochromes');

const fluorochromesSlice = createSlice({
  name: 'fluorochromes',
  initialState,
  reducers: {
    setFluorochromes: (_, action: PayloadAction<ResourceList<FluorochromeAttributes>>) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setFluorochromes,
} = fluorochromesSlice.actions;

export {
  setFluorochromes,
  requestFluorochromes,
};

export const getFluorochromes = (state: RootState) => state.fluorochromes;

export default fluorochromesSlice.reducer;
