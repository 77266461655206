import { select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSession } from 'rdx/modules/auth/slice';

function* resetPassword({ payload }) {
  const action = (yield select(getSession))?.getAction('reset_password');
  const { success, error } = yield* makeRequest.action(action, payload);
  if (success) {
    // TODO
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default resetPassword;
