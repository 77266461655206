import { select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSession } from 'rdx/modules/auth/slice';

function* forgotPassword({ payload }) {
  const action = (yield select(getSession))?.getAction('forgot_password');
  const { onSuccess, ...rest } = payload;
  const { success, error } = yield* makeRequest.action(action, rest);
  if (success) {
    if (onSuccess) {
      onSuccess();
    }
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default forgotPassword;
