import { useMemo, useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import globals from 'styles/globals';
import bdrcLogo from 'assets/bdrc-logo-white.svg';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getSessionAction, requestLogout } from 'rdx/modules/auth/slice';
import { getCurrentOrganization, getOrganizations, requestOrganizationProfile, setCurrentOrganization } from 'rdx/modules/organization/slice';
import { createBarcodeScan, getBarcodeScans, requestBarcodeScans } from 'rdx/modules/barcodeScans/slice';

import { useUnwrappedSelector } from 'hooks/useUnwrappedSelector';

import { HomeLink } from 'components/NavHeader';
import PrimaryButton from 'components/Button/PrimaryButton';
import Dropdown from 'components/Dropdown';
import BarcodeScans from 'components/BarcodeScans';
import Resource from 'lib/jsonApi/Resource';
import BarcodeScanDetail from 'components/BarcodeScans/BarcodeScanDetail';
import { getIsMobile } from 'rdx/modules/app/slice';
import Scanner from 'components/Scanner';
import { DataMatrixIcon } from 'components/Icons';
import DangerButton from 'components/Button/DangerButton';
import { useMixpanel } from 'hooks/useMixpanel';
import ButtonWithIcon from 'components/ButtonWithIcon';
import { debounce } from 'lodash';

let allowScan = true;

const Mobile = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const isMobile = useAppSelector(getIsMobile);
  const mixpanel = useMixpanel();

  const organizations = useAppSelector(getOrganizations);
  const currentOrg = useAppSelector(getCurrentOrganization);
  const orgProfileAction = useAppSelector(getSessionAction('organization_profile'));
  const barcodeScans = useUnwrappedSelector(getBarcodeScans);

  const createBarcodeScanAction = useMemo(() => barcodeScans?.getAction?.('create') ?? null, [barcodeScans]);

  const [isScanning, setIsScanning] = useState(false);

  const organizationOptions = useMemo(() => organizations.map((org) => ({
    display: org.attributes?.name,
    value: org.attributes?.slug,
  })), [organizations]);

  useEffect(() => {
    if (currentOrg?.attributes?.slug && orgProfileAction && !currentOrg?.attributes?.current_user_scope) {
      dispatch(requestOrganizationProfile({
        organizationID: currentOrg.attributes.slug,
        onError: (params) => {
          if (params && 'currentOrgSlug' in params) {
            history.push(`/${params.currentOrgSlug}`);
          }
          dispatch(requestLogout({ onSuccess: () => {
            mixpanel?.track('Logout')
            mixpanel?.reset();
            history.push('/');
          } }));
        },
      }));
    }
  }, [currentOrg.attributes?.current_user_scope, currentOrg.attributes.slug, dispatch, history, mixpanel, orgProfileAction]);


  const handleSelectOrg = (slug: string) => {
    dispatch(setCurrentOrganization(new Resource()));
    history.push(`/${slug}`);
  };

  const dispatchCreateBarcodeScan = useCallback((code: string | null) => {
    if (createBarcodeScanAction && code && allowScan) {
      allowScan = false;
      dispatch(createBarcodeScan({
        action: createBarcodeScanAction,
        params: { code },
        onSuccess: () => {
          mixpanel?.track('Reagent added to Scanned List', { mobile: true });
          if (barcodeScans?.links?.self && currentOrg) {
            dispatch(requestBarcodeScans({
              link: barcodeScans?.links?.self,
            }));
          }
          allowScan = true;
        },
      }));
    }
  }, [barcodeScans?.links?.self, createBarcodeScanAction, currentOrg, dispatch, mixpanel]);

  if (!isMobile) {
    history.goBack?.();
    return <Redirect to={`/${currentOrg?.attributes?.slug}/reagents?tab=scanned-reagents`} />;
  }

  const scanHandler = debounce((code) => {
    if (code) {
      dispatchCreateBarcodeScan(code);
    }
    setIsScanning(false);
  }, 500);

  return (
    <Container>
      <MobileNavBar>
        <HomeLink to="/" data-testid="home-link">
          <LogoImg src={bdrcLogo} alt="BD Research Cloud" />
        </HomeLink>
        <PrimaryButton
          width="80px"
          text="Sign Out"
          onClick={() => {
            dispatch(requestLogout({ onSuccess: () => {
              mixpanel?.track('Logout');
              mixpanel?.reset();
              history.push('/login');
            } }));
          }}
        />
      </MobileNavBar>
      <Switch>
        <ContentContainer>
          <Route exact path={routeMatch.path}>
            {!isScanning ? (
              <>
                <OrgSelectSection>
                  <h1>Organization</h1>
                  <Dropdown
                    width="250px"
                    options={organizationOptions}
                    value={currentOrg?.attributes?.slug}
                    onChange={(slug) => {
                      if (typeof slug === 'string') {
                        handleSelectOrg(slug);
                      }
                    }}
                  />
                </OrgSelectSection>
                <Codes>
                  <ButtonContainer>
                    <ButtonWithIcon
                      onClick={() => setIsScanning(true)}
                      icon={<DataMatrixIcon size="80px" />}
                      text="Scan Code"
                      subtext="Scan the data matrix code on a vial to import reagent."
                    />
                  </ButtonContainer>
                </Codes>
                <BarcodeScans />
              </>
            ) : (
              <>
                <Scanner onScan={scanHandler} />
                <DangerButton style={{ marginTop: '15px'}} text="Done Scanning" onClick={() => setIsScanning(false)} width="120px" />
              </>
            )}
          </Route>
          <Route path={`${routeMatch.path}/:scanId`}>
            <BarcodeScanDetail />
          </Route>
        </ContentContainer>
      </Switch>
    </Container>
  );
};

export default Mobile;

const Container = styled.div`
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
`;

const MobileNavBar = styled.nav`
  background-color: ${globals.colors.charcoal};
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: sticky;
  top: 0;
  z-index: 300;
`;

const LogoImg = styled.img`
  height: 20px;
`;

const ContentContainer = styled.div`
  height: calc(100% - 55px);
  overflow: auto;
  padding: 20px;
`;

const OrgSelectSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: 0 0 20px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
`;

const Codes = styled.div`
  margin: 30px 0;
  h1 {
    margin-bottom: 10px;
  }
  ${ButtonContainer} {
    margin-top: 10px;
  }
`;