const convertYYMMDDtoDate = (yymmdd?: string) => {
  if (yymmdd) {
    const year = parseInt(`20${yymmdd.substring(0, 2)}`);
    const monthIndex = parseInt(yymmdd.substring(2, 4)) - 1;
    const date = parseInt(yymmdd.substring(4, 6));

    return new Date(year, monthIndex, date);
  }
  return null;
};

export default convertYYMMDDtoDate;
