import { put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { LASER_VALUE_CATEGORIES, setCytometerDeltaValues, setCytometerRcvValues } from 'rdx/modules/cytometers/slice';

function* requestCytometerLaserValues({ payload }, category) {
  const { link, params, query, onSuccess, onError } = payload;
  const setAction = (category === LASER_VALUE_CATEGORIES.rCV)
    ? setCytometerRcvValues
    : setCytometerDeltaValues;
  const { success, data, error } = yield* makeRequest.link(link, params, {
    ...query,
    category,
  });
  if (success && data) {
    const laserData = data.unwrap();
    yield put(setAction(laserData));
    if (onSuccess) {
      onSuccess(laserData);
    }
  } else {
    if (onError) {
      onError();
    }
    yield* putErrorActions({ error });
  }
  return null;
}

export default requestCytometerLaserValues;
