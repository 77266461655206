import { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  text?: string,
  subtext?: string,
  onClick: () => void,
  icon: ReactNode
};

const ButtonWithIcon = ({ text, subtext, icon, onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      {icon}
      {text && <h2>{text}</h2>}
      {subtext && <p>{subtext}</p>}
    </Container>
  )
}
export default ButtonWithIcon

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 275px;
  padding: 15px;
  border: 2px solid ${({ theme }) => theme.colors.hoverDarkGrey};
  border-radius: 15px;
  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  :hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 2px 0px, rgba(0, 0, 0, 0.36) 0px 4px 12px 0px, rgba(244, 244, 245, 0.16) 0px 0px 0px 1px inset;
    h2 {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  h2 {
    font-weight: bold;
  }

  p {
    font-size: 13px;
  }
`;