export const Routes = {
  HOME: 'home',
  WORKFLOWS: 'workflows',
  FILE_EXPLORER: 'file-explorer',
  GROUPS: 'groups',
  USERS: 'users',
  CYTOMETERS: 'cytometers',
  POPULATIONS: 'populations',
  REAGENTS: 'reagents',
  EXPERIMENTS: 'experiments',
  PANELS: 'panels',
  FCS_VIEWER: 'fcs-viewer',
  CHORUS_EXPERIMENTS: 'chorus-experiments',
};

export const RouteSessionMap = {
  HOME: '*',
  WORKFLOWS: 'workflow',
  FILE_EXPLORER: 'organization_files',
  GROUPS: 'groups',
  USERS: 'organization_users',
  CYTOMETERS: 'organization_cytometers',
  POPULATIONS: '*',
  FCS_VIEWER: '*',
  REAGENTS: 'reagents',
  EXPERIMENTS: 'experiments',
  PANELS: 'organization_panels',
  CHORUS_EXPERIMENTS: '*',
};

export const RouteGroups = {
  RESEARCH: [
    'WORKFLOWS',
    'FILE_EXPLORER',
    'FCS_VIEWER',
  ],
  PEOPLE: [
    'GROUPS',
    'USERS',
  ],
  RESOURCES: [
    'CYTOMETERS',
    'POPULATIONS',
    'REAGENTS',
    'EXPERIMENTS',
    'PANELS',
  ],
};

export const RouteGroupNames = {
  RESEARCH: 'Research',
  PEOPLE: 'People',
  RESOURCES: 'Resources',
};

export const RouteNames = {
  HOME: 'Home',
  WORKFLOWS: 'Workflows',
  FILE_EXPLORER: 'Data',
  GROUPS: 'Groups & Projects',
  USERS: 'Users',
  CYTOMETERS: 'Cytometers',
  POPULATIONS: 'Populations',
  REAGENTS: 'Reagents',
  EXPERIMENTS: 'Experiments',
  PANELS: 'Panels',
  FCS_VIEWER: 'FCS Viewer',
  CHORUS_EXPERIMENTS: 'Chorus Experiments',
};
