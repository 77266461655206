import { useAppSelector } from "hooks/reduxHooks";
import { sdk, SDKContext } from "lib/scandit/scanditSDK";
import { getSystemSettings } from "rdx/modules/auth/slice";
import { useEffect, useMemo, useState } from "react";

export default function SDKProvider({ children }: React.PropsWithChildren): JSX.Element {
  const [loading, setLoading] = useState(true);
  const providerValue = useMemo(() => ({ loading, sdk }), [loading]);
  const systemSettings = useAppSelector(getSystemSettings);

  useEffect(() => {
    async function start(): Promise<void> {
      if (systemSettings.scandit_license_key) {
        await sdk.initialize(systemSettings.scandit_license_key);
        setLoading(false);
      }
    }      
    void start();

    return () => {
      if (!loading) {
        void sdk.cleanup();
      }
    };
  }, [loading, systemSettings.scandit_license_key]);

  return <SDKContext.Provider value={providerValue}>{children}</SDKContext.Provider>;
}