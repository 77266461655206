import { useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { deleteBarcodeScan, getBarcodeScan, requestBarcodeScan, setBarcodeScan } from 'rdx/modules/barcodeScans/slice';
import { getCurrentOrganization } from 'rdx/modules/organization/slice';
import { getSessionLink } from 'rdx/modules/auth/slice';
import Resource from 'lib/jsonApi/Resource';

import { useLoading } from 'hooks/useLoading';
import { useDrawer } from 'hooks/useDrawer';
import { useModal } from 'hooks/useModal';
import convertYYMMDDtoDate from 'lib/utils/convertYYMMDDtoDate';

import ReagentInventoryDrawer from 'containers/Reagents/ReagentInventoryDrawer';
import ConfirmModal from 'containers/Modals/ConfirmModal';
import LoadingCircle from 'components/Loading/LoadingCircle';
import PrimaryButton from 'components/Button/PrimaryButton';
import DangerButton from 'components/Button/DangerButton';
import ScanTable from 'components/BarcodeScans/ScanTable';
import ReagentTable from 'components/BarcodeScans/ReagentTable';
import InventoryReagentTable from 'components/BarcodeScans/InventoryReagentTable';
import TiterTable from 'components/BarcodeScans/TiterTable';
import Link from 'components/Link';
import { AddReagentToWishlistParams, addReagentToWishlist } from 'rdx/modules/reagents/slice';
import { useMixpanel } from 'hooks/useMixpanel';

const BarcodeScanDetail = () => {
  const dispatch = useAppDispatch();
  const mixpanel = useMixpanel();
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const isMobileScanner = routeMatch.path.includes('mobile-scanner');

  const { callDrawer } = useDrawer();
  const { callModal, closeAndUnsetModal } = useModal();

  const barcodeScanID = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    return 'scanId' in params ? (params.scanId as string): urlParams.get('scanId');
  }, [location.search, params]);

  const currentOrganization = useAppSelector(getCurrentOrganization);
  const barcodeScan = useAppSelector(getBarcodeScan);
  const link = useAppSelector(getSessionLink('barcode_scan'));

  const loading = useLoading({ watchRequests: [requestBarcodeScan.type] });

  const fetchBarcodeScan = useCallback(() => {
    if (currentOrganization.attributes.slug && barcodeScanID) {
      dispatch(requestBarcodeScan({
        link,
        params: {
          organizationID: currentOrganization.attributes.slug,
          barcodeScanID,
        },
      }));
    }
  }, [currentOrganization.attributes.slug, dispatch, link, barcodeScanID]);

  useEffect(() => {
    fetchBarcodeScan();
  }, [fetchBarcodeScan]);

  useEffect(() => () => {
    dispatch(setBarcodeScan(new Resource()));
  }, [dispatch]);

  if (loading) {
    return <LoadingCircle />;
  }

  if (!barcodeScan.id) {
    return <p>No Barcode Found</p>;
  }

  const { attributes } = barcodeScan;
  const { reagent, inventory_reagent: inventoryReagent } = attributes;
  const barcodeOwner = barcodeScan.getRel<{ first_name: string, last_name: string }>('owner');
  const owner = barcodeOwner instanceof Resource ? barcodeOwner.attributes : null;

  const deleteAction = barcodeScan.getAction('delete');
  const createInventoryReagentAction = barcodeScan.getAction('create_inventory_reagent');
  const addReagentToWishlistAction = currentOrganization.getAction('add_reagent_to_wishlist');

  const handleAddToInventory = () => {
    if (reagent?.variant) {
      let expiresAt = null;
      if (attributes.meta.expires_at) {
        expiresAt = convertYYMMDDtoDate(attributes.meta.expires_at);
      }
      callDrawer(
        <ReagentInventoryDrawer
          key={`${barcodeScan.id}-inventory-drawer`}
          lot={attributes.meta.lot}
          expiresAt={expiresAt}
          variant={reagent.variant}
          createInventoryReagentAction={createInventoryReagentAction}
          onSuccess={() => {
            mixpanel?.track("Scanned reagent added to inventory")
            fetchBarcodeScan()
          }}
        />
      );
    }
  };

  const handleUpdateInventory = () => {
    if (inventoryReagent?.id && reagent?.variant) {
      const drawer = (
        <ReagentInventoryDrawer
          key={`${inventoryReagent.id}-inventory-drawer`}
          variant={reagent.variant}
          onSuccess={() => {
            fetchBarcodeScan();
            history.replace({ search: '' });
          }}
        />
      );
  
      callDrawer(drawer, {
        id: 'inventory-reagent-details',
        data: {
          inventoryReagentId: inventoryReagent.id,
        },
      });
    }
  };

  const handleDeleteScan = () => {
    callModal(
      <ConfirmModal
        dangerous
        message="Are you sure you want to delete this scan?"
        onCancel={() => closeAndUnsetModal()}
        onConfirm={() => {
          dispatch(deleteBarcodeScan({
            action: deleteAction,
            onSuccess: () => history.push('/mobile-scanner'),
          }));
        }}
      />
    );
  };

  const handleAddToWishlist = () => {
    if (reagent && addReagentToWishlistAction) {
      const wishlistParams: AddReagentToWishlistParams = {
        category: reagent.category,
        catalog_number: reagent.variant,
        antigen: reagent.antigen,
        fluorochrome: reagent.fluorochrome,
      };
  
      if (reagent.clone) {
        wishlistParams.antibody = reagent.clone;
      }

      if (reagent.size) {
        wishlistParams.size = reagent.size;
      }

      if (reagent.species) {
        wishlistParams.species = reagent.species;
      }
  
      dispatch(addReagentToWishlist({
        action: addReagentToWishlistAction,
        params: wishlistParams,
        onSuccess: () => {
          mixpanel?.track("Scanned reagent added to wishlist");
        },
      }));
    }
  };

  return (
    <>
      {isMobileScanner && (
        <LinkContainer>
          <Link to="/mobile-scanner">Back to Scans</Link>
        </LinkContainer>
      )}
      {attributes && (
        <Section>
          <Header>Scan Details</Header>
          <ScanTable scanAttributes={attributes} owner={owner ?? {}} />
        </Section>
      )}
      <Section>
        <Header>Matched Catalog Reagent</Header>
        {reagent ? (
          <ReagentTable reagent={reagent} />
        ) : (
          <em>No reagent found</em>
        )}
      </Section>
      <Section>
        <Header>Matched Inventory Entry</Header>
        {inventoryReagent ? (
          <InventoryReagentTable inventoryReagent={inventoryReagent} />
        ) : (
          <em>Not in inventory</em>
        )}
      </Section>
      <Section>
        {!!inventoryReagent?.titers?.length && (
          <>
            <Header>Titers</Header>
            <TiterTableContainer>
              {inventoryReagent.titers.map((titer) => (
                <TiterTable key={`${titer.ab_vol}-${titer.total_vol}`} titer={titer} />
              ))}
            </TiterTableContainer>
          </>
        )}
      </Section>
      {isMobileScanner && (
        <ButtonContainer>
          {(!inventoryReagent && createInventoryReagentAction && attributes.meta.lot && reagent?.variant) && (
            <PrimaryButton
              text="add to inventory"
              onClick={() => handleAddToInventory()}
            />
          )}
          {!!inventoryReagent && createInventoryReagentAction && (
            <PrimaryButton
              text="update inventory entry"
              onClick={() => handleUpdateInventory()}
              width="180px"
            />
          )}
          {reagent && addReagentToWishlistAction && (
            <PrimaryButton
              text="add to wishlist"
              onClick={() => handleAddToWishlist()}
            />
          )}
          {deleteAction && (
            <DangerButton
              text="delete scan"
              onClick={() => handleDeleteScan()}
            />
          )}
        </ButtonContainer>
      )}
    </>
  );
};

export default BarcodeScanDetail;

const TiterTableContainer = styled.div`
  table {
    margin-bottom: 10px;
  }
`;

const LinkContainer = styled.div`
  margin-bottom: 10px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
`;

const Header = styled.h2`
  margin-bottom: 5px;
`;
