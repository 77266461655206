import { put, select } from 'redux-saga/effects';

import Resource from 'lib/jsonApi/Resource';
import ResourceList from 'lib/jsonApi/ResourceList';
import { getAuthToken, setAuthToken } from 'lib/utils/authHelpers';
import Action from 'lib/jsonApi/Action';
import Field from 'lib/jsonApi/Field';
import { getApiVersion, setApiVersion } from 'rdx/modules/auth/slice';
import { FormattedResponse, JsonApiError, Link, ResourceObject } from 'types/json-api-types';
import { ResourceListParams } from 'types/api-helpers';

// <!-- BDRC-API-URL: https://api-bdrc.devau.io -->
export function parseApiURL() {
  const comments = [];
  const iterator = document.createNodeIterator(
    document.body,
    NodeFilter.SHOW_COMMENT,
    () => NodeFilter.FILTER_ACCEPT,
  );
  let curNode = iterator.nextNode();
  let apiURL;
  while (curNode) {
    const value = curNode.nodeValue;
    comments.push(value);
    const matchResult = value?.match(/^ BDRC-API-URL: ([\S]+) $/);
    if (matchResult) {
      [, apiURL] = matchResult;
      break;
    }
    curNode = iterator.nextNode();
  }

  return apiURL;
}

const API_URL = parseApiURL() ?? import.meta.env.VITE_API_ENDPOINT;
const IS_DEV = import.meta.env.DEV;

export const CONTENT_TYPES = {
  JSON: 'application/json',
  CSV: 'text/csv',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
} as const;

const CONNECTION_ERROR = 'CONN_ERR';

export type RequestParams<T = Record<string, any>> = T;
export type RequestQuery<T = Record<string, any>> = T;

interface JsonApiRequestParams {
  action?: Action;
  link?: Link;
  params?: RequestParams;
  query?: RequestQuery;
  type: string;
}

interface BuildRequestParams {
  urlTemplate: string;
  method: string;
  params?: RequestParams;
  query?: RequestQuery;
  fields?: Field[];
}

interface ExecuteRequestParams {
  url: string;
  method: string;
  body?: string;
}

function normalizeUrlTemplate(urlTemplate: string) {
  return urlTemplate.startsWith('http') ? urlTemplate : `${API_URL}${urlTemplate}`;
}

function searchParamIsTruthy(value: string | string[] | number) {
  if (Array.isArray(value)) {
    return !!value.length;
  }
  const val = value ?? null;
  return val !== null;
}

function formatRequestError(errorMsg: string) {
  console.error(errorMsg);
  const message = IS_DEV
    ? errorMsg
    : 'An unknown error occurred with your request';
  const error = { text: message };
  return { success: false, error, data: null };
}

// === Build request === //
function buildRequest({ urlTemplate, method, params = {}, query = {}, fields = [] }: BuildRequestParams): ExecuteRequestParams | { error: string } {
  let url = urlTemplate;
  const remainingParams = { ...params };

  // URL Params
  const paramRegex = /{(.+?)}/g;
  const urlParamKeys = [];
  /* eslint-disable-next-line no-restricted-syntax */
  for (const match of urlTemplate.matchAll(paramRegex)) {
    urlParamKeys.push(match[1]);
  }
  const missingParams: string[] = [];
  urlParamKeys.forEach((key) => {
    const value = remainingParams[key];
    if (value == null) {
      missingParams.push(key);
    } else {
      url = url.replace(`{${key}}`, value);
      delete remainingParams[key];
    }
  });
  if (missingParams.length) {
    const paramList = missingParams.map((param) => `{${param}}`).join(', ');
    return { error: `Request to ${urlTemplate} is missing required url param(s) ${paramList}` };
  }

  // URL Query
  const queryEntries = Object.entries(query);

  if (queryEntries.length) {
    const tempUrl: URL = new URL(url);
    queryEntries.forEach(([key, value]) => {
      if (searchParamIsTruthy(value)) {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            tempUrl.searchParams.append(key, v);
          });
        } else {
          tempUrl.searchParams.set(key, String(value));
        }
      }
    });
    url = tempUrl.toString();
  }

  // Request Body
  let body;
  if (['PUT', 'POST', 'PATCH', 'DELETE'].includes(method)) {
    const bodyData: Record<string, unknown> = {};
    fields.forEach((field) => {
      const key = field.name;
      const value = remainingParams[key];
      bodyData[key] = value;
      delete remainingParams[key];
    });
    body = JSON.stringify(bodyData);
  }

  if (IS_DEV && Object.keys(remainingParams).length !== 0) {
    const keys = Object.keys(remainingParams).join(', ');
    console.warn(`Some params were not included in the request because they are not allowed by the api: ${keys}`);
  }

  return { url, method, body };
}

function getHeaders() {
  const baseHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/vnd.api+json',
  };
  const authToken = getAuthToken();
  if (authToken) return { ...baseHeaders, Authorization: authToken };
  return baseHeaders;
}

// === Response formatters === //
function formatServerConnectionError(e: unknown) {
  console.error(e);
  const error = {
    text: 'There was an error connecting with the server',
    statusCode: CONNECTION_ERROR,
  };
  return { success: false, data: null, error };
}

function formatTextApiResponse(response: JsonApiError | string): FormattedResponse {
  if (typeof response === 'string') {
    return { success: true, error: null, data: response };
  }
  const error = {
    text: response.message,
    statusCode: response.statusCode,
    warning: response.warning,
    error: response.error,
  };
  return { success: false, error, data: null };
}

function formatJsonApiResponse(response: JsonApiError | ResourceObject | ResourceListParams): FormattedResponse {
  if ('error' in response) {
    const error = {
      text: response.message,
      statusCode: response.statusCode,
      warning: response.warning,
      error: response.error,
      link: response.link,
      meta: response.meta,
    };
    return { success: false, error, data: null };
  }
  if ('data' in response) {
    return { success: true, error: null, data: new ResourceList(response) };
  }
  if ('attributes' in response) {
    return { success: true, error: null, data: new Resource(response) };
  }
  return { success: true, error: null, data: response };
}

// === Execute request === //
function* executeRequest({ url, method, body }: ExecuteRequestParams) {
  const headers = getHeaders();
  const request = new Request(url, { method, headers, body });
  try {
    const res: Response = yield fetch(request);
    const newApiVersion = res.headers.get('api-version');
    const currentApiVersion: string = yield select(getApiVersion);
    if (newApiVersion !== currentApiVersion) {
      yield put(setApiVersion(newApiVersion));
    }
    if (request.url.includes(API_URL)) {
      const refreshToken = res.headers.get('x-refresh-token');
      if (refreshToken && refreshToken !== request.headers.get('authorization')) {
        setAuthToken(refreshToken);
      }
    }
    const responseType = res.headers.get('Content-type')?.split(';')[0];
    if (res.status === 204) {
      return formatJsonApiResponse({});
    }

    switch (responseType) {
      case CONTENT_TYPES.JSON: {
        const resData: JsonApiError | ResourceObject | ResourceListParams = yield res.json();
        return formatJsonApiResponse(resData);
      }
      case CONTENT_TYPES.CSV: {
        const resData: JsonApiError | string = yield res.text();
        return formatTextApiResponse(resData);
      }
      case CONTENT_TYPES.XLSX: {
        const resData: JsonApiError | string = yield res.text();
        return formatTextApiResponse(resData);
      }
      default: {
        const resData: JsonApiError | ResourceObject | ResourceListParams = yield res.json();
        console.warn(`Non-204 Response from ${res.url} lacks Content-type header, parsing as JSON`);
        return formatJsonApiResponse(resData);
      }
    }
  } catch (e) {
    return formatServerConnectionError(e);
  }
}

function* jsonApiRequest({ action, link, params, query, type }: JsonApiRequestParams) {
  let request: ExecuteRequestParams | { error: string };
  if (action) {
    request = buildRequest({
      urlTemplate: normalizeUrlTemplate(action.url),
      method: action.method,
      params,
      query,
      fields: action.fields,
    });
  } else if (link && typeof link === 'string') {
    request = buildRequest({
      urlTemplate: normalizeUrlTemplate(link),
      method: 'GET',
      params,
      query,
      fields: [],
    });
  } else {
    request = { error: `Tried to make a request with no defined link or action, redux type: ${type}` };
  }
  if ('error' in request) {
    return formatRequestError(request.error);
  }
  return yield* executeRequest(request);
}

export default jsonApiRequest;
