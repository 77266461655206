import styled from 'styled-components';
import { HalfWidthCell, LabelCell, BaseTable, TableRow } from 'components/BarcodeScans/shared';

const TiterTable = ({ titer }) => {
  const { ab_vol, total_vol } = titer;
  const abPerTotal = Number(Number((total_vol / ab_vol)).toFixed(2));
  let dilutionRatio = `1:${abPerTotal}`;
  if (Number.isNaN(abPerTotal) || !abPerTotal) {
    dilutionRatio = '---';
  }

  return (
    <Table>
      <tbody>
        {!!titer.ab_vol && (
          <TableRow>
            <LabelCell>Ab Vol (μL)</LabelCell>
            <HalfWidthCell>{titer.ab_vol}</HalfWidthCell>
          </TableRow>
        )}
        {!!titer.total_vol && (
          <TableRow>
            <LabelCell>Total Vol (μL)</LabelCell>
            <HalfWidthCell>{titer.total_vol}</HalfWidthCell>
          </TableRow>
        )}
        {!!dilutionRatio && (
          <TableRow>
            <LabelCell>Dilution</LabelCell>
            <HalfWidthCell>{dilutionRatio}</HalfWidthCell>
          </TableRow>
        )}
        {!!titer.time && (
          <TableRow>
            <LabelCell>Time (min)</LabelCell>
            <HalfWidthCell>{titer.time}</HalfWidthCell>
          </TableRow>
        )}
        {!!titer.temp && (
          <TableRow>
            <LabelCell>Temp (C)</LabelCell>
            <HalfWidthCell>{titer.temp}</HalfWidthCell>
          </TableRow>
        )}
        {!!titer.cell_loc && (
          <TableRow>
            <LabelCell>Cellular Location</LabelCell>
            <HalfWidthCell>{titer.cell_loc}</HalfWidthCell>
          </TableRow>
        )}
        {!!titer.notes && (
          <TableRow>
            <LabelCell>Notes</LabelCell>
            <HalfWidthCell>{titer.notes}</HalfWidthCell>
          </TableRow>
        )}
      </tbody>
    </Table>
  );
};

export default TiterTable;

const Table = styled(BaseTable)`
  td {
    padding: 5px 10px;
  }
`;
