import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import ResourceList from 'lib/jsonApi/ResourceList';
import { resetStore } from 'rdx/modules/app/slice';
import moment from 'moment';
import Resource from 'lib/jsonApi/Resource';
import type { ResourceObject, ResourceObjects } from 'types/json-api-types';

type Messages = Record<string, {
    unread: number,
    messages: ResourceList,
  }>;

type OpenChatFor = {
  userUUIDs: [string],
  name?: string,
  programmaticMessage?: string,
} | null;

type ChatState = {
  channels: ResourceObjects;
  messages: Messages;
  currentChannel: ResourceObject | null;
  openChatFor: OpenChatFor;
  // unconfirmed
  fetchError: string | null;
};

type SetMessagesType = {
  data: ResourceList,
  channelUUID: string,
};

type ReceiveMessagesType = {
  list: ResourceList,
  selfMessage: boolean,
  currentChannelUUID: string,
};

const initialState: ChatState = {
  channels: [],
  messages: {},
  currentChannel: null,
  openChatFor: null,
  fetchError: null,
};

const determineUnread = (selfMessage: boolean, channel: ResourceObject, currentChannelUUID: string) => {
  if (selfMessage) return 0;
  if (channel?.attributes?.uuid === currentChannelUUID) return 0;
  return 1;
};

const requestChannels = createAction('requestChannels');
const requestChannelDetail = createAction('requestChannelDetail');
const postChannel = createAction('postChannel');
const hideChannel = createAction('hideChannel');
const requestMessages = createAction('requestMessages');

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChannels: (state, action: PayloadAction<ResourceObjects>) => { state.channels = action.payload; },
    addChannel: (state, action: PayloadAction<Resource>) => {
      const newState = state.channels;
      const newChannelId = action.payload.attributes?.uuid;
      if (!state.channels.find((channel) => channel?.attributes?.uuid === newChannelId)) {
        newState.push(action.payload);
      }
      state.channels = newState;
    },
    setMessages: (state, action: PayloadAction<SetMessagesType>) => {
      const { data, channelUUID }: { data: ResourceList, channelUUID: string } = action.payload;
      const currentMessages = state.messages[channelUUID]?.messages;
      if (currentMessages && data?.meta?.pagination?.page !== undefined && data?.meta?.pagination?.page > 1) {
        state.messages = { ...state.messages,
          [channelUUID]: {
            unread: 0,
            messages: currentMessages.mergeWith(data),
          } };
      } else {
        const channelData = { unread: 0, messages: data };
        state.messages = { ...state.messages, [channelUUID]: channelData };
      }
    },
    receiveMessage(state, action: PayloadAction<ReceiveMessagesType>) {
      const { list, selfMessage, currentChannelUUID } = action.payload;

      const unwrappedList = list.unwrap() as Resource;

      const channel = unwrappedList.getRel('channel') as ResourceObject;

      const channelUUID = channel?.attributes?.uuid as string;

      const unread = (channel !== undefined ? state.messages[channelUUID]?.unread : 0) + determineUnread(selfMessage, channel, currentChannelUUID);

      const listData = list.data as Resource;

      if (!listData?.attributes?.created_at) {
        const createdAt = `${moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')}`;
        list.data = listData?.withAttrs({ created_at: createdAt });
      }

      if (state.messages[channelUUID]) {
        const channelData = { unread, messages: state.messages[channelUUID]?.messages?.mergeWith?.(list) || list };
        state.messages = { ...state.messages, [channelUUID]: channelData };
      }
    },
    setCurrentChannel: (state, action: PayloadAction<ResourceObject>) => {
      state.currentChannel = action.payload;
    },
    openChatFor: (state, action: PayloadAction<OpenChatFor>) => {
      state.openChatFor = action.payload;
    },
    setFetchError: (state, action: PayloadAction<string>) => {
      state.fetchError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setChannels,
  setMessages,
  setCurrentChannel,
  setFetchError,
  addChannel,
  receiveMessage,
  openChatFor,
} = chatSlice.actions;

export {
  // Saga Actions
  requestChannels,
  requestChannelDetail,
  postChannel,
  hideChannel,
  requestMessages,
  // Reducer Actions
  addChannel,
  receiveMessage,
  openChatFor,
  setChannels,
  setMessages,
  setCurrentChannel,
  setFetchError,
};

// Selectors
export const getChannels = (state: RootState) => state.chat.channels;
export const getMessages = (state: RootState) => state.chat.messages;
export const getCurrentChannel = (state: RootState) => state.chat.currentChannel;
export const getOpenChatFor = (state: RootState) => state.chat.openChatFor;
export const getMessagesRdxError = (state: RootState) => state.chat.fetchError;
export const getMessagesByCurrentChannel = (currentChannel: ResourceObject | null) => (state: RootState) => {
  const currentChannelUUID = currentChannel?.attributes?.uuid as string;
  return state.chat.messages[currentChannelUUID]?.messages?.unwrap?.() ?? [];
};
export const getMessagesPaginationByCurrentChannel = (currentChannel: ResourceObject | null) => (state: RootState) => {
  const currentChannelUUID = currentChannel?.attributes?.uuid as string;
  return state.chat.messages[currentChannelUUID]?.messages?.meta?.pagination ?? {};
};

// Reducer
export default chatSlice.reducer;
