/* eslint-disable no-nested-ternary */
import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';

import { humanizeString } from 'lib/utils/string';
import NoContentMessage from 'components/NoContentMessage';
import Pagination from 'components/Pagination/index';
import { getReagentEvents, requestReagentEvents } from 'rdx/modules/reagents/slice';

const ActivityLog = ({ eventsLink }) => {
  const dispatch = useDispatch();
  const [queryParams, onQueryChange] = useState({ page: 1 });

  useEffect(() => {
    dispatch(requestReagentEvents({
      link: eventsLink,
      query: { ...queryParams, sorting: 'created_at-' },
    }));
  }, [dispatch, eventsLink, queryParams]);

  const reagentEvents = useSelector(getReagentEvents);

  const events = reagentEvents?.unwrap?.();

  const renderActivityLog = (item) => {
    const rows = [];
    if (!isEmpty(item.diff)) {
      rows.push(Object.entries(item.diff).map((entry) => {
        const name = entry[0];
        const changes = entry[1];
        if (!changes[0] && !changes[1]) {
          return null;
        }

        return (
          <TableRow white key={`${name}-${item.id}`}>
            <Subvalue capitalize>{humanizeString(name)}</Subvalue>
            <Subvalue>{(name === 'titers')
              ? changes[0].map((c, i) => (
                <div style={{ marginBottom: '10px' }}>
                  <strong>Titer {i + 1}</strong>
                  {Object.entries(c).map(([field, value], j) => {
                    if (changes[1].length !== changes[0].length || !changes[1][i] || value !== Object.values(changes[1][i])[j]) {
                      return <p>{humanizeString(field)}: {typeof value === 'boolean' ? value.toString() : value || '---'}</p>;
                    }
                    return null;
                  })}
                </div>
              ))
              : moment(changes[0], moment.ISO_8601).isValid() ? moment(changes[0]).format('llll') : changes[0]}
            </Subvalue>
            <Subvalue>{(name === 'titers')
              ? changes[1].map((c, i) => (
                <div style={{ marginBottom: '10px' }}>
                  <strong>Titer {i + 1}</strong>
                  {Object.entries(c).map(([field, value], j) => {
                    if (changes[1].length !== changes[0].length || !changes[0][i] || value !== Object.values(changes[0][i])[j]) {
                      return <p>{humanizeString(field)}: {typeof value === 'boolean' ? value.toString() : value || '---'}</p>;
                    }
                    return null;
                  })}
                </div>
              ))
              : moment(changes[1], moment.ISO_8601).isValid() ? moment(changes[1]).format('llll') : changes[1]}
            </Subvalue>
          </TableRow>
        );
      }));
    }
    return rows;
  };

  const renderEventItem = (item, i) => {
    if (isEmpty(item.diff)) {
      return null;
    }
    const user = item?.getRel('user');
    const userFullName = `${user.first_name} ${user.last_name}`;
    const timestamp = moment(item.created_at).format('llll');
    const activityLogEntry = (
      <Fragment key={item.id}>
        <Subrow>
          <Label sublabel colSpan="3">{userFullName} on {timestamp}</Label>
        </Subrow>
        {renderActivityLog(item)}
      </Fragment>
    );
    if (i === 0) {
      return (
        <Fragment key={item.id}>
          <TableRow>
            <Label>Attribute Changed</Label>
            <Label>Before</Label>
            <Label>After</Label>
          </TableRow>
          {activityLogEntry}
        </Fragment>
      );
    }
    return activityLogEntry;
  };

  return (
    <>
      {events?.length ? (
        <>
          <Section>
            <Title>Activity Log</Title>
          </Section>
          <PaginationWrapper>
            <Pagination
              pagination={events?.meta.pagination}
              onPageChange={(newPage) => onQueryChange({ page: newPage })}
            />
          </PaginationWrapper>
          <Table>
            <tbody>
              {events?.map((item, i) => renderEventItem(item, i))}
            </tbody>
          </Table>
        </>
      ) : (
        <NoContentMessage item="event" />
      )}
    </>
  );
};

ActivityLog.propTypes = {
  eventsLink: PropTypes.string.isRequired,
};

export default ActivityLog;

const Section = styled.div`
  margin-bottom: 20px;
  padding-top: 30px;
`;

const Title = styled.h2`
  margin-bottom: 5px;
`;

const Table = styled.table`
  padding-bottom: 40px;
`;

const PaginationWrapper = styled.div`
  max-height: 38px;
`;

const Label = styled.td`
  font-weight: bold;
  padding: 10px;
  width: 200px;
  ${({ sublabel }) => (sublabel && 'padding: 5px 10px;')};
`;

const TableRow = styled.tr`
  background-color: ${({ theme, white }) => (white ? 'white' : theme.colors.darkGrey)};
`;

const Subrow = styled(TableRow)`
  background-color: ${({ theme }) => theme.colors.mediumGrey};
`;

const Value = styled.td`
  padding: 10px;
`;

const Subvalue = styled(Value)`
  padding: 5px 10px;
  ${({ capitalize }) => capitalize && 'text-transform: capitalize;'}
`;
