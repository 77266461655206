import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

export type CheckboxProps = React.PropsWithChildren<{
  id?: string,
  text?: string,
  value: boolean,
  onChange: (newVal: boolean) => void,
  disabled?: boolean,
  className?: string,
  style?: React.CSSProperties,
  readonly?: boolean
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'value'>>

const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>(({
  id, text, value, onChange, readonly,
  disabled, className, style, children, ...inputProps
}, ref) => {
  const handleClick = () => {
    onChange(!value);
  };

  return (
    <Shrinkwrap>
      <Container
        id={id}
        onClick={() => { handleClick(); }}
        disabled={disabled}
        className={className}
        style={style}
        ref={ref}
        readonly={readonly}
      >
        <HiddenInput
          {...inputProps}
          type="checkbox"
          value={String(value)}
          onChange={onChange}
        />
        <CustomCheckbox checked={value} />
        <CheckboxContent>
          {text ?? children}
        </CheckboxContent>
      </Container>
    </Shrinkwrap>
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;

const Shrinkwrap = styled.div`
  display: table;
`;

const Container = styled.div<{ disabled?: boolean, readonly?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  ${({ disabled }) => disabled && css`
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  `}
  ${({ readonly }) => readonly && css`
    cursor: default;
    pointer-events: none;
  `}
`;

const HiddenInput = styled.input`
  display: none;
`;

const CustomCheckbox = styled.div<{ checked?: boolean }>`
    position: relative;
    flex: 0 0 auto;
    top: 0px;
    left: 0px;
    width: 29px;
    height: 29px;
    background-color: transparent;
    border: solid ${({ theme }) => theme.colors.grey90} 1px;
    transition-timing-function: ease-out;
    transition: background-color 300ms;

    &:after {
      content: "";
      display: block;
      position: absolute;
      opacity: 0;
      left: 23px;
      top: 5px;
      width: 0px;
      height: 0px;
      border: solid ${({ theme }) => theme.colors.white};
      border-width: 0 3px 3px 0;
      transform: rotate(40deg);
      transition-timing-function: ease-out;
      transition: all 0ms;
    }

    ${({ checked }) => checked && css`
      background-color: ${({ theme }) => theme.colors.primary};
      border: solid ${({ theme }) => theme.colors.primary} 1px;

      &:after {
        opacity: 1;
        width: 8px;
        height: 17px;
        left: 10px;
        top: 3px;
        transition: all 300ms;
      }
    `}
`;

const CheckboxContent = styled.div`
  ${({ children }) => React.Children.count(children) > 0 && css`
    margin-left: 10px;
    margin-right: 10px;
  `}
`;
