import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'index';
import ResourceList from 'lib/jsonApi/ResourceList';
import Resource from 'lib/jsonApi/Resource';
import { GenericActionPayload } from 'types/redux-types';
import { resetStore } from '../app/slice';

type TrialsState = {
  trials: ResourceList | null;
  divaExport: ResourceList | null;
  completeFailed: boolean | null;
};

const initialState: TrialsState = {
  trials: null,
  divaExport: null,
  completeFailed: null,
};

const requestTrials = createAction('requestTrials');
const postTrial = createAction('postTrials');
const resumeTrial = createAction('resumeTrial');
const pauseTrial = createAction('pauseTrial');
const redoTrial = createAction('redoTrial');
const saveTrial = createAction<Record<string, unknown>>('saveTrial');
const completeTrial = createAction<Record<string, unknown>>('completeTrial');
const abandonTrial = createAction('abandonTrial');
const divaExport = createAction<GenericActionPayload<{ save_file?: boolean }>>('divaExport');
const shoppingList = createAction<GenericActionPayload>('shoppingList');
const addToBDBCart = createAction<GenericActionPayload>('addToBDBCart');
const addToBDBShoppingList = createAction<GenericActionPayload>('addToBDBShoppingList');
const fetchTrials = createAction<GenericActionPayload>('fetchTrials');
// const updateTrial = createAction('updateTrial');
const processSequencingFiles = createAction<GenericActionPayload>('processSequencingFiles');

export const trialsSlice = createSlice({
  name: 'trials',
  initialState,
  reducers: {
    setTrials: (state, action: PayloadAction<ResourceList>) => { state.trials = action.payload; },
    updateTrial: (state, action: PayloadAction<Resource>) => {
      state.trials?.withResource(action.payload);
    },
    setDivaExport: (state, action: PayloadAction<ResourceList>) => { state.trials = action.payload; },
    setCompleteTrial: (state, action: PayloadAction<ResourceList>) => { state.trials = action.payload; },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const { setTrials, updateTrial, setDivaExport, setCompleteTrial } = trialsSlice.actions;

// Actions
export {
  requestTrials,
  postTrial,
  resumeTrial,
  pauseTrial,
  redoTrial,
  saveTrial,
  completeTrial,
  abandonTrial,
  divaExport,
  shoppingList,
  addToBDBCart,
  fetchTrials,
  updateTrial,
  setTrials,
  setDivaExport,
  setCompleteTrial,
  addToBDBShoppingList,
  processSequencingFiles,
};

// Selectors

export const getDivaExport = (state: RootState) => {
  const divaExportData = state.trials?.divaExport?.data;
  if (!Array.isArray(divaExportData)) {
    return divaExportData?.attributes?.presignedUrl;
  }
  return undefined;
};
export const getTrials = (state: RootState) => state.trials.trials;
export const getCurrentTrial = (state: RootState) => {
  const trials = state.trials.trials?.unwrap();
  if (Array.isArray(trials)) {
    return trials[0] ?? null;
  }
  return null;
};
export const getCompleteFailed = (state: RootState) => state.trials?.completeFailed;

// Reducer
export default trialsSlice.reducer;
