import { useState, useCallback, useMemo } from 'react';
import { jwtDecode } from 'lib/utils/jwtDecode';
import PropTypes from 'prop-types';
import BaseModal from 'containers/Modals/BaseModal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useModal } from 'hooks/useModal';
import { useInterval } from 'hooks/useInterval';
import { requestLogout, requestSession } from 'rdx/modules/auth/slice';
import { getAuthToken } from 'lib/utils/authHelpers';
import { DISCONNECT_TIMEOUT } from 'hooks/useSessionTimeout';
import { useMixpanel } from 'hooks/useMixpanel';

const AutomaticLogoutModal = ({ message, onCancel, seconds }) => {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const history = useHistory();
  const { setModal, closeModal } = useModal();
  const authToken = getAuthToken();
  const authExpirationTime = jwtDecode(authToken).exp;
  const now = Math.round((Date.now() / 1000));
  const secondsUntilLogout = (authExpirationTime - 10) - now;
  const [secondsRemaining, setSecondsRemaining] = useState(seconds || secondsUntilLogout);

  const cancelAndClose = useCallback(() => {
    onCancel?.();
    setModal(null);
    closeModal();
  }, [closeModal, onCancel, setModal]);

  const cancel = useMemo(() => ({
    text: 'Stay Logged In',
    handler: () => {
      dispatch(requestSession());
      cancelAndClose();
    },
  }), [cancelAndClose, dispatch]);

  const logout = useCallback(() => {
    dispatch(requestLogout({ onSuccess: () => {
      mixpanel?.track('Logout');
      mixpanel?.reset();
      history.push('/login');
    } }));
  }, [dispatch, history, mixpanel]);

  const tick = () => {
    if (seconds) {
      setSecondsRemaining(secondsRemaining - 1);
      if (secondsRemaining <= 1) {
        logout();
      }
      return;
    }

    const tokenExpSecondsRemaining = (jwtDecode(authToken).exp - 10) - Math.round((Date.now() / 1000));
    if (tokenExpSecondsRemaining > DISCONNECT_TIMEOUT) {
      cancelAndClose();
      return;
    }

    setSecondsRemaining(tokenExpSecondsRemaining);
    if (tokenExpSecondsRemaining <= 1) {
      logout();
    }
  };

  useInterval(tick, 1000);

  return (
    <BaseModal
      dimensions={{
        width: '600px',
        height: '250px',
      }}
      confirm={{
        text: 'Log Out',
        handler: logout,
      }}
      cancel={cancel}
      forceClick
      preventClose
    >
      <h2>
        {message}
        {` You will be automatically logged out in ${secondsRemaining} seconds.`}
      </h2>
    </BaseModal>
  );
};

AutomaticLogoutModal.propTypes = {
  message: PropTypes.string,
  seconds: PropTypes.number,
  onCancel: PropTypes.func,
};

AutomaticLogoutModal.defaultProps = {
  message: '',
  seconds: null,
  onCancel: null,
};

export default AutomaticLogoutModal;
