import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'rdx/modules/app/slice';
import type { RootState } from 'index';
import { v1 } from 'uuid';
import Action from 'lib/jsonApi/Action';
import { PayloadOnSuccess } from 'types/redux-types';
import { LOGIN_FORM, messageTypes } from './constants';

type ApiEvent = {
  id?: string;
  type?: string;
  error?: MessageEvent;
  text?: string;
  confirmAction?: {
    type: string;
    payload: unknown
  };
  rdxAction?: {
    type: string;
    payload: unknown
  };
  jsonApiAction?: Action;
  onSuccess?: PayloadOnSuccess;
};

type MessageEvent =
  { id?: string;
    type?: string;
    text?: string;
    target?: string;
    link?: {
      href?: string;
      text?: string;
      state?: any;
    };
    function?: () => void;
    persist?: boolean;
  } | null;

type MessageState = {
  latestMessageEvent: MessageEvent
  apiConfirmEvents: Array<ApiEvent>;
};

const initialState: MessageState = {
  latestMessageEvent: null,
  apiConfirmEvents: [],
};

const MessageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    newMessageEvent: (state, action: PayloadAction<MessageEvent>) => {
      state.latestMessageEvent = { id: v1(), type: messageTypes.GENERAL, ...action.payload };
    },
    newLinkingMessageEvent: (state, action: PayloadAction<MessageEvent>) => { state.latestMessageEvent = { id: v1(), type: messageTypes.LINKING, ...action.payload }; },
    newErrorEvent: (state, action: PayloadAction<MessageEvent>) => { state.latestMessageEvent = { id: v1(), type: messageTypes.ERROR, ...action.payload }; },
    newApiConfirmEvent: (state, action: PayloadAction<ApiEvent>) => {
      const event = {
        id: v1(),
        type: messageTypes.CONFIRM,
        text: action.payload.error?.text,
        confirmAction: action.payload.rdxAction,
        rdxAction: action.payload.rdxAction,
        jsonApiAction: action.payload?.jsonApiAction,
        onSuccess: action.payload?.onSuccess,
      };
      state.apiConfirmEvents = [...state.apiConfirmEvents, event];
    },
    clearApiConfirmEvent: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      const newState = state.apiConfirmEvents.filter((e) => e.id !== id);
      state.apiConfirmEvents = newState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  newMessageEvent,
  newLinkingMessageEvent,
  newErrorEvent,
  newApiConfirmEvent,
  clearApiConfirmEvent,
} = MessageSlice.actions;

export {
  newMessageEvent,
  newLinkingMessageEvent,
  newErrorEvent,
  newApiConfirmEvent,
  clearApiConfirmEvent,
};

const filterMessageByTarget = (target: string | undefined) => (state: RootState) => {
  const latestMessage = state.messages.latestMessageEvent;
  if (latestMessage?.target === target) {
    return latestMessage;
  }
  return {};
};

export const getLatestMessageEvt = (state: RootState) => state.messages.latestMessageEvent;
export const getGenericMessageEvt = filterMessageByTarget(undefined);
export const getLatestLoginMessageEvt = filterMessageByTarget(LOGIN_FORM);
export const getApiConfirmEvents = (state: RootState) => state.messages.apiConfirmEvents;

export default MessageSlice.reducer;
