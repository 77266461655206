export const messageTypes = {
  GENERAL: 'GENERAL',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  CONFIRM: 'CONFIRM',
  LINKING: 'LINKING',
};

// message targets
export const LOGIN_FORM = 'LOGIN_FORM';
export const TECH_SUPPORT_LOGIN_FORM = 'TECH_SUPPORT_LOGIN_FORM';
export const INVITE_USER_FORM = 'INVITE_USER_FORM';
export const ORDER_LIST = 'ORDER_LIST';
export const REGISTER_PWD_FORM = 'REGISTER_PWD_FORM';
export const CREATE_USER_FORM = 'CREATE_USER_FORM';
