import { select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSession } from 'rdx/modules/auth/slice';

function* createAccount({ payload }) {
  const { params, onSuccess } = payload;
  const action = (yield select(getSession))?.getAction('signup');
  const { success, error } = yield* makeRequest.action(action, params);
  if (success) {
    onSuccess?.();
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default createAccount;
