import React, { useCallback, useMemo } from 'react';
import ToolTip from 'components/ToolTip';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { callTooltip, closeTooltip, getTooltipIsOpen } from 'rdx/modules/tooltip/slice';

export const useTooltip = (message?: string, render?: () => JSX.Element) => {
  const dispatch = useAppDispatch();
  const tooltipIsOpen = useAppSelector(getTooltipIsOpen);

  const dispatchCallTooltip = useCallback((component: React.ReactElement) => dispatch(callTooltip(component)), [dispatch]);
  const dispatchCloseTooltip = useCallback(() => dispatch(closeTooltip()), [dispatch]);

  const props = useMemo(() => {
    if (message) {
      return {
        onMouseOver: (e: React.MouseEvent) => dispatchCallTooltip(<ToolTip message={message} child={e} />),
        onFocus: (e: React.FocusEvent) => dispatchCallTooltip(<ToolTip message={message} child={e} />),
        onBlur: dispatchCloseTooltip,
        onMouseLeave: dispatchCloseTooltip,
      };
    }
    if (render) {
      return {
        onMouseOver: (e: React.MouseEvent) => dispatchCallTooltip(<ToolTip render={render} child={e} />),
        onFocus: (e: React.FocusEvent) => dispatchCallTooltip(<ToolTip render={render} child={e} />),
        onBlur: dispatchCloseTooltip,
        onMouseLeave: dispatchCloseTooltip,
      };
    }
    return {};
  }, [dispatchCallTooltip, dispatchCloseTooltip, message, render]);

  return {
    callTooltip: dispatchCallTooltip,
    closeTooltip: dispatchCloseTooltip,
    tooltipIsOpen,
    props,
  };
};
