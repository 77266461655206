import React, { useState, useRef, useMemo, useCallback, useEffect, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import fuzzysort from 'fuzzysort';
import { throttle } from 'lodash';
import ResizeObserver from 'react-resize-observer';

import newlinesToHtml from 'lib/utils/newlinesToHtml';

import OverlayPortal from 'components/OverlayPortal/index';
import { ChevronDown, XIcon } from 'components/Icons/index';

const DROPDOWN_TYPE = {
  STANDARD: 'standard',
  BORDERLESS: 'borderless',
  COMPACT: 'compact',
  COMPACT_WITH_BORDER: 'compact_with_border',
};

const FUZZY_SORT_OPTIONS = {
  threshold: -8000,
  allowTypo: true,
  keys: ['display', 'subText'],
};

function sortDecimals<T>(array: DropdownOption<T>[]) {
  return array.sort((a, b) => {
    let nums1: string[] = [];
    let nums2: string[] = [];
    if (a?.value && typeof a.value === 'string' && typeof b.value === 'string') {
      nums1 = b.value.split('.');
      nums2 = a.value.split('.');
    }

    for (let i = 0; i < nums1.length; i++) {
      if (nums2[i]) {
        if (nums1[i] !== nums2[i]) {
          return Number(nums1[i]) - Number(nums2[i]);
        }
      } else {
        return 1;
      }
    }
    return -1;
  });
}

const filterAlgorithm = <T,>(filterText: string, options: DropdownOption<T>[], orderDecimals?: boolean) => {
  let results = options;
  if (filterText) {
    results = fuzzysort
      .go(filterText, options, FUZZY_SORT_OPTIONS)
      .map((r) => r.obj);
  }
  if (orderDecimals) {
    return sortDecimals(results);
  }
  return results;
};

type DropdownType = 'standard' | 'borderless' | 'compact' | 'compact_with_border';

export type DropdownValue = string | number | boolean | null;

export type DropdownOption<T> = {
  value: T | null,
  display: ReactNode,
  subText?: string,
  data?: any,
  render?: (args?: { highlighted?: boolean, option?: DropdownOption<T> }) => ReactNode | undefined,
  discouraged?: boolean,
  subOption?: boolean,
};

type DisplayOptionTextPropsType = {
  font?: string,
  fontSize?: string,
  fontStyle?: string,
  textTransform?: string,
} | Record<string, never>;

export interface DropdownProps<T> {
  id?: string;
  dataTestId?: string;
  filter?: boolean;
  handleInputChange?: (input: string) => void;
  width?: string;
  height?: string;
  placeholder?: string;
  label?: ReactNode;
  options: DropdownOption<T>[];
  nullOption?: string;
  value: T;
  onChange: (value: T | null, data?: any) => void;
  onFocus?: (e: React.FocusEvent<HTMLDivElement, Element>) => void,
  onBlur?: (e: React.FocusEvent<HTMLDivElement, Element>) => void,
  color?: string,
  backgroundColor?: string,
  borderColor?: string,
  type?: DropdownType,
  className?: string,
  style?: React.CSSProperties,
  displayOptionTextProps?: DisplayOptionTextPropsType,
  placeholderTextProps?: { fontStyle: string } | Record<string, never>,
  disabled?: boolean,
  dropdownStateListener?: (isOpen: boolean) => void,
  initialValue?: T,
  orderDecimals?: boolean;
}

const Dropdown = <T = DropdownValue>({
  id = 'dropdown',
  dataTestId = 'dropdown-container',
  filter = false,
  handleInputChange,
  width = '230px',
  height,
  placeholder = '',
  label = '',
  options = [],
  nullOption,
  value,
  onChange,
  onFocus,
  onBlur,
  color,
  backgroundColor = 'transparent',
  borderColor,
  type = 'standard',
  className,
  style,
  displayOptionTextProps = {},
  placeholderTextProps = {},
  disabled = false,
  dropdownStateListener,
  initialValue,
  orderDecimals,
}: DropdownProps<T>) => {
  const [textFieldValue, setTextFieldValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [expandTop, setExpandTop] = useState(false);
  const [highlightedValue, setHighlightedValue] = useState<T | null>(null);
  const [initialValueSet, setInitialValueSet] = useState(false);

  const focusTimeout = useRef<NodeJS.Timeout>();
  const containerRef = useRef<HTMLDivElement>();
  const scrollRef = useRef<HTMLDivElement>();
  const textFieldRef = useRef<HTMLDivElement>();
  const textFieldContainerRef = useRef<HTMLDivElement>();
  const optionRefs = useRef<HTMLDivElement[]>([]);
  const portalRef = useRef<HTMLDivElement | null>();

  const allOptions: DropdownOption<T>[] = useMemo(() => {
    if (nullOption !== null && nullOption !== undefined) {
      return [{ value: null, display: nullOption, data: undefined }, ...options];
    }
    return options;
  }, [options, nullOption]);

  if (initialValue && !initialValueSet) {
    const option = allOptions.find((opt) => opt.value === initialValue);
    if (option) {
      onChange(initialValue, option.data);
      setInitialValueSet(true);
    }
  }

  const selectedOption = useMemo(() => {
    const option = allOptions.find((opt) => {
      if (nullOption === undefined) {
        return opt.value === value;
      }
      if (value != null) {
        return opt.value === value;
      }
      return false;
    });
    return option ?? null;
  }, [value, allOptions, nullOption]);

  const filteredOptions = useMemo(
    () => (handleInputChange ? allOptions : filterAlgorithm<T>(textFieldValue, allOptions, orderDecimals)),
    [handleInputChange, allOptions, textFieldValue, orderDecimals],
  );

  const highlightedItemIndex = useMemo(() => {
    const index = filteredOptions.findIndex(
      (opt) => opt.value === highlightedValue,
    );
    return index > -1 ? index : null;
  }, [filteredOptions, highlightedValue]);

  const open = useCallback(() => {
    setIsOpen(true);
    updatePortal();
    dropdownStateListener?.(true);
    if (filter) {
      scrollToIndex(null);
    }
  }, [dropdownStateListener, filter]);

  useEffect(() => {
    if (isOpen && portalRef.current) {
      let containerBottom = containerRef.current?.getBoundingClientRect().bottom;
      if (!containerBottom) {
        containerBottom = 0;
      }
      if ((window.innerHeight - 340 - containerBottom) < 0) {
        setExpandTop(true);
        setHighlightedValue(null);
        scrollToIndex(null);
      }
      updatePortal();
      open();
    }
  }, [open, isOpen]);

  // Cleanup
  useEffect(() => () => {
    if (focusTimeout.current) {
      clearTimeout(focusTimeout.current);
    }
  }, []);

  const close = () => {
    updatePortal();
    setExpandTop(false);
    dropdownStateListener?.(false);
    setIsOpen(false);
    portalRef.current = null;
  };

  const handleClear = (e: React.FocusEvent<HTMLDivElement, Element> | React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    handleTextFieldChange('');
  };

  const updatePortal = () => {
    if (containerRef?.current && portalRef?.current) {
      const { bottom, left, width: containerWidth } = containerRef.current.getBoundingClientRect();
      portalRef.current.style.width = `${containerWidth}px`;
      portalRef.current.style.top = `${bottom}px`;
      portalRef.current.style.left = `${left}px`;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleContainerReflow = useCallback(throttle(() => {
    updatePortal();
    if (isOpen) {
      close();
    }
  }, 10, { leading: true, trailing: true }), []);

  // === Text Field Handling === //
  const handleTextFieldChange = (textValue: string) => {
    if (handleInputChange) {
      handleInputChange(textValue);
    }
    setTextFieldValue(textValue);
  };

  // === Selection Handling === //
  const selectOptionByValue = (optionValue: T | null) => {
    const option = allOptions.find((opt) => opt.value === optionValue);
    onChange(optionValue, option?.data);
    setTextFieldValue('');
    close();
  };

  const selectOption = (option: DropdownOption<T>) => {
    const val = option?.value != null ? option.value : null;
    selectOptionByValue(val);
  };

  // === Scroll Handling === //
  const scrollToIndex = (index: number | null) => {
    const container = scrollRef?.current;
    const containerBounds = container?.getBoundingClientRect();
    const textFieldBounds = textFieldContainerRef?.current?.getBoundingClientRect();
    let optionBounds;
    if (index) {
      optionBounds = optionRefs?.current?.[index]?.getBoundingClientRect();
    }

    if (containerBounds && container) {
      if (index === null) {
        container.scrollTop = 0;
      } else if (optionBounds) {
        const optionTopOffset = optionBounds.top - (textFieldBounds?.bottom ?? containerBounds.top);
        const optionBottomOffset = optionBounds.bottom - containerBounds.bottom;
        if (optionTopOffset < 0) {
          container.scrollTop += optionTopOffset;
        } else if (optionBottomOffset > 0) {
          container.scrollTop += optionBottomOffset;
        }
      }
    }
  };

  // === Highlight Handling === //
  const highlightItem = (item: DropdownOption<T>) => {
    const val = item?.value != null ? item.value : null;
    setHighlightedValue(val);
  };

  const highlightIndex = (index: number) => {
    highlightItem(filteredOptions[index]);
  };

  const incrementHighlightIndex = (delta: 1 | -1) => {
    if (highlightedItemIndex == null) {
      highlightIndex(0);
      scrollToIndex(0);
    } else {
      const nextIndex =
        (highlightedItemIndex + delta + filteredOptions.length)
        % filteredOptions.length;
      highlightIndex(nextIndex);
      scrollToIndex(nextIndex);
    }
  };
  const highlightNextOption = () => incrementHighlightIndex(1);
  const highlightPrevOption = () => incrementHighlightIndex(-1);

  // === Focus Handling === //
  const handleFocus = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    open();
    onFocus?.(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    if (!containerRef.current?.contains(document.activeElement)) {
      close();
      handleClear(e);
      onBlur?.(e);
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (!isOpen) {
      open();
    }
    focusTimeout.current = setTimeout(() => {
      if (filter && textFieldRef?.current) {
        textFieldRef?.current?.focus();
      } else {
        containerRef?.current?.focus();
      }
    }, 0);
  };

  // === Keypress Handling === //
  const handleTextFieldKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (!isOpen) {
          open();
        } else {
          highlightNextOption();
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (!isOpen) {
          open();
        } else {
          highlightPrevOption();
        }
        break;
      case 'Enter':
        e.preventDefault();
        if (isOpen && highlightedValue) {
          selectOptionByValue(highlightedValue);
        }
        break;
      case 'Escape':
        e.preventDefault();
        if (isOpen) {
          if (textFieldValue) {
            setTextFieldValue('');
          } else {
            close();
          }
        }
        break;
      default:
        break;
    }
  };

  const attachPortalRef = useCallback((ref: HTMLDivElement | null) => {
    if (ref && !portalRef.current) {
      portalRef.current = ref;
      updatePortal();
    }
  }, []);

  return (
    <ContainerContainer width={width}>
      <Container
        id={id}
        disabled={disabled}
        data-testid={dataTestId}
        ref={(ref: HTMLDivElement) => {
          containerRef.current = ref;
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseDown={handleMouseDown}
        onKeyDown={handleTextFieldKeypress}
        tabIndex={disabled
          ? -1
          : filter
            ? undefined
            : 0}
        className={className}
        style={style}
        {...{ type }}
      >
        <ResizeObserver onReflow={handleContainerReflow} />
        {label && <Label {...{ type, color }}>{label}</Label>}
        <DisplayContainer
          {...{ borderColor, backgroundColor, type, height }}
          expanded={isOpen}
          changeBg={isOpen}
          expandTop={expandTop}
        >
          {selectedOption?.display
            ? (
              <DisplayValue {...{ type, color, height, ...displayOptionTextProps }} title={typeof selectedOption.display === 'string' ? selectedOption.display : ''}>
                {selectedOption.display}
              </DisplayValue>
            ) : (
              <PlaceholderValue {...{ type, color, ...displayOptionTextProps, ...placeholderTextProps }}>
                {placeholder}
              </PlaceholderValue>
            )}
          <DropdownArrow size="sm" open={isOpen} {...{ type }} />
        </DisplayContainer>
        {isOpen && (
          <OverlayPortal ref={attachPortalRef}>
            <ExpandingContainer
              ref={(ref: HTMLDivElement) => {
                if (ref) {
                  scrollRef.current = ref;
                }
              }}
              visible={isOpen}
              expandTop={expandTop}
              borderColor={borderColor}
              type={type}
              height={height}
            >
              {filter && (
                <TextFieldContainer
                  ref={(ref: HTMLDivElement) => {
                    if (ref) {
                      textFieldContainerRef.current = ref;
                    }
                  }}
                >
                  <TextField
                    autoComplete="off"
                    id={`${id}-filter-input`}
                    data-testid="dropdown-filter-input"
                    ref={(ref: HTMLDivElement | null) => {
                      if (ref) {
                        textFieldRef.current = ref;
                      }
                    }}
                    value={textFieldValue}
                    onChange={(e) => {
                      e.preventDefault();
                      handleTextFieldChange(e.target.value);
                    }}
                    type={type || 'text'}
                    placeholder="Type to filter"
                  />
                  <ClearButton
                    size="sm"
                    data-testid="clear-icon"
                    id={id ? `${id}-clear-button` : undefined}
                    show={!!textFieldValue}
                    onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => { handleClear(e); }}
                    onMouseDown={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => { e.preventDefault(); }}
                  />
                </TextFieldContainer>
              )}
              <OptionList data-testid="dropdown-option-list">
                {!disabled && filteredOptions.map((option, index) => {
                  optionRefs.current = [];
                  const highlighted = highlightedValue === option.value;
                  const optionProps = {
                    ref: (ref: HTMLDivElement | null) => {
                      if (ref) {
                        optionRefs.current[index] = ref;
                      }
                    },
                    onMouseMove: () => {
                      highlightItem(option);
                    },
                    onClick: () => {
                      selectOption(option);
                    },
                    highlighted,
                    discouraged: option.discouraged ?? false,
                  };
                  const optionKey = option.value === null ? 'null-option' : option.value ? String(option.value) : index;
                  if (option.render) {
                    return (
                      <OptionCustomRenderContainer
                        key={optionKey}
                        {...optionProps}
                        id={`${id}-option-${index}`}
                        data-testid="dropdown-option"
                      >
                        {option.render({ highlighted, option })}
                      </OptionCustomRenderContainer>
                    );
                  }
                  if (option.subOption) {
                    return (
                      <SubOption
                        key={optionKey}
                        {...optionProps}
                        id={`${id}-option-${index}`}
                        data-testid="dropdown-option"
                      >
                        <OptionText highlighted={highlighted} {...{ type, color }}>
                          {option.display}
                        </OptionText>
                        {option.subOption && (
                          <SubText highlighted={highlighted}>{newlinesToHtml(option.subOption)}</SubText>
                        )}
                      </SubOption>
                    );
                  }
                  return (
                    <Option
                      key={optionKey}
                      {...optionProps}
                      id={`${id}-option-${index}`}
                      data-testid="dropdown-option"
                    >
                      <OptionText highlighted={highlighted} {...{ type, color }}>
                        {option.display}
                      </OptionText>
                      {option.subText && (
                        <SubText highlighted={highlighted}>{newlinesToHtml(option.subText)}</SubText>
                      )}
                    </Option>
                  );
                })}
              </OptionList>
            </ExpandingContainer>
          </OverlayPortal>
        )}
      </Container>
    </ContainerContainer>
  );
};

export default Dropdown;

const styles = {
  [DROPDOWN_TYPE.STANDARD]: {
    height: '40px',
    fontSize: '15px',
    iconSize: '18px',
    border: true,
  },
  [DROPDOWN_TYPE.BORDERLESS]: {
    height: '40px',
    fontSize: '15px',
    iconSize: '18px',
    border: false,
  },
  [DROPDOWN_TYPE.COMPACT]: {
    height: '30px',
    fontSize: '13px',
    iconSize: '14px',
    border: false,
  },
  [DROPDOWN_TYPE.COMPACT_WITH_BORDER]: {
    height: '30px',
    fontSize: '13px',
    iconSize: '14px',
    border: true,
  },
};

const BORDER_RADIUS = '2px';
const MAX_OPTIONS_HEIGHT = '300px';
const TRANSITION_DURATION = '200ms';

const ContainerContainer = styled.div<{ width: string }>`
  position: relative;
  width: ${({ width }) => width};
`;

interface ContainerProps {
  type: DropdownType;
  disabled: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  overflow: visible;
  outline: none;
  ${({ type }) =>
    !styles[type].border
    && css`
      left: -3px;
    `}
    ${({ disabled }) =>
    disabled
    && css`
      pointer-events: none;
      opacity: 0.6;
    `}
`;

const Label = styled.div<{ type: DropdownType }>`
  color: ${({ color, theme }) => color ?? theme.colors.textDark};
  font-size: ${({ type }) => styles[type].fontSize};
  padding-bottom: 5px;
  padding-left: 3px;
`;

type DisplayContainerProps = {
  type: DropdownType,
  borderColor?: string,
  backgroundColor?: string,
  changeBg?: boolean,
  expanded: boolean,
  expandTop?: boolean,
  height?: string,
};

const DisplayContainer = styled.div<DisplayContainerProps>`
  position: relative;
  width: 100%;
  height: ${({ type, height }) => height ? height : styles[type].height};
  padding-left: 10px;
  border: 1px solid ${({ borderColor, theme }) => borderColor ?? theme.colors.grey70};
  border-radius: ${BORDER_RADIUS};
  background-color: ${({ backgroundColor, changeBg, theme }) =>
    (changeBg ? theme.colors.white : backgroundColor ?? 'white')};
  padding-right: 35px;
  transition: background-color 250ms linear;

  ${Container}:focus-within & {
    border-width: ${({ type }) => (styles[type].border ? '2px' : '1px')};
    padding-left: ${({ type }) => (styles[type].border ? '9px' : '10px')};
  }

  ${({ expanded }) => expanded && css<{ expandTop?: boolean }>`
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: ${({ expandTop }) => (expandTop ? 'auto' : 'none')};
    border-top: ${({ expandTop }) => (expandTop ? 'none' : 'auto')};
  `}

  ${({ type, expanded }) =>
    !styles[type].border
    && !expanded
    && css`
      border: none;
    `}
`;

type DisplayValueProps = {
  color?: string,
  type: DropdownType,
  font?: string,
  fontSize?: string,
  fontStyle?: string,
  textTransform?: string,
  height?: string,
};

const DisplayValue = styled.div<DisplayValueProps>`
  ${({ color }) => color && css`color: ${color}`};
  width: 100%;
  line-height: ${({ type, height }) => height ? height : styles[type].height};
  height: ${({ type, height }) => height ? height : styles[type].height};
  font-size: ${({ type }) => styles[type].fontSize};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ font }) => font && `font: ${font}`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
  ${({ fontStyle }) => fontStyle && `font-style: ${fontStyle}`};
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform}`};
`;

const PlaceholderValue = styled(DisplayValue)`
  font-style: ${({ fontStyle }) => fontStyle ?? 'italic'};
`;

const DropdownArrow = styled(ChevronDown)<{ type: DropdownType, open?: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  flex: 0 0 auto;
  min-width: ${({ type }) => styles[type].iconSize};
  max-width: ${({ type }) => styles[type].iconSize};
  height: 100%;
  margin-right: 10px;
  ${({ open }) =>
    open
    && css`
      transform: rotate(180deg);
    `}
`;

const TextFieldContainer = styled.div`
  position: sticky;
  top: 0px;
  z-index: 1;
  padding: 4px 3px;
  background-color: #eee;
`;

const TextField = styled.input<{ type: DropdownType }>`
  color: ${({ theme }) => theme.colors.textDark};
  font-size: ${({ type }) => styles[type].fontSize};
  font-weight: lighter;
  width: 100%;
  padding: 5px;
  border: none;
  outline: none;
  height: 30px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.textMed};
    font-style: italic;
  }
`;

type ExpandingContainerProps = {
  expandTop?: boolean,
  type: DropdownType,
  visible?: boolean,
  borderColor?: string,
  height?: string
};

const ExpandingContainer = styled.div<ExpandingContainerProps>`
  position: absolute;
  z-index: 99;
  top: ${({ expandTop }) => (expandTop ? 'auto' : '100%')};
  bottom: ${({ expandTop, type, height }) => (expandTop ? (height ? height : styles[type].height) : 'auto')};
  left: 0px;
  width: inherit;
  height: auto;
  max-height: ${MAX_OPTIONS_HEIGHT};
  border: ${({ visible, borderColor, theme }) =>
    (visible ? `1px solid ${borderColor ?? theme.colors.grey70}` : 'none')};
  border-bottom-left-radius: ${BORDER_RADIUS};
  border-bottom-right-radius: ${BORDER_RADIUS};
  border-top: ${({ expandTop }) => (expandTop ? 'auto' : 'none')};
  border-bottom: ${({ expandTop }) => (expandTop ? 'none' : 'auto')};
  background-color: ${({ theme }) => theme.colors.white};
  overflow: auto;
  cursor: pointer;
  /* transition: max-height ${TRANSITION_DURATION} ease-out; */

  ${Container}:focus-within & {
    border-width: ${({ type }) => (styles[type].border ? '2px' : '1px')};
  }
`;

const OptionList = styled.div``;

export const Option = styled.div<{ highlighted?: boolean, discouraged?: boolean }>`
  background-color: ${({ highlighted, discouraged, theme }) => (
    highlighted
      ? theme.colors.primary
      : discouraged
        ? theme.colors.grey93
        : 'none'
  )};
  padding: 10px;
`;

export const SubOption = styled(Option)`
  padding-left: 30px;`;

export const OptionText = styled.div<{ highlighted?: boolean, type: DropdownType }>`
  font-size: ${({ type }) => styles[type].fontSize};
  color: ${({ highlighted, color, theme }) =>
    (highlighted ? theme.colors.textLight : color)};
`;

const SubText = styled.div<{ highlighted?: boolean }>`
  font-size: 13px;
  color: ${({ highlighted, theme }) => (highlighted ? theme.colors.grey90 : theme.colors.textMed)};
  margin-top: 5px;
`;

const OptionCustomRenderContainer = styled(Option)`
  padding: 0px;
`;

const ClearButton = styled(({ show, ...props }) => <XIcon {...props} />)`
  box-sizing: content-box;
  position: absolute;
  right: 0px;
  top: -1px;
  flex: 0 0 auto;
  min-width: 10px;
  max-width: 10px;
  height: 100%;
  padding: 0px 10px;
  cursor: pointer;
  ${({ show }) => !show && css`
    display: none;
  `}
`;

/* interface ClearButtonProps extends React.SVGProps<SVGSVGElement> {
  show?: boolean;
}

const ClearButton = styled(XIcon)<ClearButtonProps>`
  box-sizing: content-box;
  position: absolute;
  right: 0px;
  top: -1px;
  flex: 0 0 auto;
  min-width: 10px;
  max-width: 10px;
  height: 100%;
  padding: 0px 10px;
  cursor: pointer;
  ${({ show }) => !show && css`
    display: none;
  `}
`; */
