import { useEffect } from "react";

import ScannerComponent from "./ScannerComponent";
import LoadingCircle from "components/Loading/LoadingCircle";
import { useScanditSDK } from "lib/scandit/scanditSDK";

type ScanditProps = {
  onScan: (code: string | null) => void
}

const Scandit = ({ onScan }: ScanditProps): JSX.Element => {
  const { loading, sdk } = useScanditSDK();

  useEffect(() => {
    if (loading) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    sdk.onScan(async (_, session) => {
      if (session.newlyRecognizedBarcodes.length > 0) {
        await sdk.enableScanning(false);
        const barcode = session.newlyRecognizedBarcodes[0].data;
        onScan(barcode);
      }
    });
  }, [loading, onScan, sdk]);

  // const symbologyDescription = new SymbologyDescription(barcode?.symbology);
  
  return (
    <>
      {loading && (
        <LoadingCircle />
      )}
      {!loading && (
        <ScannerComponent />
      )}
    </>
  );
}

export default Scandit;