import { select, put } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import Resource from 'lib/jsonApi/Resource';
import { getAuthToken, removeAuthToken } from 'lib/utils/authHelpers';
import { resetStore } from 'rdx/modules/app/slice';
import { setCurrentOrganization, getCurrentOrganization, setOrganizations } from 'rdx/modules/organization/slice';
import { setRedirectUrl, setSession } from 'rdx/modules/auth/slice';
import { setUser } from 'rdx/modules/users/slice';
import { requestChannels } from 'rdx/modules/chat/slice';

export default function* getSession() {
  const authToken = getAuthToken();

  if (authToken) {
    yield* getAuthSession();
    return;
  }
  yield* getAnonSession();
}

function* getAuthSession() {
  const { success, data, error } = yield* makeRequest.get('/session');
  if (success && data) {
    const session = data.unwrap();
    const signInRequirements = session?.attributes?.sign_in_requirements;
    const user = session.getRel('user');
    const organizations = session.getRel('organizations');
    yield put(setUser(user));
    yield put(setOrganizations(organizations));
    const currentOrg = yield select(getCurrentOrganization);
    const { location } = window;
    const locationOrg = organizations.find((org) => org.attributes.slug === location.pathname.split('/')[1]);
    const defaultOrgUUID = user.attributes.settings?.default_org_uuid;
    const defaultOrg = organizations.find((org) => org.attributes.uuid === defaultOrgUUID);

    if (!currentOrg?.id) {
      if (locationOrg) {
        yield put(setCurrentOrganization(new Resource(locationOrg)));
      } else if (defaultOrg) {
        yield put(setCurrentOrganization(new Resource(defaultOrg)));
      } else {
        yield put(setCurrentOrganization(new Resource(session.organizations[0])));
      }
    }
    yield put(setSession(session));
    yield put(setRedirectUrl(null));
    if (!signInRequirements?.length) {
      yield put(requestChannels());
    }
  } else if (error) {
    if (error.statusCode === 401) {
      removeAuthToken();
      yield put(resetStore());
    }
    yield putErrorActions({ error });
  }
  return null;
}

function* getAnonSession() {
  const { success, data, error } = yield* makeRequest.get('/');
  if (success && data) {
    yield put(setSession(data.unwrap()));
  } else {
    yield* putErrorActions({ error });
  }
  return null;
}
