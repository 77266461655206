import { select } from 'redux-saga/effects';

import makeRequest from 'rdx/utils/makeRequest';
import putErrorActions from 'rdx/utils/putErrorActions';
import { getSession } from 'rdx/modules/auth/slice';

function* confirmInviteExisting({ payload }) {
  const action = (yield select(getSession))?.getAction('invite_existing_confirm');
  const { params: { token: code, password, email }, onSuccess } = payload;
  const params = { code, password, email };
  const { success, error } = yield* makeRequest.action(
    action,
    params,
  );
  if (success) {
    if (onSuccess) {
      onSuccess();
    }
  } else if (error) {
    yield* putErrorActions({ error });
  }
  return null;
}

export default confirmInviteExisting;
