import { all } from 'redux-saga/effects';

import watchAuthSagas from 'rdx/modules/auth/sagas';
import watchBarcodeScansSagas from 'rdx/modules/barcodeScans/sagas';
import watchGroupsSagas from 'rdx/modules/groups/sagas';
import watchWorkflowSagas from 'rdx/modules/workflow/sagas';
import watchUsersSagas from 'rdx/modules/users/sagas';
import watchProceduresSagas from 'rdx/modules/procedures/sagas';
import watchStepsSagas from 'rdx/modules/steps/sagas';
import watchOrganizationSagas from 'rdx/modules/organization/sagas';
import watchTemplatesSagas from 'rdx/modules/templates/sagas';
import watchProjectsSagas from 'rdx/modules/projects/sagas';
import watchTrialsSagas from 'rdx/modules/trials/sagas';
import watchCytometersSagas from 'rdx/modules/cytometers/sagas';
import watchStudiesSagas from 'rdx/modules/studies/sagas';
import watchSystemSagas from 'rdx/modules/system/sagas';
import watchLabsSagas from 'rdx/modules/labs/sagas';
import watchSpectrumViewerSagas from 'rdx/modules/spectrumViewer/sagas';
import watchExperimentsSagas from 'rdx/modules/experiments/sagas/index';
import watchReagentsSagas from 'rdx/modules/reagents/sagas';
import watchFluorochromesSagas from 'rdx/modules/fluorochromes/sagas';
import watchGatedFeatureBlurbsSagas from 'rdx/modules/gatedFeatureBlurbs/sagas';
import watchChatSagas from 'rdx/modules/chat/sagas';
import watchfcsDataSagas from 'rdx/modules/fcsData/sagas';
import watchWspDataSagas from 'rdx/modules/wspData/sagas';
import watchTutorialsSagas from 'rdx/modules/tutorials/sagas';
import watchFileExplorerSagas from 'rdx/modules/fileExplorer/sagas';
import watchLicensesSagas from 'rdx/modules/licenses/sagas';
import watchPanelsSagas from 'rdx/modules/panels/sagas';
import watchPanelSharingsSagas from 'rdx/modules/panelSharings/sagas';
import watchPanelDetailsSagas from 'rdx/modules/panelDetails/sagas';
import watchLinkedResourcesSagas from 'rdx/modules/linkedResources/sagas';
import watchTechSupportSagas from 'rdx/modules/techSupport/sagas';
import watchSystemDocSagas from 'rdx/modules/systemDocs/sagas';
import watchFlowGptQueriesSagas from 'rdx/modules/flowGptQueries/sagas';
import watchWishlistsSagas from './modules/wishlists/sagas';
import watchChorusExperimentsSagas from './modules/chorusExperiments/sagas';

function* rootSaga() {
  yield all([
    watchAuthSagas(),
    watchBarcodeScansSagas(),
    watchGroupsSagas(),
    watchWorkflowSagas(),
    watchUsersSagas(),
    watchProceduresSagas(),
    watchStepsSagas(),
    watchOrganizationSagas(),
    watchTemplatesSagas(),
    watchProjectsSagas(),
    watchTrialsSagas(),
    watchCytometersSagas(),
    watchStudiesSagas(),
    watchSystemSagas(),
    watchLabsSagas(),
    watchSpectrumViewerSagas(),
    watchExperimentsSagas(),
    watchReagentsSagas(),
    watchFluorochromesSagas(),
    watchChatSagas(),
    watchfcsDataSagas(),
    watchWspDataSagas(),
    watchTutorialsSagas(),
    watchFileExplorerSagas(),
    watchFlowGptQueriesSagas(),
    watchLicensesSagas(),
    watchPanelsSagas(),
    watchPanelSharingsSagas(),
    watchLinkedResourcesSagas(),
    watchPanelDetailsSagas(),
    watchTechSupportSagas(),
    watchSystemDocSagas(),
    watchChorusExperimentsSagas(),
    watchWishlistsSagas(),
    watchGatedFeatureBlurbsSagas(),
  ]);
}

export default rootSaga;
