import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { divideStyleString, multiplyStyleString } from 'lib/utils/styles/doMathOnStyleString';
import Portal from 'components/Portal';
import globals from 'styles/globals';
import titleCase from 'lib/utils/string/titleCase';
import { ChevronRight } from 'components/Icons/index';

const { colors, animations, fonts } = globals;

export type ContextualDropdownItem<T> = {
  name: string,
  value?: T,
  description?: string,
  component?: () => JSX.Element,
  isHeader?: boolean,
  isDivider?: boolean,
  render?: () => JSX.Element,
  disabled?: boolean,
  subItems?: ContextualDropdownItem<T>[],
  action?: () => void,
  preventClose?: boolean,
};

export interface ContextualDropdownProps<T> extends React.ComponentPropsWithoutRef<'menu'> {
  items?: ContextualDropdownItem<T>[],
  itemHeight?: string,
  handleSelectItem?: (item: ContextualDropdownItem<T>) => void,
  isMainNav?: boolean,
  setDropdownStateIsOpen?: (isOpen: boolean) => void,
  clickException?: string,
  isOptionSelect?: boolean,
  hasHeaders?: boolean,
  selectedItem?: string | number,
  color?: string,
  display?: React.ReactNode,
  className?: string,
  masterWidth?: string,
  id?: string,
  component?: React.ReactNode,
  skipAnimation?: boolean,
  highZ?: boolean,
  isStepNav?: boolean,
  noCarrot?: boolean,
  downArrowClassName?: string,
  noDownArrow?: boolean,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
}

const ContextualDropdown = <T,>({
  items = [],
  itemHeight = '2.5rem',
  handleSelectItem,
  isMainNav,
  setDropdownStateIsOpen,
  clickException,
  isOptionSelect,
  selectedItem,
  color = globals.colors.primary,
  display,
  className,
  masterWidth,
  hasHeaders,
  id = 'contextual-dropdown',
  component,
  skipAnimation,
  highZ,
  isStepNav,
  noCarrot,
  downArrowClassName = 'contextual-dropdown-down-arrow',
  noDownArrow,
  onMouseEnter,
  onMouseLeave,
  children,
  ...otherProps
}: React.PropsWithChildren<ContextualDropdownProps<T>>) => {
  const [open, setOpen] = useState(false);
  const [carrotStyle, setCarrotStyle] = useState<React.CSSProperties>({});
  const [menuStyle, setMenuStyle] = useState<React.CSSProperties>({});
  const [menuHeight, setMenuHeight] = useState('0');
  const [stylesReady, setStylesReady] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [subItems, setSubItems] = useState<ContextualDropdownItem<T>[]>([]);
  const [submenuTop, setSubmenuTop] = useState(0);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const downArrowRef = useRef<HTMLElement | null>(null);
  const displayValueRef = useRef<HTMLDivElement | null>(null);
  const componentContainerRef = useRef<HTMLDivElement | null>(null);
  const firstItemRef = useRef<HTMLDivElement | null>(null);
  const itemsContainerRef = useRef<HTMLDivElement | null>(null);
  const carrotRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLElement | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const submenuTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const calcMenuHeight = useCallback(() => {
    const newMenuHeight = multiplyStyleString(itemHeight, items.length);
    setMenuHeight(newMenuHeight);
  }, [itemHeight, items.length]);

  const getOrigin = () => {
    let origin: DOMRect | undefined;
    if (downArrowRef.current !== null) {
      origin = downArrowRef.current.getBoundingClientRect();
    } else if (displayValueRef.current !== null) {
      origin = displayValueRef.current.getBoundingClientRect();
    }
    return origin;
  };

  const getMenuPortalStyle = useCallback(() => {
    const style: React.CSSProperties = {};
    const origin = getOrigin();
    const menu = menuRef?.current?.getBoundingClientRect?.();
    if (origin && menu && carrotRef.current) {
      const aboveTarget = window.innerHeight / 2 < origin.top;
      let menuPortalHeight = multiplyStyleString(itemHeight, items.length);
      if (menuPortalHeight.includes('rem')) {
        const num = parseFloat(itemHeight);
        menuPortalHeight = `${parseFloat(getComputedStyle(document.documentElement).fontSize) * num * items.length}px`;
      }
      const { width: menuWidth } = menu;
      const { width: originWidth, height: originHeight, left: originLeft, top: originTop } = origin;
      const top = aboveTarget
        ? originTop - parseFloat(menuPortalHeight)
        : originTop + originHeight + 10;
      const left = (originLeft + originWidth / 2) - (menuWidth / 2);
      const normalizedTipOffset = Math.max((left + menuWidth) - window.innerWidth, 0);
      const actualLeft = Math.max(left - normalizedTipOffset);

      style.top = `${top}px`;
      style.left = `${actualLeft}px`;
      if (isMainNav) {
        style.left = origin.left;
        style.top = origin.bottom;
      }
    }
    setMenuStyle(style);
    setStylesReady(true);
  }, [isMainNav, itemHeight, items.length]);

  const getCarrotPortalStyle = useCallback(() => {
    const origin = getOrigin();
    const carrot = carrotRef.current?.getBoundingClientRect?.();
    const style: React.CSSProperties = {
      position: 'fixed',
    };
    let top;
    if (origin && carrot) {
      const aboveTarget = window.innerHeight / 2 < origin.top;
      const { width: carrotWidth } = carrot;
      const { width: originWidth, height: originHeight, left: originLeft, top: originTop } = origin;
      top = aboveTarget
        ? originTop
        : originTop + originHeight;
      const left = (originLeft + originWidth / 2) - (carrotWidth / 2);
      const normalizedTipOffset = Math.max((left + carrotWidth) - window.innerWidth, 0);
      const actualLeft = Math.max(left - normalizedTipOffset);

      style.top = `${top}px`;
      style.left = `${actualLeft}px`;
      if (aboveTarget) {
        style.transform = 'rotate(180deg)';
      }
      setCarrotStyle(style);
    }
  }, []);

  const updateStyles = useCallback(() => {
    getMenuPortalStyle();
    getCarrotPortalStyle();
  }, [getCarrotPortalStyle, getMenuPortalStyle]);

  const openDropdown = () => {
    setDropdownStateIsOpen?.(true);
    setOpen(true);
    if (firstItemRef.current) {
      firstItemRef.current.focus();
    }
  };

  const resetDropdownState = () => {
    setCarrotStyle({});
    setStylesReady(false);
    setOpen(false);
    setShowSubmenu(false);
    setSubItems([]);
    setSubmenuTop(0);
  };

  const closeDropdown = useCallback(() => {
    setDropdownStateIsOpen?.(false);
    resetDropdownState();
  }, [setDropdownStateIsOpen]);

  const handleClick = useCallback((e: MouseEvent | KeyboardEvent) => {
    const target = e.target as HTMLElement | undefined;
    if ('key' in e) {
      if (e.key === 'Escape') {
        closeDropdown();
      }
    } else if (clickException === target?.id) {
      return null;
    } else if (
      target
      && !wrapperRef.current?.contains(target)
      && !componentContainerRef.current?.contains(target)
      && !itemsContainerRef.current?.contains(target)
    ) {
      closeDropdown();
    }
    return null;
  }, [clickException, closeDropdown]);

  const handleScroll = useCallback(() => {
    closeDropdown();
  }, [closeDropdown]);

  const toggleDropdown = () => {
    if (open) {
      closeDropdown();
    } else {
      openDropdown();
    }
  };

  const openContextualDropdown = () => {
    if (!open) {
      openDropdown();
    }
  };

  const handleLeave = () => {
    if (open) {
      timeoutRef.current = setTimeout(() => {
        closeDropdown();
      }, 200);
    }
  };

  const clearTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleClickItem = (item: ContextualDropdownItem<T>, event?: React.KeyboardEvent<HTMLDivElement>) => {
    if (event?.key) {
      if (event.key === 'Enter' || event.key === ' ') {
        item.action?.();
        handleSelectItem?.(item);
        updateStyles();
      }
    } else if (!event) {
      item.action?.();
      handleSelectItem?.(item);
      updateStyles();
    }
    if (!item.preventClose) {
      toggleDropdown();
    }
  };

  const getSelectedItem = () => {
    if (isOptionSelect && selectedItem != null) {
      return items.find((item) => item.value === selectedItem);
    }
    return undefined;
  };

  useEffect(() => {
    calcMenuHeight();
  }, [calcMenuHeight]);

  // Remove this in favor of calling updateStyles when setting open state.
  useEffect(() => {
    updateStyles();
  }, [updateStyles, open]);

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClick, false);
      document.addEventListener('keyup', handleClick, false);
      document.addEventListener('bdrc-drawer-scroll', handleScroll, false);
      window.addEventListener('resize', updateStyles);
    } else {
      document.removeEventListener('click', handleClick, false);
      document.removeEventListener('bdrc-drawer-scroll', handleScroll, false);
      window.removeEventListener('resize', updateStyles);
      document.removeEventListener('keyup', handleClick, false);
    }

    return () => {
      document.removeEventListener('click', handleClick, false);
      document.removeEventListener('bdrc-drawer-scroll', handleScroll, false);
      window.removeEventListener('resize', updateStyles);
      document.removeEventListener('keyup', handleClick, false);
    };
  }, [handleClick, handleScroll, open, updateStyles]);

  const width = masterWidth ?? '15rem';
  const halfWidth = divideStyleString(width, 2);

  const handleMouseEnter = () => {
    if (isMainNav) {
      openContextualDropdown();
      clearTimer();
      onMouseEnter?.();
    }
  };

  const handleMouseLeave = () => {
    if (isMainNav) {
      handleLeave();
      onMouseLeave?.();
    }
  };

  const wholeSelectedItem = getSelectedItem();
  const selectedItemName = isOptionSelect ? wholeSelectedItem?.name ?? '' : '';
  const displayValue = display ?? selectedItemName;

  return (
    <Wrapper
      color={color}
      className={className}
      id={id}
      onMouseEnter={children ? undefined : () => clearTimer()}
      onMouseLeave={children ? undefined : () => handleLeave()}
      ref={wrapperRef}
    >
      {children ? (
        <>
          {React.Children.map(children,
            (child) => {
              if (React.isValidElement(child)) {
                return React.cloneElement(
                  child as React.ReactElement<{
                    onClick: () => void,
                    onMouseEnter: () => void,
                    onMouseLeave: () => void,
                    ref: (el: HTMLElement | null) => void,
                  }>,
                  {
                    onClick: () => (isMainNav ? null : toggleDropdown()),
                    onMouseEnter: handleMouseEnter,
                    onMouseLeave: handleMouseLeave,
                    ref: (el: HTMLElement | null) => { downArrowRef.current = el; },
                  }
                );
              }
              return child;
            })}
        </>
      ) : (
        <>
          <DisplayValue
            ref={(el) => { displayValueRef.current = el; }}
            isStepNav={isStepNav}
            onClick={() => !isStepNav && toggleDropdown()}
            onMouseEnter={() => isStepNav && openContextualDropdown()}
          >
            {displayValue}
          </DisplayValue>
          {!isStepNav && !noDownArrow && (
            <DownArrow
              ref={(el) => { downArrowRef.current = el; }}
              onClick={() => toggleDropdown()}
              className={downArrowClassName}
            />
          )}
        </>
      )}
      {open && (
        <>
          <Portal>
            <Menu
              {...{ width, halfWidth, height: menuHeight, component, isMainNav, skipAnimation, highZ }}
              showMenu={open && stylesReady}
              style={menuStyle}
              ref={(el) => { menuRef.current = el; }}
              {...otherProps}
              id="find-me-baby"
            >
              {component
                ? (
                  <ComponentContainer ref={(el) => { componentContainerRef.current = el; }}>
                    {component}
                  </ComponentContainer>
                )
                : (
                  <ItemsContainer
                    onMouseEnter={children ? () => clearTimer() : undefined}
                    onMouseLeave={children ? () => handleLeave() : undefined}
                    ref={(el) => { itemsContainerRef.current = el; }}
                  >
                    {items.map((item, index) => {
                      const { value, name, description, component: itemComponent, isHeader, isDivider, render, disabled, subItems: itemSubItems } = item;
                      const selected = isOptionSelect && (selectedItem != null) && (selectedItem === value);
                      return (
                        <DDContainer
                          key={name || index}
                          itemHeight={itemHeight}
                          id={`${id}-option-${index}`}
                          data-testid={`${id}-option`}
                          isHeader={isHeader}
                          isDivider={isDivider}
                          disabled={disabled}
                          firstElement={index === 0}
                          tabIndex={isHeader ? -1 : 0}
                          onMouseEnter={() => {
                            if (submenuTimeoutRef.current) {
                              clearTimeout(submenuTimeoutRef.current);
                            }
                            if (itemSubItems) {
                              setSubItems(itemSubItems);
                              setShowSubmenu(true);
                              setSubmenuTop((index) * 2.5);
                            } else if (showSubmenu) {
                              setShowSubmenu(false);
                              setSubmenuTop(0);
                            }
                          }}
                          onClick={isHeader ?? disabled ?? itemSubItems ? undefined : () => handleClickItem(item)}
                          onKeyDown={isHeader ?? disabled ?? itemSubItems ? undefined : (e) => handleClickItem(item, e)}
                          ref={(el) => {
                            if (index === 0) {
                              firstItemRef.current = el;
                            }
                          }}
                        >
                          {render ? render() : (
                            <>
                              <Display title={name} {...{ selected, isHeader, hasHeaders }}>
                                {titleCase(name)}
                                {itemSubItems && (
                                  <ChevronRight size="xs" />
                                )}
                              </Display>
                              {itemComponent?.()}
                              {description && (
                                <Description>
                                  {description}
                                </Description>
                              )}
                            </>
                          )}
                        </DDContainer>
                      );
                    })}
                  </ItemsContainer>
                )}
            </Menu>
            {showSubmenu && (
              <Menu
                {...{ width: 'fit-content', halfWidth, height: 'fit-content', component, isMainNav, skipAnimation, highZ }}
                showMenu={open && stylesReady}
                style={{
                  ...menuStyle,
                  left: `${parseInt(menuStyle.left?.toString().split('px')[0] ? menuStyle.left?.toString().split('px')[0] : '0') + parseInt(width)}px`,
                  top: `${parseInt(menuStyle.top?.toString().split('px')[0] ? menuStyle.top?.toString().split('px')[0] : '0') + submenuTop * 16}px`,
                }}
                {...otherProps}
              >
                <ItemsContainer
                  onMouseEnter={() => {
                    if (submenuTimeoutRef.current) {
                      clearTimeout(submenuTimeoutRef.current);
                    }
                  }}
                  onMouseLeave={() => {
                    submenuTimeoutRef.current = setTimeout(() => {
                      setSubItems([]);
                      setShowSubmenu(false);
                      setSubmenuTop(0);
                    }, 200);
                  }}
                >
                  {subItems?.map((item, index) => {
                    const { name, description, isHeader, isDivider, disabled, render, value, component: subItemComponent } = item;
                    const selected = isOptionSelect && (selectedItem != null) && (selectedItem === value);
                    return (
                      <DDContainer
                        key={name}
                        itemHeight={itemHeight}
                        id={`${id}-option-${index}`}
                        data-testid={`${id}-option`}
                        isHeader={isHeader}
                        isDivider={isDivider}
                        disabled={disabled}
                        firstElement={index === 0}
                        tabIndex={isHeader ? -1 : 0}
                        onClick={isHeader ?? disabled ? undefined : () => handleClickItem(item)}
                        onKeyDown={isHeader ?? disabled ? undefined : (e) => handleClickItem(item, e)}
                        ref={(el) => { if (index === 0) { firstItemRef.current = el; } }}
                      >
                        {render ? render() : (
                          <>
                            <Display title={name} {...{ selected, isHeader, hasHeaders }}>
                              {titleCase(name)}
                            </Display>
                            {subItemComponent?.()}
                            {description && (
                              <Description>
                                {description}
                              </Description>
                            )}
                          </>
                        )}
                      </DDContainer>
                    );
                  })}
                </ItemsContainer>
              </Menu>
            )}
          </Portal>
          <Portal>
            <Carrot
              {...{ halfWidth, isMainNav, isStepNav, highZ }}
              showCarrot={open && stylesReady}
              ref={(el) => { carrotRef.current = el; }}
              style={carrotStyle}
              noCarrot={noCarrot}
            />
          </Portal>
        </>
      )}
    </Wrapper>
  );
};

export default ContextualDropdown;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  color: ${({ color }) => color};
`;

const DownArrow = styled.div`
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid ${({ color }) => color};
  position: relative;
  margin-left: 10px;
  top: 7px;
  cursor: pointer;
`;

const Menu = styled.menu<{
  showMenu?: boolean,
  height: string,
  width: string,
  isStepNav?: boolean,
  highZ?: boolean,
  skipAnimation?: boolean,
  isMainNav?: boolean,
}>`
  margin: 0;
  padding: 0;
  background-color: ${colors.white};
  position: fixed;
  height: ${({ showMenu, height }) => (showMenu ? `${height || 'auto'}` : '0')};
  max-height: 440px;
  z-index: ${({ theme, isStepNav, highZ }) => ((isStepNav ?? highZ) ? 1000 : theme.zIndices.portal)};
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  overflow: auto;

  ${({ skipAnimation }) => !skipAnimation && css`
    transition: all ${animations.transitionSlow} ${animations.bounceBezier};
  `}

  ${({ showMenu }) => showMenu && css<{ height: string }>`
    height: ${({ height }) => height};
    box-shadow: 3px 3px 20px 0px rgba(0,0,0,0.3);
  `}

  ${({ isMainNav, showMenu }) => isMainNav && showMenu && css`
    box-shadow: 0px 15px 30px -15px rgba(0,0,0,0.3);
  `}
`;

const ComponentContainer = styled.div`
  padding: 10px 20px;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const DisplayValue = styled.div<{ isStepNav?: boolean }>`
  cursor: pointer;

  ${({ isStepNav }) => isStepNav && css`
    color: black;
    font-size: 16px;
    margin: 0px 15px;
  `}
`;

const Display = styled.div<{ selected?: boolean, isHeader?: boolean, hasHeaders?: boolean }>`
  color: ${({ selected }) => (selected ? colors.primary : colors.fontDark)};
  position: relative;
  font: ${fonts.buttons};
  color: ${({ isHeader }) => (isHeader ? colors.primary : colors.fontDark)};
  letter-spacing: 0.4pt;
  z-index: 121;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;


  ${({ hasHeaders }) => hasHeaders && css<{ isHeader?: boolean }>`
    width: ${({ isHeader }) => (isHeader ? '100%' : '90%')};
  `}

  &:before {
    ${({ selected }) => selected && css`
      content: "✓";
      color: ${colors.primary};
      position: absolute;
      left: -20px;
      z-index: 121;
    `}
  }
`;

const Description = styled.div`
  flex: 1;
  color: ${colors.fontLight};
  padding-top: 0.25rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Carrot = styled.div<{
  isMainNav?: boolean,
  noCarrot?: boolean,
  isStepNav?: boolean,
  highZ?: boolean,
  showCarrot?: boolean,
}>`
  ${({ isMainNav, noCarrot }) => (isMainNav ?? noCarrot) && css`
    display: none;
  `};
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 0px solid transparent;
  position: fixed;
  z-index: ${({ theme, isStepNav, highZ }) => ((isStepNav ?? highZ) ? 1000 : theme.zIndices.portal)};

  ${({ showCarrot }) => showCarrot && css`
    border-bottom: 10px solid ${({ theme }) => theme.colors.white};
  `};
`;

const DDContainer = styled.div<{
  itemHeight?: string,
  isDivider?: boolean,
  isHeader?: boolean,
  disabled?: boolean,
  firstElement?: boolean,
}>`
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  height: ${({ itemHeight, isDivider }) => (isDivider ? '10px' : itemHeight ?? 'auto')};
  flex-direction: column;
  justify-content: center;
  align-items: ${({ isHeader }) => (isHeader ? 'flex-start' : 'center')};
  position: relative;
  z-index: 121;
  transition: all 0.2s ease-in-out;
  cursor: ${({ isHeader, isDivider, disabled }) => (isHeader ?? disabled ?? isDivider ? 'default' : 'pointer')};
  border-bottom: ${({ theme, isHeader }) => (isHeader ? `1px solid ${theme.colors.primary}` : 'none')};
  border-top: ${({ theme, isHeader }) => (isHeader ? `1px solid ${theme.colors.lightGrey}` : 'none')};
  ${({ disabled }) => disabled && `background-color: ${colors.darkGrey};`}

  ${({ isHeader, isDivider, disabled }) => !isHeader && !isDivider && !disabled && css`
    &:hover {
      background-color: ${colors.lightGrey};
    }
  `}
  ${({ isHeader, firstElement }) => ((isHeader && !firstElement) && css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background: rgb(200, 200, 200);
        background: linear-gradient(180deg, rgba(200,200,200,1) 3%, rgba(255,255,255,0) 100%);
      }
  `)}
  ${({ isDivider, firstElement }) => ((isDivider && !firstElement) && css`
      &::after {
        content: '';
        position: absolute;
        left: 20px;
        width: 80%;
        height: 1px;
        background: lightGrey;
      }
  `)}
`;
