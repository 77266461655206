/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { array, object, number, string, bool } from 'yup';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'hooks/reduxHooks';
import { useForm } from 'hooks/useForm/index';

import { ChevronDown, ChevronUp, DeleteIcon, Plus } from 'components/Icons/index';
import { Table, Cell } from 'components/GridTable';
import Link from 'components/Link/index';
import { useLoading } from 'hooks/useLoading/index';
import PrimaryButton from 'components/Button/PrimaryButton/index';
import Input from 'components/Form/Input/index';
import TextArea from 'components/Form/TextArea/index';
import Checkbox from 'components/Form/Checkbox/index';
import { patchInventoryReagent } from 'rdx/modules/reagents/slice';
import { getOrganizationScopes, getIsOrgOwner } from 'rdx/modules/organization/slice';

const NoTransform = styled.span`
  text-transform: none;
`;

const TITER_LABELS = {
  ab_vol: (
    <>
      <NoTransform>Ab</NoTransform> Volume Per Sample <NoTransform>(μL)</NoTransform>
    </>
  ),
  total_vol: (
    <>
      Total Staining Volume <NoTransform>(μL)</NoTransform>
    </>
  ),
  time: (
    <>
      Time <NoTransform>(min)</NoTransform>
    </>
  ),
  dilution: 'Dilution',
  temp: 'Temperature (C)',
  cell_loc: 'Cellular Location',
  notes: 'Notes',
  display: 'Display on Card',
  autofill: 'Autofill for Staining Sheet',
};

const EMPTY_TITER_FIELD = {
  ab_vol: '',
  total_vol: '',
  time: '',
  temp: '',
  cell_loc: '',
  notes: '',
  display: false,
  autofill: false,
};

const TITERS_SCHEMA = array(object({
  ab_vol: number().required().transform((curr, orig) => (orig === '' ? null : curr)),
  total_vol: number().required().transform((curr, orig) => (orig === '' ? null : curr)),
  time: number().nullable().transform((curr, orig) => (orig === '' ? null : curr)),
  temp: number().nullable().transform((curr, orig) => (orig === '' ? null : curr)),
  cell_loc: string().nullable(),
  notes: string().nullable(),
  display: bool(),
  autofill: bool(),
}));

const Titers = ({ inventoryReagent, queryParams }) => {
  const dispatch = useAppDispatch();
  const [expandedTiterIndexes, setExpandedTiterIndexes] = useState([]);
  const updateInventoryReagentAction = inventoryReagent?.getAction?.('update');
  const titersField = updateInventoryReagentAction?.field('titers') || inventoryReagent?.attributes?.titers;
  const initialTiters = titersField?.value || titersField;
  const orgScopes = useSelector(getOrganizationScopes);
  const isOrgOwner = useSelector(getIsOrgOwner);
  const hasReagentsPermission = orgScopes.includes('reagents') || isOrgOwner;

  const loading = useLoading({ watchRequests: [patchInventoryReagent.type] });

  const { form, fields } = useForm({
    titers: {
      schema: TITERS_SCHEMA,
      initialValue: structuredClone(initialTiters),
    },
  }, {
    onSubmit: (values) => {
      dispatch(patchInventoryReagent({
        action: updateInventoryReagentAction,
        query: queryParams,
        values,
      }));
    },
  });

  const currentTiters = form.values.titers;

  return (
    <form {...form.props}>
      {!currentTiters?.length && hasReagentsPermission && (
        <p>No titers exist for this reagent.{' '}
          <Link onClick={() => fields.titers.setValue([{ ...EMPTY_TITER_FIELD, display: true }])}>
            Click here to add a titer.
          </Link>
        </p>
      )}
      {currentTiters?.map((titer, i) => {
        const { ab_vol, total_vol } = titer;
        const abPerTotal = Number(Number((total_vol / ab_vol)).toFixed(2));
        let dilutionRatio = `1:${abPerTotal}`;
        if (Number.isNaN(abPerTotal) || !abPerTotal) {
          dilutionRatio = '---';
        }
        const lastTiter = i === currentTiters?.length - 1;
        const isExpanded = expandedTiterIndexes.includes(i);
        const Chevron = isExpanded ? ChevronUp : ChevronDown;

        return (
          <Card
            key={i}
            isExpanded={isExpanded}
          >
            <Cell label={TITER_LABELS.ab_vol} cellId="abvol">
              <Input
                type="number"
                min={0}
                className="titer-input"
                value={titer.ab_vol}
                onChange={
                  (e) => {
                    const newValue = structuredClone(fields.titers.value);
                    newValue[i].ab_vol = e.target.value;
                    fields.titers.setValue(newValue);
                  }
                }
                disabled={!hasReagentsPermission}
              />
            </Cell>
            <Cell label={TITER_LABELS.total_vol} cellId="totalvol">
              <Input
                type="number"
                min={0}
                className="titer-input"
                value={titer.total_vol}
                onChange={
                  (e) => {
                    const newValue = structuredClone(fields.titers.value);
                    newValue[i].total_vol = e.target.value;
                    fields.titers.setValue(newValue);
                  }
                }
                disabled={!hasReagentsPermission}
              />
            </Cell>
            <Cell label={TITER_LABELS.dilution} cellId="dilution">
              <p>{dilutionRatio}</p>
            </Cell>
            <Cell label={TITER_LABELS.display} cellId="display">
              <Checkbox
                value={titer.display}
                onChange={
                  (newVal) => {
                    const newValue = [...currentTiters]?.map((t) => ({ ...t, display: false }));
                    newValue[i].display = (newValue?.length === 1) ? true : newVal;
                    if (!newValue.some((t) => !!t.display)) {
                      newValue[i].display = true;
                    }
                    fields.titers.setValue(newValue);
                  }
                }
                disabled={!hasReagentsPermission}
              />
            </Cell>
            {/* <Cell label={TITER_LABELS.autofill} cellId="autofill">
              <Checkbox
                value={titer.autofill}
                onChange={
                  (newVal) => {
                    const newValue = [...currentTiters];
                    newValue[i].autofill = newVal;
                    fields.titers.setValue(newValue);
                  }
                }
              />
            </Cell> */}
            <Cell cellId="actions" className="icons">
              <DeleteIcon
                onClick={() => {
                  const newValue = fields.titers.value.filter((_, index) => index !== i);
                  if (newValue?.length && !newValue.some((t) => !!t.display)) {
                    newValue[0].display = true;
                  }
                  fields.titers.setValue(newValue);
                }}
                disabled={!hasReagentsPermission}
              />
              {lastTiter && <Plus size="15px" onClick={() => fields.titers.setValue([...fields.titers.value, EMPTY_TITER_FIELD])} disabled={!hasReagentsPermission} />}
            </Cell>
            <Cell cellId="chevron">
              <Chevron
                onClick={() => {
                  setExpandedTiterIndexes((prev) => {
                    if (prev.includes(i)) {
                      return prev.filter((index) => index !== i);
                    }
                    return [...prev, i];
                  });
                }}
              />
            </Cell>
            {isExpanded && (
              <>
                <Cell label={TITER_LABELS.time} cellId="time">
                  <Input
                    type="number"
                    className="titer-input"
                    value={titer.time}
                    onChange={
                      (e) => {
                        const newValue = structuredClone(fields.titers.value);
                        newValue[i].time = e.target.value;
                        fields.titers.setValue(newValue);
                      }
                    }
                    disabled={!hasReagentsPermission}
                  />
                </Cell>
                <Cell label={TITER_LABELS.temp} cellId="temp">
                  <Input
                    type="number"
                    className="titer-input"
                    value={titer.temp}
                    onChange={
                      (e) => {
                        const newValue = structuredClone(fields.titers.value);
                        newValue[i].temp = e.target.value;
                        fields.titers.setValue(newValue);
                      }
                    }
                    disabled={!hasReagentsPermission}
                  />
                </Cell>
                <Cell label={TITER_LABELS.cell_loc} cellId="cellloc">
                  <Input
                    className="titer-input"
                    value={titer.cell_loc}
                    onChange={
                      (e) => {
                        const newValue = structuredClone(fields.titers.value);
                        newValue[i].cell_loc = e.target.value;
                        fields.titers.setValue(newValue);
                      }
                    }
                    disabled={!hasReagentsPermission}
                  />
                </Cell>
                <Cell label={TITER_LABELS.notes} cellId="notes">
                  <TextArea
                    placeholder="Need to stain without protein, e.g."
                    value={titer.notes}
                    heightRange={{ min: '40px', max: '200px' }}
                    height="85px"
                    onChange={
                      (e) => {
                        const newValue = structuredClone(fields.titers.value);
                        newValue[i].notes = e.target.value;
                        fields.titers.setValue(newValue);
                      }
                    }
                    disabled={!hasReagentsPermission}
                  />
                </Cell>
              </>
            )}
          </Card>
        );
      })}
      {hasReagentsPermission && (
        <PrimaryButton
          text="save"
          type="submit"
          disabled={!form.isValid || form.isSubmitting}
          loading={loading}
          style={{ margin: '15px 0 25px auto' }}
        />
      )}
    </form>
  );
};

export default Titers;

const Card = styled(Table)`
  grid-template-areas: "abvol  totalvol  dilution  display  autofill  actions  chevron";

  ${({ isExpanded }) => isExpanded && css`
    grid-template-areas:
      "abvol  totalvol  dilution  display  autofill  actions  chevron"
      "time   temp      cellloc   notes    notes     actions  chevron";
  `}

  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr 0.25fr;

  min-height: ${({ isExpanded }) => (isExpanded ? '200px' : '120px')};
  background-color: ${({ theme, selected }) => (selected ? theme.colors.darkGrey : theme.colors.white)};

  .titer-input {
    height: 40px;
    width: 100px;
  }

  .cell-label {
    margin-bottom: 10px;
  }

  .icons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  .cell-input {
    div {
      height: fit-content;
    }
  }
`;
