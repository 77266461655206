import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useUnwrappedSelector } from 'hooks/useUnwrappedSelector';
import customPropTypes from 'lib/customPropTypes';

import Tabs from 'components/Tabs/index';
import BaseDrawer from 'containers/Drawers/BaseDrawer';
import LoadingCircle from 'components/Loading/LoadingCircle';

import Titers from 'containers/Reagents/ReagentInventoryDrawer/Titers';

import { requestOrganizationSettings, getCurrentOrganization } from 'rdx/modules/organization/slice';
import { requestSmartGroups } from 'rdx/modules/groups/slice';
import { getInventoryReagent, getReagent, requestInventoryReagent, requestReagent, setInventoryReagent } from 'rdx/modules/reagents/slice';
import { getSessionAction, getSessionLink } from 'rdx/modules/auth/slice';
import ReagentInventoryForm from 'containers/Reagents/ReagentInventoryDrawer/ReagentInventoryForm';
import ReagentTable from 'containers/Reagents/ReagentInventoryDrawer/ReagentTable';
import ActivityLog from './ActivityLog';

const ReagentInventoryDrawer = ({
  variant,
  lot,
  expiresAt,
  createInventoryReagentAction,
  queryParams,
  onSuccess,
  deletable,
  secondary,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const reagentVariantLink = useSelector(getSessionLink('reagent_variant'));
  const fetchedReagent = useUnwrappedSelector(getReagent, true);
  const groupsAction = useSelector(getSessionAction('groups'));
  const inventoryReagent = useUnwrappedSelector(getInventoryReagent, true);
  const currentOrg = useSelector(getCurrentOrganization);

  const reagent = inventoryReagent?.getRel?.('reagent') || fetchedReagent;

  const getInventoryReagentAction = currentOrg?.getAction('inventory_reagent');

  useEffect(() => {
    dispatch(requestOrganizationSettings());
  }, [dispatch]);

  useEffect(() => {
    if (currentOrg.id && groupsAction) {
      const groupParams = {
        organization_id: currentOrg.id,
        page: 1,
        confirmed: 1,
      };
      dispatch(requestSmartGroups({
        action: groupsAction,
        params: groupParams,
      }));
    }
  }, [dispatch, groupsAction, currentOrg.id]);

  useEffect(() => {
    if (reagentVariantLink && currentOrg && variant) {
      dispatch(requestReagent({
        link: reagentVariantLink,
        params: {
          organizationID: currentOrg.id,
          variantID: variant,
        },
      }));
    }
  }, [currentOrg, dispatch, reagentVariantLink, variant]);

  useEffect(() => () => {
    dispatch(setInventoryReagent(null));
  }, [dispatch]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const inventoryReagentId = params.get('inventoryReagentId');
    if (params.has('inventoryReagentId') && currentOrg) {
      dispatch(requestInventoryReagent({
        action: getInventoryReagentAction,
        params: {
          inventoryReagentID: inventoryReagentId,
        },
      }));
    }
  }, [currentOrg, dispatch, location.search, getInventoryReagentAction]);

  const [activeTab, setActiveTab] = useState('reagent');

  const drawerTitle = createInventoryReagentAction ? `Add ${reagent?.title}` : `Edit ${reagent?.variant ?? inventoryReagent?.variant ?? variant ?? ''}`;

  return (
    <BaseDrawer title={drawerTitle} secondary={secondary}>
      {!createInventoryReagentAction && (
        <Tabs
          tabs={[
            { text: 'Reagent', id: 'reagent' },
            { text: 'Titers', id: 'titers' },
            { text: 'Activity Log', id: 'activity-log' },
          ]}
          active={activeTab}
          handleTabChange={({ tabId }) => setActiveTab(tabId)}
          noUppercase
        />
      )}
      {reagent
        ? (
          <>
            {activeTab === 'reagent' && (
              <>
                {!!reagent && (
                  <ReagentInventoryForm
                    lot={lot}
                    expiresAt={expiresAt}
                    variant={variant}
                    inventoryReagent={inventoryReagent}
                    createInventoryReagentAction={createInventoryReagentAction}
                    queryParams={queryParams}
                    onSuccess={onSuccess}
                    deletable={deletable}
                    secondary={secondary}
                  />
                )}
                <Section>
                  <Title>Reagent Details</Title>
                  <p>{reagent?.description || ''}</p>
                </Section>
                {!!reagent && (
                  <ReagentTable
                    variant={variant}
                    reagent={reagent}
                    inventoryReagent={inventoryReagent}
                  />
                )}
              </>
            )}
            {activeTab === 'titers' && (
              <Titers inventoryReagent={inventoryReagent} queryParams={queryParams} />
            )}
            {activeTab === 'activity-log' && (
              <ActivityLog eventsLink={inventoryReagent?.links?.events || reagent?.links?.events} />
            )}
          </>
        )
        : (
          <LoadingCircle />
        )}
    </BaseDrawer>
  );
};

ReagentInventoryDrawer.propTypes = {
  variant: PropTypes.string.isRequired,
  lot: PropTypes.string,
  expiresAt: PropTypes.instanceOf(Date),
  // eslint-disable-next-line react/forbid-prop-types
  queryParams: PropTypes.object,
  createInventoryReagentAction: customPropTypes.action,
  deletable: PropTypes.bool,
  onSuccess: PropTypes.func,
  secondary: PropTypes.bool,
};

ReagentInventoryDrawer.defaultProps = {
  queryParams: {},
  lot: null,
  expiresAt: null,
  createInventoryReagentAction: null,
  onSuccess: null,
  deletable: false,
  secondary: false,
};

export default ReagentInventoryDrawer;

const Section = styled.div`
  margin-bottom: 20px;
  padding-top: 30px;
`;

const Title = styled.h2`
  margin-bottom: 5px;
`;

export const CompactLabel = styled.div`
  font-size: 18px;
`;
