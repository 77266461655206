import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ResourceList from 'lib/jsonApi/ResourceList';
import Resource from 'lib/jsonApi/Resource';
import type { RootState } from 'index';
import { resetStore } from 'rdx/modules/app/slice';
import { UnwrappedResourceList } from 'types/json-api-types';
import { CytometerAttributes } from 'types/cytometers';
import { GenericActionPayload } from 'types/redux-types';

export const LASER_VALUE_CATEGORIES = {
  rCV: 'rCV',
  delta: 'delta',
} as const;

type Setting = {
  parameter: string,
  voltage: number,
  log: boolean,
  a: boolean,
  h: boolean,
  w: boolean,
};

interface CytometerSliceState {
  orgCytometers: ResourceList<CytometerAttributes>;
  searchCytometers: ResourceList;
  currentCytometer: Resource;
  cytometerVendors: ResourceList;
  cytometerRcvValues: UnwrappedResourceList | null;
  cytometerDeltaValues: UnwrappedResourceList | null;
  cytometerSettings: Setting[];
  publicCytometers: ResourceList;
  dashboardCytometers: ResourceList<CytometerAttributes>
  currentCytometerRssReports: ResourceList;
}

const initialState: CytometerSliceState = {
  orgCytometers: new ResourceList(),
  searchCytometers: new ResourceList(),
  currentCytometer: new Resource(),
  cytometerVendors: new ResourceList(),
  cytometerRcvValues: null,
  cytometerDeltaValues: null,
  cytometerSettings: [],
  publicCytometers: new ResourceList(),
  dashboardCytometers: new ResourceList(),
  currentCytometerRssReports: new ResourceList(),
};

// API Request Actions
const requestOrgCytometers = createAction<GenericActionPayload>('requestOrgCytometers');
const requestSearchCytometers = createAction('requestSearchCytometers');
const requestCurrentCytometer = createAction<GenericActionPayload>('requestCurrentCytometer');
const requestCytometerVendors = createAction('requestCytometerVendors');
const patchCytometer = createAction('patchCytometer');
const deleteCytometer = createAction('deleteCytometer');
const deleteCytometerProfile = createAction('deleteCytometerProfile');
const setDefaultCytometerProfile = createAction('setDefaultCytometerProfile');
const publishCytometer = createAction('publishCytometer');
const unpubilshCytometer = createAction('unpubilshCytometer');
const cloneCytometer = createAction('cloneCytometer');
const linkCytometer = createAction('linkCytometer');
const unlinkCytometer = createAction('unlinkCytometer');
const patchCytometerOnlineStatus = createAction('patchCytometerOnlineStatus');
const downloadCytometerProfilePdf = createAction('downloadCytometerProfilePdf');
const requestCytometerRcvValues = createAction('requestCytometerRcvValues');
const requestCytometerDeltaValues = createAction('requestCytometerDeltaValues');
const requestCytometerSettings = createAction('requestCytometerSettings');
const patchCytometerSpectralOn = createAction('patchCytometerSpectralOn');
const patchCytometerSpectralOff = createAction('patchCytometerSpectralOff');
const updateCytometerSettings = createAction('updateCytometerSettings');
const requestPublicCytometers = createAction('requestPublicCytometers');
const requestDashboardCytometers = createAction<GenericActionPayload<Record<string, any>, { dashboard: boolean }>>('requestDashboardCytometers');
const requestCytometerRssReports = createAction<GenericActionPayload>('requestCytometerRssReports');

const cytometersSlice = createSlice({
  name: 'cytometers',
  initialState,
  reducers: {
    setCytometers: (state, action: PayloadAction<ResourceList<CytometerAttributes>>) => {
      state.orgCytometers = action.payload;
    },
    setMoreCytometers: (state, action: PayloadAction<ResourceList<CytometerAttributes>>) => {
      state.orgCytometers = state.orgCytometers.mergeWith(action.payload);
    },
    updateCytometersList: (state, action: PayloadAction<Resource<CytometerAttributes> | UnwrappedResourceList<CytometerAttributes>>) => {
      state.orgCytometers = state.orgCytometers.withResource(action.payload);
    },
    setSearchCytometers: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.searchCytometers = state.searchCytometers.mergeWith(action.payload);
      } else {
        state.searchCytometers = action.payload;
      }
    },
    setCurrentCytometer: (state, action: PayloadAction<Resource>) => {
      state.currentCytometer = action.payload;
    },
    setCytometerVendors: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.cytometerVendors = state.cytometerVendors.mergeWith(action.payload);
      } else {
        state.cytometerVendors = action.payload;
      }
    },
    setCytometerRcvValues: (state, action: PayloadAction<UnwrappedResourceList>) => {
      state.cytometerRcvValues = action.payload;
    },
    setCytometerDeltaValues: (state, action: PayloadAction<UnwrappedResourceList>) => {
      state.cytometerDeltaValues = action.payload;
    },
    setCytometerSettings: (state, action: PayloadAction<Setting[]>) => {
      state.cytometerSettings = action.payload;
    },
    setPublicCytometers: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.publicCytometers = state.publicCytometers.mergeWith(action.payload);
      } else {
        state.publicCytometers = action.payload;
      }
    },
    setDashboardCytometers: (state, action: PayloadAction<ResourceList<CytometerAttributes>>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.dashboardCytometers = state.dashboardCytometers.mergeWith(action.payload);
      } else {
        state.dashboardCytometers = action.payload;
      }
    },
    setCurrentCytometerRssReports: (state, action: PayloadAction<ResourceList>) => {
      if (action.payload.meta.pagination && action.payload.meta.pagination.page > 1) {
        state.currentCytometerRssReports = state.currentCytometerRssReports.mergeWith(action.payload);
      } else {
        state.currentCytometerRssReports = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore.type, () => initialState);
  },
});

const {
  setCytometers,
  setMoreCytometers,
  updateCytometersList,
  setSearchCytometers,
  setCurrentCytometer,
  setCytometerVendors,
  setCytometerRcvValues,
  setCytometerDeltaValues,
  setCytometerSettings,
  setPublicCytometers,
  setDashboardCytometers,
  setCurrentCytometerRssReports,
} = cytometersSlice.actions;

export {
  // Reducer Actions
  setCytometers,
  setMoreCytometers,
  updateCytometersList,
  setSearchCytometers,
  setCurrentCytometer,
  setCytometerVendors,
  setCytometerRcvValues,
  setCytometerDeltaValues,
  setCytometerSettings,
  setPublicCytometers,
  setDashboardCytometers,
  setCurrentCytometerRssReports,

  // API Requests
  requestOrgCytometers,
  requestSearchCytometers,
  requestCurrentCytometer,
  patchCytometer,
  deleteCytometer,
  deleteCytometerProfile,
  setDefaultCytometerProfile,
  publishCytometer,
  unpubilshCytometer,
  cloneCytometer,
  linkCytometer,
  unlinkCytometer,
  patchCytometerOnlineStatus,
  requestCytometerVendors,
  downloadCytometerProfilePdf,
  requestCytometerRcvValues,
  requestCytometerDeltaValues,
  requestCytometerSettings,
  patchCytometerSpectralOn,
  patchCytometerSpectralOff,
  updateCytometerSettings,
  requestPublicCytometers,
  requestDashboardCytometers,
  requestCytometerRssReports,
};

// Selectors
export const getOrgCytometers = (state: RootState) => state.cytometers.orgCytometers;
export const getSearchCytometers = (state: RootState) => state.cytometers.searchCytometers;
export const getCurrentCytometer = (state: RootState) => state.cytometers.currentCytometer;
export const getCytometerVendors = (state: RootState) => state.cytometers.cytometerVendors;
export const getCytometerRcvValues = (state: RootState) => state.cytometers.cytometerRcvValues;
export const getCytometerDeltaValues = (state: RootState) => state.cytometers.cytometerDeltaValues;
export const getCytometerSettings = (state: RootState) => state.cytometers.cytometerSettings;
export const getPublicCytometers = (state: RootState) => state.cytometers.publicCytometers;
export const getDashboardCytometers = (state: RootState) => state.cytometers.dashboardCytometers;
export const getCurrentCytometerRssReports = (state: RootState) => state.cytometers.currentCytometerRssReports;

export default cytometersSlice.reducer;
