import { ReactNode, useRef } from 'react';
import FocusLock from 'react-focus-lock';
import styled, { css } from 'styled-components';
import globals from 'styles/globals';
import { useDrawer } from 'hooks/useDrawer';
import { useSecondaryDrawer } from 'hooks/useSecondaryDrawer';
import { useForceRender } from 'hooks/useForceRender/index';

import NavHeader, { NavHeaderProps } from 'components/NavHeader';
import { useHistory, useLocation } from 'react-router-dom';
import useUnpublishedCytoDrawerConfirm from 'hooks/useUnpublishedCytoDrawerConfirm/index';
import { getIsMobile } from 'rdx/modules/app/slice';
import { useAppSelector } from 'hooks/reduxHooks';

const { layout, colors } = globals;

type BaseDrawerProps = {
  secondary?: boolean;
  children: ReactNode;
} & NavHeaderProps<any>;

const BaseDrawer = ({
  secondary = false,
  children,
  ...navHeaderProps
}: BaseDrawerProps) => {
  const isMobile = useAppSelector(getIsMobile);

  const history = useHistory();
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>();
  const forceRender = useForceRender();
  const { closeAndUnsetDrawer, urlInfo } = useDrawer();
  const { closeSecondaryDrawer, setSecondaryDrawer } = useSecondaryDrawer();

  const closeDrawerAndCleanPath = () => {
    if (secondary) {
      closeSecondaryDrawer();
      setTimeout(() => setSecondaryDrawer(null), 300);
    } else {
      closeAndUnsetDrawer();
      const params = new URLSearchParams(location.search);
      params.delete('drawer');
      params.delete('drawer-tab');
      Object.keys((urlInfo?.data ?? {})).forEach((key) => {
        params.delete(key);
      });
      history.push(`${location.pathname}?${params.toString()}`);
    }
  };

  const handleClose = useUnpublishedCytoDrawerConfirm({ onConfirm: closeDrawerAndCleanPath });

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const eventOptions = { bubbles: true };
    if (secondary) {
      const event = new Event('bdrc-secondary-drawer-scroll', eventOptions);
      e.target.dispatchEvent(event);
    } else {
      const event = new Event('bdrc-drawer-scroll', eventOptions);
      e.target.dispatchEvent(event);
    }
  };

  const attachRef = (ref: HTMLDivElement | null) => {
    if (ref && !containerRef.current) {
      containerRef.current = ref;
      forceRender();
    }
  };

  const showHeaderBottomBar = navHeaderProps.tabs?.length ?? navHeaderProps.buttons?.length;
  const headerHeight = showHeaderBottomBar ? '108px' : '55px';

  return (
    <FocusLockStyleContainer>
      <FocusLock className='focus-lock'>
        <DrawerContainer ref={attachRef}>
          <NavHeader
            {...navHeaderProps}
            onClose={() => handleClose()}
          />
          {isMobile ? (
            <DrawerBody {...{ secondary, isMobile }}>
              {children}
            </DrawerBody>
          ) : (
            <DrawerOuterBody headerHeight={headerHeight} onScroll={handleScroll} id={secondary ? 'secondary-drawer-scroll-container' : 'drawer-scroll-container'}>
              <DrawerBody {...{ secondary }}>
                {children}
              </DrawerBody>
            </DrawerOuterBody>
          )}
        </DrawerContainer>
      </FocusLock>
    </FocusLockStyleContainer>
  );
};

export default BaseDrawer;

const DrawerContainer = styled.div`
  background-color: ${colors.lightGrey};
  width: 100%;
  height: 100%;
`;

const DrawerOuterBody = styled.div<{ headerHeight: string }>`
  width: 100%;
  height: calc(100% - ${({ headerHeight }) => headerHeight});
  padding: 45px ${layout.drawerPadding} 0 ${layout.drawerPadding};
  overflow-y: auto;
  overflow-x: hidden;
`;

const DrawerBody = styled.div<{ secondary?: boolean, isMobile?: boolean }>`
  height: 100%;
  width: 100%;
  max-width: ${({ theme, secondary }) => (secondary ? theme.layout.maxSecondaryDrawerInnerWidth : theme.layout.maxDrawerInnerWidth)};
  display: flex;
  flex-direction: column;
  ${({ isMobile }) => isMobile && css`
    overflow: auto;
    padding: 20px;
  `}
`;

const FocusLockStyleContainer = styled.div`
  height: 100%;
  .focus-lock {
    height: 100%;
  }
`;
