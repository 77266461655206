import { useState } from 'react';
import { useDispatch } from 'react-redux';
import BaseModal from 'containers/Modals/BaseModal';
import { CompactLabel } from 'containers/Reagents/ReagentInventoryDrawer';
import Dropdown from 'components/Dropdown/index';
import { patchInventoryReagent } from 'rdx/modules/reagents/slice';

const ChangeGroupModal = ({ inventoryReagent, queryParams, groupOptions }) => {
  const dispatch = useDispatch();

  const [selectedGroup, setSelectedGroup] = useState('');

  const handleSubmit = () => {
    const changeGroupAction = inventoryReagent.getAction('change_group');

    dispatch(patchInventoryReagent({
      action: changeGroupAction,
      query: queryParams,
      values: { group_id: parseInt(selectedGroup) },
    }));
  };

  return (
    <BaseModal
      title="Change Group"
      dimensions={{ width: '400px', height: '250px' }}
      confirm={{
        text: 'submit',
        handler: handleSubmit,
        disabled: !selectedGroup,
      }}
    >
      <CompactLabel>Group</CompactLabel>
      <Dropdown
        options={groupOptions}
        onChange={(val) => setSelectedGroup(val)}
        value={selectedGroup}
        placeholder="Select a new group"
      />
    </BaseModal>
  );
};

export default ChangeGroupModal;
