import { useMemo, useEffect } from 'react';
import { v4 } from 'uuid';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import * as Yup from 'yup';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getSmartGroups } from 'rdx/modules/groups/slice';
import { getCurrentOrganization, getIsOrgOwner, getOrganizationScopes, getOrganizationSettingValueByName } from 'rdx/modules/organization/slice';
import { deleteInventoryReagent, patchInventoryReagent, postInventoryReagent } from 'rdx/modules/reagents/slice';
import { getUserDefaultGroupForCurrentOrg } from 'rdx/modules/users/slice';

import { useUnwrappedSelector } from 'hooks/useUnwrappedSelector';
import { useModal } from 'hooks/useModal';
import { useDrawer } from 'hooks/useDrawer';
import { useSecondaryDrawer } from 'hooks/useSecondaryDrawer';
import { useForm } from 'hooks/useForm';

import { EditIcon } from 'components/Icons';
import DangerButton from 'components/Button/DangerButton/index';
import ChangeGroupModal from 'containers/Reagents/ReagentInventoryDrawer/ChangeGroupModal';
import ConfirmModal from 'containers/Modals/ConfirmModal';
import Dropdown from 'components/Dropdown/index';
import PrimaryButton from 'components/Button/PrimaryButton/index';
import Input from 'components/Form/Input/index';
import TextArea from 'components/Form/TextArea/index';
import FormField from 'components/Form/FormField/index';

import { ORG_SETTINGS } from 'containers/Modals/OrgProfileModal/SettingsTab/constants';
import { CompactLabel } from 'containers/Reagents/ReagentInventoryDrawer';
import { getIsMobile } from 'rdx/modules/app/slice';

const ReagentInventoryForm = ({
  lot, variant, expiresAt, inventoryReagent, queryParams,
  deletable, createInventoryReagentAction, onSuccess, secondary,
}) => {
  const isMobile = useAppSelector(getIsMobile);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { callModal, closeModal, setModal } = useModal();
  const { closeDrawer, setDrawer } = useDrawer();
  const { closeSecondaryDrawer, setSecondaryDrawer } = useSecondaryDrawer();

  const currentOrg = useAppSelector(getCurrentOrganization);
  const groups = useUnwrappedSelector(getSmartGroups);
  const orgScopes = useAppSelector(getOrganizationScopes);
  const isOrgOwner = useAppSelector(getIsOrgOwner);
  const defaultGroupId = useAppSelector(getUserDefaultGroupForCurrentOrg);
  const orgRequiresLotNumbers = useAppSelector(getOrganizationSettingValueByName(ORG_SETTINGS.ORG_REQUIRE_LOT_NUMBERS.name));

  const groupOptions = useMemo(() => groups?.map((group) => ({
    value: group.id,
    display: group.attributes.name,
  })), [groups]);

  const confirmPopModal = (formValues) => {
    callModal(
      <ConfirmModal
        key="confirm-modal"
        dangerous
        message="Are you sure?"
        explanation="You are adding a quantity of 0 vials. Press confirm to continue or Cancel to change the vial quantity."
        onConfirm={() => handleSubmit(formValues)}
      />
    );
  };

  const checkQuantity = (formValues) => {
    const { quantity } = formValues;
    if (Number(quantity) < 1) {
      confirmPopModal(formValues);
    } else {
      handleSubmit(formValues);
    }
  };

  function handleSubmit(formValues) {
    const { quantity, volume_remaining, received_at, expires_at, ...rest } = formValues;
    const values = {
      ...rest,
    };

    if (received_at || inventoryReagent?.received_at) {
      values.received_at = moment(received_at || inventoryReagent.received_at).format('YYYY-MM-DD');
    }

    if (expires_at || inventoryReagent?.expires_at) {
      values.expires_at = moment(expires_at || inventoryReagent.expires_at).format('YYYY-MM-DD');
    }

    if (quantity !== '' || !createInventoryReagentAction) {
      values.quantity = Number(quantity);
    }

    if (volume_remaining !== '' || !createInventoryReagentAction) {
      values.volume_remaining = Number(volume_remaining);
    }

    if (createInventoryReagentAction) {
      if (!values.received_at) {
        values.received_at = moment().format('YYYY-MM-DD');
      }
      dispatch(postInventoryReagent({
        action: createInventoryReagentAction,
        values: {
          ...values,
          variant,
        },
        query: queryParams,
        onSuccess: () => { onSuccess?.(queryParams, true); closeDrawerOnSuccess(); },
      }));
    } else if (updateInventoryReagentAction) {
      dispatch(patchInventoryReagent({
        action: updateInventoryReagentAction,
        values,
        query: queryParams,
        onSuccess: () => { onSuccess?.(queryParams, true); closeDrawerOnSuccess(); },
      }));
    }
  }

  const initialExpiresAt = useMemo(() => {
    if (inventoryReagent?.expires_at) {
      return moment(inventoryReagent.expires_at).format('YYYY-MM-DD');
    }
    if (expiresAt) {
      return moment(expiresAt).format('YYYY-MM-DD');
    }
    return '';
  }, [expiresAt, inventoryReagent?.expires_at]);

  const formFields = {
    quantity: {
      initialValue: inventoryReagent?.quantity ?? '',
      schema: Yup.string(),
    },
    lot: {
      initialValue: inventoryReagent?.lot ?? lot ?? '',
      schema: orgRequiresLotNumbers ? Yup.string().required('Lot number is required') : Yup.string(),
    },
    received_at: {
      initialValue: inventoryReagent?.received_at ? moment(inventoryReagent.received_at).format('YYYY-MM-DD') : '',
      schema: Yup.string(),
    },
    expires_at: {
      initialValue: initialExpiresAt,
      schema: Yup.string(),
    },
    location: {
      initialValue: inventoryReagent?.location ?? '',
      schema: Yup.string(),
    },
    box: {
      initialValue: inventoryReagent?.box ?? '',
      schema: Yup.string(),
    },
    coordinate: {
      initialValue: inventoryReagent?.coordinate ?? '',
      schema: Yup.string(),
    },
    notes: {
      initialValue: inventoryReagent?.notes ?? '',
      schema: Yup.string(),
    },
    volume_remaining: {
      initialValue: inventoryReagent?.volume_remaining ?? '',
      schema: Yup.string(),
    },
  };

  if (createInventoryReagentAction) {
    formFields.group_id = {
      initialValue: '',
      schema: Yup.string().required('Group is required'),
    };
  }

  const { form, fields } = useForm(formFields, { onSubmit: checkQuantity });

  useEffect(() => {
    if (inventoryReagent?.id) {
      fields.quantity.setValue(inventoryReagent?.quantity);
      fields.lot.setValue(inventoryReagent?.lot);
      fields.received_at.setValue(inventoryReagent?.received_at ? moment(inventoryReagent.received_at).format('YYYY-MM-DD') : '');
      fields.expires_at.setValue(inventoryReagent?.expires_at ? moment(inventoryReagent.expires_at).format('YYYY-MM-DD') : '');
      fields.location.setValue(inventoryReagent?.location);
      fields.box.setValue(inventoryReagent?.box);
      fields.coordinate.setValue(inventoryReagent?.coordinate);
      fields.notes.setValue(inventoryReagent?.notes);
      fields.volume_remaining.setValue(inventoryReagent?.volume_remaining);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryReagent]);

  useEffect(() => {
    if (fields.group_id) {
      if (defaultGroupId) {
        fields.group_id.setValue(defaultGroupId);
      } else {
        fields.group_id.setValue('');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultGroupId]);

  const closeDrawerOnSuccess = () => {
    if (secondary) {
      setSecondaryDrawer(null);
      closeSecondaryDrawer(null);
    } else {
      setDrawer(null);
      closeDrawer();
    }
  };

  const updateInventoryReagentAction = inventoryReagent?.getAction?.('update');

  const deleteInventoryReagentAction = deletable ? inventoryReagent?.getAction?.('delete') : null;

  const hasReagentsPermission = orgScopes.includes('reagents') || isOrgOwner;

  const handleDelete = () => {
    callModal(
      <ConfirmModal
        dangerous
        key={v4()}
        noCancel={false}
        message="Are you sure?"
        explanation="Please confirm you want to permanently delete this Reagent."
        onConfirm={() => {
          dispatch(deleteInventoryReagent({ action: deleteInventoryReagentAction,
            query: queryParams,
            onSuccess: () => {
              closeModal();
              setModal(null);
              onSuccess?.();
              closeDrawerOnSuccess();
            } }));
          history.push(`/${currentOrg.slug}/reagents?tab=inventory`);
        }}
        onCancel={() => {
          closeModal();
          setModal(null);
        }}
      />
    );
  };

  return (
    <form {...form.props}>
      {createInventoryReagentAction ? (
        <FormField
          compact={isMobile}
          label="Group *"
          field={fields.group_id}
          component={Dropdown}
          inputProps={{
            width: '100%',
            options: groupOptions,
            onChange: (value) => {
              fields.group_id.setValue(value);
            },
            value: fields.group_id.value,
            disabled: !hasReagentsPermission,
            id: 'inventory-reagent-group-dropdown',
            datatTestId: 'inventory-reagent-group-dropdown',
          }}
        />
      ) : (
        <>
          <CompactLabel>Group</CompactLabel>
          <GroupName>
            <CompactLabel>{inventoryReagent?.getRel?.('group')?.attributes?.name}</CompactLabel>
            {hasReagentsPermission && (
              <EditIcon
                onClick={() => callModal(
                  <ChangeGroupModal key={v4()} inventoryReagent={inventoryReagent} queryParams={queryParams} groupOptions={groupOptions} />
                )}
              />
            )}
          </GroupName>
        </>
      )}
      <Row isMobile={isMobile}>
        <FormField
          compact={isMobile}
          label="Quantity (vials)"
          field={fields.quantity}
          component={Input}
          inputProps={{
            width: '100%',
            type: 'number',
            'data-testid': 'vials-input',
            min: 0,
            disabled: !hasReagentsPermission,
          }}
        />
      </Row>
      <Row isMobile={isMobile}>
        <FormField
          compact={isMobile}
          label={`Lot${orgRequiresLotNumbers ? '*' : ''}`}
          required={orgRequiresLotNumbers}
          field={fields.lot}
          component={Input}
          inputProps={{
            width: '95%',
            disabled: !hasReagentsPermission || !!lot,
            'data-testid': 'lot-input',
          }}
        />
        <FormField
          compact={isMobile}
          label={(
            <>
              Volume Remaining <span style={{ textTransform: 'none' }}>(μL)</span> (current vial)
            </>
          )}
          field={fields.volume_remaining}
          component={Input}
          inputProps={{
            width: '100%',
            type: 'number',
            'data-testid': 'volume-remaining-input',
            disabled: !hasReagentsPermission,
          }}
        />
      </Row>
      <Row isMobile={isMobile}>
        <FormField
          compact={isMobile}
          label="Received"
          field={fields.received_at}
          component={Input}
          inputProps={{
            width: '95%',
            type: 'date',
            max: moment().add(999, 'years').format('YYYY-MM-DD'),
            disabled: !hasReagentsPermission,
          }}
        />
        <FormField
          compact={isMobile}
          label="Expires"
          field={fields.expires_at}
          component={Input}
          inputProps={{
            width: '100%',
            type: 'date',
            max: moment().add(999, 'years').format('YYYY-MM-DD'),
            disabled: !hasReagentsPermission || !!expiresAt,
          }}
        />
      </Row>
      <FormField
        compact={isMobile}
        label="Location"
        field={fields.location}
        component={Input}
        inputProps={{
          width: '100%',
          disabled: !hasReagentsPermission,
        }}
      />
      <Row isMobile={isMobile}>
        <FormField
          compact={isMobile}
          label="Box"
          field={fields.box}
          component={Input}
          inputProps={{
            width: '95%',
            disabled: !hasReagentsPermission,
          }}
        />
        <FormField
          compact={isMobile}
          label="Coordinates"
          field={fields.coordinate}
          component={Input}
          inputProps={{
            width: '100%',
            disabled: !hasReagentsPermission,
          }}
        />
      </Row>
      <FormField
        compact={isMobile}
        label="Notes"
        field={fields.notes}
        component={TextArea}
        inputProps={{
          width: '100%',
          disabled: !hasReagentsPermission,
        }}
      />
      <ButtonRow>
        {deletable && (
          <DeleteButton
            text="Delete"
            onClick={handleDelete}
            disabled={!hasReagentsPermission}
          />
        )}
        <PrimaryButton
          hollow
          text="Cancel"
          onClick={() => closeDrawer()}
          style={{ marginLeft: '10px' }}
        />
        <PrimaryButton
          id="submit-button"
          text="Submit"
          type="submit"
          disabled={!hasReagentsPermission}
          style={{ marginLeft: '10px' }}
        />
      </ButtonRow>
    </form>
  );
};

export default ReagentInventoryForm;

const Row = styled.div`
  display: flex;
  ${({ isMobile }) => isMobile && css`align-items: flex-end;`}
  & > * {
    width: 100%;
    margin-bottom: 15px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DeleteButton = styled(DangerButton)`
  margin-left: 10px;
`;

const GroupName = styled.div`
  display: flex;
  gap: 6px;
  margin: 3px 0 15px 0;
`;
