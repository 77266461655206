import styled from 'styled-components';

export const BaseTable = styled.table`
  width: 100%;
  font-size: 13px;
`;

export const Table = styled(BaseTable)`
  td {
    padding: 10px;
  }
`;

export const TableRow = styled.tr`
  background-color: ${({ theme }) => theme.colors.darkGrey};
`;

export const HalfWidthCell = styled.td`
  width: 50%;
`;

export const LabelCell = styled(HalfWidthCell)`
  font-weight: bold;
`;
