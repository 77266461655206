import LoadingCircle from "components/Loading/LoadingCircle";
import { useAppDispatch } from "hooks/reduxHooks";
// import { useScanditContext } from "contexts/ScanditContext";
import { useScanditSDK } from "lib/scandit/scanditSDK";
import { newErrorEvent } from "rdx/modules/messages/slice";
import { useEffect, useRef } from "react";

export default function ScannerComponent(): JSX.Element {
  const dispatch = useAppDispatch();
  const host = useRef<HTMLDivElement | null>(null);
  const { loading, sdk } = useScanditSDK();
  // const { keepCameraOn } = useScanditContext();

  useEffect(() => {
    async function start(): Promise<void> {
      if (!loading && host.current) {
        sdk.connectToElement(host.current);
        try {
          await sdk.enableCamera(true);
          await sdk.enableScanning(true);
          
        } catch (error) {
          if (error instanceof Error) {
            dispatch(newErrorEvent({ text: error.message }));
          }
        }
      }
    }
    async function cleanup(): Promise<void> {
      if (!loading) {
        sdk.detachFromElement();
        await sdk.enableScanning(false);
        await sdk.enableCamera(false);
      }
    }
    void start();
    return () => {
      void cleanup();
    };
  });

  if (loading) {
    return <LoadingCircle />
  }
  return <div ref={host} style={{ width: '100%', height: '100%' }}/>;
}
