import { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import globals from 'styles/globals';

import { Redirect } from 'react-router-dom';
import SplashBackground from 'containers/SplashBackground';
import LoadingSquares from 'components/Loading/LoadingSquares/index';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { requestLogin } from 'rdx/modules/auth/slice';
import { getLatestLoginMessageEvt } from 'rdx/modules/messages/slice';
import {
  HeaderContainer,
  Header,
  Subheader,
} from '../components';
import { useMixpanel } from 'hooks/useMixpanel';

const BDSSO = ({ bd_login_key }) => {
  const dispatch = useAppDispatch();
  const mixpanel = useMixpanel();
  const loginMessage = useAppSelector(getLatestLoginMessageEvt);
  const timer = useRef();
  const [dotCount, setDotCount] = useState(0);
  const [minTimeout, setMinTimeout] = useState(false);
  const loginError = useMemo(() => loginMessage.statusCode >= 400, [loginMessage.statusCode]);

  useEffect(() => {
    dispatch(requestLogin({
      bd_login_key,
      onSuccess: () => {
        mixpanel?.track('Login');
      },
    }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    timer.current = setInterval(() => {
      setDotCount((d) => {
        if (d === 4) {
          setMinTimeout(true);
        }
        return (d + 1) % 5;
      });
    }, 500);
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  if (loginError && minTimeout) {
    return <Redirect to="/login" />;
  }

  /* eslint-disable */
  const dots =
    dotCount === 0 ? ''
    : dotCount === 1 ? '.'
    : dotCount === 2 ? '..'
    : '...'
  /* eslint-enable */
  return (
    <SplashBackground>
      <MainContainer>
        <HeaderContainer>
          <Header>
            Logging In{dots}
          </Header>
          <Subheader>
            Please wait while we log you in with BD Single Sign-On
          </Subheader>
        </HeaderContainer>
        <SpinnerContainer>
          <LoadingSquares />
        </SpinnerContainer>
      </MainContainer>
    </SplashBackground>
  );
};

BDSSO.propTypes = {
  bd_login_key: PropTypes.string.isRequired,
};

export default BDSSO;

const { colors } = globals;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 70px;
  position: relative;
  background-color: ${colors.white};
  width: 720px;
  height: 400px;
  border-radius: 3px;
  box-shadow: 0.2em 0.2em 0.6em 0.2em rgba(0,0,0,0.3);
  z-index: 1;
  transition: all 1s ease;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
`;
