import { HalfWidthCell, LabelCell, Table, TableRow } from 'components/BarcodeScans/shared';
import { BarcodeScanReagent } from 'types/barcodeScans';

const ReagentTable = ({ reagent }: { reagent: BarcodeScanReagent }) => (
  <Table>
    <tbody>
      <TableRow>
        <LabelCell>Antigen</LabelCell>
        <HalfWidthCell>{reagent.antigen}</HalfWidthCell>
      </TableRow>
      <TableRow>
        <LabelCell>Fluorochrome</LabelCell>
        <HalfWidthCell>{reagent.fluorochrome}</HalfWidthCell>
      </TableRow>
      <TableRow>
        <LabelCell>Clone</LabelCell>
        <HalfWidthCell>{reagent.clone}</HalfWidthCell>
      </TableRow>
      <TableRow>
        <LabelCell>Catalog Number</LabelCell>
        <HalfWidthCell>{reagent.variant}</HalfWidthCell>
      </TableRow>
      <TableRow>
        <LabelCell>Size</LabelCell>
        <HalfWidthCell>{reagent.size}</HalfWidthCell>
      </TableRow>
      <TableRow>
        <LabelCell>Species</LabelCell>
        <HalfWidthCell>{reagent.species?.join(', ')}</HalfWidthCell>
      </TableRow>
    </tbody>
  </Table>
);

export default ReagentTable;
