import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from 'hooks/reduxHooks';
import { setWorkflow } from 'rdx/modules/workflow/slice';
import { setProcedures } from 'rdx/modules/procedures/slice';

import DropdownNav from 'components/DropdownNav';

import { type NavHeaderTab } from 'components/NavHeader';
import ResourceList from 'lib/jsonApi/ResourceList';
import { ArrowLeftLongIcon } from 'components/Icons';
import globals from 'styles/globals';
import { NavLink, navTabUnderlineCSS } from 'components/NavHeader/shared';

type Props = {
  tabs: NavHeaderTab[],
  currentTab?: string,
  activeTab?: string,
  setActiveTab: React.Dispatch<React.SetStateAction<string>>,
  onTabChange?: (tabId: string) => void,
};

const NavHeaderTabs = ({ tabs = [], currentTab, activeTab, setActiveTab, onTabChange }: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleTabClick = ({ tabId, link }: { tabId: string, link?: string }) => {
    setActiveTab(tabId);
    onTabChange?.(tabId);
    if (link) {
      history.push(link);
    }
  };

  let hasGroups = false;
  tabs?.forEach((tab) => {
    if (tab.name || tab.children) {
      hasGroups = true;
    }
  });

  if (hasGroups) {
    return tabs?.map((tab) => (
      <DropdownNav
        key={`dropdown-nav-${tab.id}`}
        text={tab.id}
        routes={tab.children ? tab.children : []}
      />
    ));
  }

  return tabs.map(({ text, id, link }) => {
    if (id === 'PREV') {
      return (
        <BackArrow
          data-testid="back-arrow"
          key={id}
          onClick={() => {
            setWorkflow(null);
            dispatch(setProcedures(new ResourceList()));
            history.push(link ?? '/');
          }}
        >
          <ArrowLeftLongIcon size={20} color="#444" />
          <BackText>Back</BackText>
        </BackArrow>
      );
    }
    const isActiveTab = currentTab ? currentTab === id : activeTab === id;
    return (
      <NavLinkWrapper
        key={id}
        data-testid={`nav-${id}`}
        onClick={() => handleTabClick({ tabId: id, link })}
        className={isActiveTab ? 'active' : ''}
        characters={text.length}
      >
        {text}
      </NavLinkWrapper>
    );
  });
};
export default NavHeaderTabs;

const BackText = styled.div`
  white-space: nowrap;
  text-transform: capitalize;
  font: ${globals.fonts.buttons};
  color: ${globals.colors.fontDark};
  letter-spacing: 0.4pt;
  font-weight: 300;
  padding-left: 8px;
`;

const NavLinkWrapper = styled(NavLink)<{ characters: number }>`
  padding-top: 3px;
  border: none;
  text-align: center;
  max-width: ${({ characters }) => `${characters * 8 + 23}px`};
  font: ${({ theme }) => theme.fonts.navLinks};
  font-weight: 300;
  letter-spacing: 0.3pt;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  color: ${({ theme }) => theme.colors.fontDark};

  &.active {
    border: none;
    color: ${({ theme }) => theme.colors.fontDark};
    ${navTabUnderlineCSS}
  }
`;

const BackArrow = styled(NavLink)`
  height: 40px;
  padding-top: 15px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: calc(100% + 10px);
  width: 35px;
  cursor: pointer;
  overflow: hidden;
  transition: width 200ms;

  &:hover,
  &:focus {
    width: 100px;
  }

  & > * {
    flex: 0 0 auto;
  }
  & > :first-child {
    margin-left: 40px
  }
`;
