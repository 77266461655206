import PropTypes from 'prop-types';

import NavHeader from 'components/NavHeader';
import { useAppSelector } from 'hooks/reduxHooks';
import { getActiveNavTab, getNavButtons, getNavTabs } from 'rdx/modules/nav/slice';

const MainNavBar = ({ defaultTabs }) => {
  const navButtons = useAppSelector(getNavButtons);
  const navTabsList = useAppSelector(getNavTabs);
  const activeNavTab = useAppSelector(getActiveNavTab);
  const defaultButtons = [];

  const buttons = (navButtons && navButtons.length) ? navButtons : defaultButtons;
  const tabs = (navTabsList && navTabsList.length) ? navTabsList : defaultTabs;

  return (
    <NavHeader
      tabs={tabs}
      buttons={buttons}
      isMainNav
      initialTabId={activeNavTab}
      currentTab={activeNavTab}
    />
  );
};

MainNavBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  defaultTabs: PropTypes.array,
};

MainNavBar.defaultProps = {
  defaultTabs: [],
};

export default MainNavBar;
