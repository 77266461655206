import { PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import globals from 'styles/globals';

export type CellProps = PropsWithChildren<{
  cellId: string,
  label?: ReactNode,
  multiline?: boolean,
  capitalize?: boolean,
  className?: string,
  wrap?: boolean,
}>

const Cell = ({ cellId, label, multiline, capitalize, className, children, wrap }: CellProps) => {
  let dataTestId = '';

  if (typeof label === 'string' && label) {
    dataTestId = `cell-container-${label}`;
  } else {
    dataTestId = `cell-container-${cellId}`;
  }

  return (
    <CellContainer cellId={cellId} data-testid={dataTestId}>
      <CellLabel className="cell-label">{label}</CellLabel>
      <CellContent multiline={multiline} capitalize={capitalize} className={className} shouldWrap={wrap}>{children}</CellContent>
    </CellContainer>
  );
};

export default Cell;

const { colors, fonts } = globals;

const CellContainer = styled.div<{ cellId: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: ${({ cellId }) => cellId};
  min-width: 0;
`;

const CellLabel = styled.div`
  flex: 0 0 auto;
  text-transform: uppercase;
  color: ${colors.fontMed};
  font: ${fonts.boldLabel};
  margin-bottom: 5px;
`;

const CellContent = styled.div<{
  capitalize?: boolean,
  multiline?: boolean,
  shouldWrap?: boolean,
}>`
  font-size: 15px;

  ${({ capitalize }) => capitalize && css`
    &::first-letter {
      text-transform: capitalize;
    }
  `}

  ${({ multiline }) => !multiline && css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}

  ${({ shouldWrap }) => shouldWrap && css`
    overflow-wrap: break-word;
  `}
`;
