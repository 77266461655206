import { takeLatest } from 'redux-saga/effects';
import trackRequests from 'rdx/utils/trackRequests';

import getFluorochromesSaga from 'rdx/modules/fluorochromes/sagas/getFluorochromes';
import { requestFluorochromes } from 'rdx/modules/fluorochromes/slice';

function* watchFluorochromesSagas() {
  yield trackRequests(takeLatest, requestFluorochromes.type, getFluorochromesSaga);
}

export default watchFluorochromesSagas;
