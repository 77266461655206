import { put, select } from 'redux-saga/effects';
import { get } from 'lodash';
import makeRequest from 'rdx/utils/makeRequest.ts';
import putErrorActions from 'rdx/utils/putErrorActions';
import { LOGIN_FORM } from 'rdx/modules/messages/constants';
import { setAuthToken } from 'lib/utils/authHelpers';
import { getSessionAction, requestSession, setUnusedLicensesModalSeen } from 'rdx/modules/auth/slice';

function* login({ payload }) {
  const { rememberMe, onSuccess, ...rest } = payload;
  if (rememberMe) {
    localStorage.setItem('rememberMe', true);
  }
  const loginAction = yield select(getSessionAction('login'));
  const { success, data, error } = yield* makeRequest.action(loginAction, rest);
  if (success && data) {
    const token = get(data, 'data.attributes.token');
    setAuthToken(token);
    yield put(setUnusedLicensesModalSeen(false));
    yield put(requestSession());
    onSuccess?.();
  } else {
    yield* putErrorActions({ error, target: LOGIN_FORM });
  }
  return null;
}

export default login;
